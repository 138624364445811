import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-top: 1px solid var(--color-neutral-90);
  padding: 16px 24px;

  background: white;
  z-index: 10;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

export const CancelButton = styled(LinkButton)`
  margin: auto 0;
`;

export const BackButton = styled(Button)``;
export const ContinueButton = styled(Button)``;
