import styled from "styled-components";

import {
  Button,
  Typography,
  Breadcrumbs as FlashBreadcrumbs,
} from "@flash-tecnologia/hros-web-ui-v2";

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 40px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 479px;
  padding: 34px 24px 64px 24px;
`;

export const TitleText = styled(Typography)`
  && {
    font-weight: 700;
  }
`;
export const ContentText = styled(Typography)`
  && {
    font-weight: 400;
    color: #83727d;
  }
`;

export const StyledButton = styled(Button)`
  && {
    align-self: center;
    width: 250px;
    @media screen and (max-width: 400px) {
      width: 150px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ebe6e9;
  padding: 16px 24px;
  position: relative;
  bottom: 0px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

export const Breadcrumbs = styled(FlashBreadcrumbs)`
  margin: 40px;
`;

export const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
