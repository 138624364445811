import { useEffect, useMemo, useState } from "react";
import { Tab, TabItens } from "@flash-tecnologia/hros-web-ui-v2";

import { TabTemplate } from "./components/TabTemplate";
import { Container } from "./styled";
import { useTabs } from "./use-tabs";

export const Tabs = ({ redirectedTab = 0 }: { redirectedTab?: number }) => {
  const [selectedTab, setSelectedTab] = useState(redirectedTab);
  const tabs = useTabs();

  useEffect(() => {
    if (redirectedTab != selectedTab) setSelectedTab(redirectedTab);
  }, [redirectedTab]);

  useEffect(() => {
    setSelectedTab(redirectedTab);
  }, []);

  const tabsItems = useMemo(
    () =>
      tabs.map<TabItens>((tab) => ({
        label: tab.label,
        component: (
          <TabTemplate
            description={tab.description}
            secondaryTabs={tab.secondaryTabs}
          />
        ),
      })),
    [tabs],
  );

  return (
    <Container>
      <Tab
        tabItens={tabsItems}
        selected={selectedTab}
        onTabChanged={setSelectedTab}
      />
    </Container>
  );
};
