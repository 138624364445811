interface DispatchToastParams {
  content: string | undefined;
  type: "error" | "success" | "warning";
  title?: string;
  description?: string;
}

export default function dispatchToast(detail: DispatchToastParams) {
  dispatchEvent(new CustomEvent("showToast", { detail }));
}
