import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  Loader,
  PageContainer,
  TransferList,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useCompany } from "@lib";
import { debounce } from "lodash";

import { useSession } from "@/common/user";

import { Footer } from "./Footer";
import { Header } from "./Header";
import {
  Body,
  Container,
  ContentWrapper,
  EmailField,
  EmployeeName,
  LoadingWrapper,
  StyledDescription,
  StyledSubtitle,
  StyledTitle,
  TableData,
  TableDataArea,
  TextWrapper,
  TransferListArea,
} from "./styled";
import { useEmployees } from "./useEmployees";
import { useGroupEmployees } from "./useGroupEmployees";
import { useUpdateGroupMembers } from "./useUpdateGroupMembers";

export const PageManageGroupMembers = () => {
  const { groupId } = useParams();
  const [searchParams] = useSearchParams();
  const groupName = searchParams.get("groupName");

  const { companyId } = useSession();
  const [searchState, setSearchState] = useState("");
  const [groupSearchState, setgroupSearchState] = useState("");

  const [paginationState, setPaginationState] = useState({
    pageNumber: 1,
    pageSize: 20,
  });

  const setDebouncedSearchState = debounce((value: string) => {
    setSearchState(value);
  }, 300);

  const setDebouncedGroupSearchState = debounce((value: string) => {
    setgroupSearchState(value);
  }, 300);

  const {
    groupEmployees,
    isGroupEmployeesLoading,
    employeesToAdd,
    employeesToRemove,
    updateEmployeesFromGroup,
    checkGroupEmployees,
  } = useGroupEmployees(groupId as any);

  const {
    employees,
    isEmployeesLoading,
    totalEmployees,
    fetchEmployees,
    checkEmployees,
    updateEmployees,
  } = useEmployees(groupEmployees);

  const { updateGroupMembers, loading: isUpdatingGroupMembers } =
    useUpdateGroupMembers(groupId || "");

  useLayoutEffect(() => {
    const transferListContainer: any = document.getElementsByClassName(
      "transfer-list-filter-search-field-container",
    );

    if (!transferListContainer.length) return;

    Array.from(transferListContainer).forEach((item: any) => {
      if (!item?.childNodes?.length) return;
      item.childNodes[0].style.width = "100%";
    });
  }, []);

  const columns = [
    {
      Header: "Nome",
      accessor: "name",
      Cell: ({
        row: {
          original: { name = null, email },
        },
      }: {
        row: { original: { name: string | null; email: string | null } };
      }) => (
        <TableData>
          <TableDataArea>
            <EmployeeName variant="body3">{name}</EmployeeName>
            {email && <EmailField variant="body3">{email}</EmailField>}
          </TableDataArea>
        </TableData>
      ),
    },
  ];

  const filteredGroupEmployees = useMemo(() => {
    return groupEmployees?.filter((employee) =>
      employee.name.toLowerCase().includes(groupSearchState.toLowerCase()),
    );
  }, [groupEmployees, groupSearchState]);

  useEffect(() => {
    setPaginationState((prev) => ({ ...prev, pageNumber: 1 }));
    fetchEmployees({
      companyId,
      pageNumber: 1,
      pageSize: paginationState.pageSize,
      search: searchState,
    });
  }, [searchState]);

  return (
    <Body>
      <Header />
      <ContentWrapper>
        <PageContainer>
          <Container>
            <TextWrapper>
              <StyledTitle variant="headline6">Editar grupo</StyledTitle>
              <StyledSubtitle variant="headline8">
                Selecione pela lista
              </StyledSubtitle>
              <StyledDescription variant="body3">
                Escolha quais pessoa você gostaria de adicionar à esse grupo de{" "}
                <strong>{groupName}</strong>
              </StyledDescription>
            </TextWrapper>
            <TransferListArea>
              <TransferList
                columns={columns as any}
                onTransfer={({ leftList, rightList }) => {
                  updateEmployees(leftList?.data as any);
                  updateEmployeesFromGroup(rightList?.data as any);
                }}
                leftList={{
                  data: employees,
                  total: totalEmployees || 0,
                  title: `Todas as pessoas (${totalEmployees || 0})`,
                  loading: isEmployeesLoading,
                  onScroll: (_, hasReachedEnd) => {
                    if (
                      paginationState.pageSize * paginationState.pageNumber <
                        (totalEmployees || 0) &&
                      hasReachedEnd
                    ) {
                      fetchEmployees({
                        companyId,
                        pageNumber: paginationState.pageNumber + 1,
                        pageSize: paginationState.pageSize,
                        search: searchState,
                        isFetchingMore: true,
                      });
                      setPaginationState((prev) => ({
                        ...prev,
                        pageNumber: paginationState.pageNumber + 1,
                      }));
                    }
                  },
                  onCheck: ({ allChecked, data }) => {
                    checkEmployees(data, allChecked as any);
                  },
                  onSearch: setDebouncedSearchState,
                  emptyStageMessage: (
                    <>
                      {!employees?.length && !isEmployeesLoading ? (
                        <>Nenhum colaborador encontrado</>
                      ) : (
                        <LoadingWrapper>
                          <Typography variant="body3">
                            Carregando colaboradores...
                          </Typography>
                          <Loader size="small" variant="primary" />
                        </LoadingWrapper>
                      )}
                    </>
                  ) as any,
                }}
                rightList={{
                  data: filteredGroupEmployees as any,
                  total: groupEmployees?.length || 0,
                  title: `Selecionados para o grupo (${
                    groupEmployees?.length || 0
                  })`,
                  loading: isGroupEmployeesLoading,
                  onCheck: ({ allChecked, data }) => {
                    checkGroupEmployees(data as any, allChecked as any);
                  },
                  onSearch: setDebouncedGroupSearchState,
                  emptyStageMessage: (
                    <>
                      {!filteredGroupEmployees?.length &&
                      !isGroupEmployeesLoading ? (
                        <>Nenhum colaborador encontrado</>
                      ) : (
                        <LoadingWrapper>
                          <Typography variant="body3">
                            Carregando colaboradores...
                          </Typography>
                          <Loader size="small" variant="primary" />
                        </LoadingWrapper>
                      )}
                    </>
                  ) as any,
                }}
              />
            </TransferListArea>
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer
        onConfirm={() =>
          updateGroupMembers({
            groupName: groupName || "",
            employeesToAdd: employeesToAdd || [],
            employeesToRemove: employeesToRemove || [],
          })
        }
        loading={isUpdatingGroupMembers}
      />
    </Body>
  );
};
