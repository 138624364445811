import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { REMOVE_EMPLOYEE_FROM_ROLE } from "@/api/mutations/roles";
import { RemoveEmployeesFromRoleVariables } from "@/api/mutations/roles/__generated__/RemoveEmployeesFromRole";
import { useCompany } from "@/lib";
import { routes } from "@/routes";
import { dispatchToast, ErrorBoundary } from "@/utils";
import { RemoveEmployeesFromRoleInput } from "@grapql-schema";

export const useRemoveEmployeesFromRole = () => {
  const { companyId } = useCompany();
  const navigate = useNavigate();

  const [removeEmployeesRoleMutation, { loading }] = useMutation<
    void,
    RemoveEmployeesFromRoleVariables
  >(REMOVE_EMPLOYEE_FROM_ROLE, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: "Pessoa desvinculada com sucesso!",
      });
      navigate(routes.roles);
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content:
          "Ocorreu um erro ao desvicular a pessoa. Por favor, tente novamente mais tarde.",
      });
    },
    refetchQueries: ["GetRoleWithMembers"],
  });

  const removeEmployeesFromRole = (
    input: Omit<RemoveEmployeesFromRoleInput, "companyId">,
  ): void => {
    removeEmployeesRoleMutation({
      variables: {
        input: {
          companyId,
          ...input,
        },
      },
    });
  };

  return {
    removeEmployeesFromRole,
    loading,
  };
};
