import styled from "styled-components";
import { Modal, TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { DialogProps } from "@mui/material";

export const StyledModal = styled(Modal.Root).attrs((props) => {
  const handleClose: DialogProps["onClose"] = (_, reason) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return;
    props.onClose();
  };
  return { onClose: handleClose, loading: Boolean };
})`
  && {
    .modal-content-area {
      padding: 0px 36px;
      max-width: 650px;
    }
  }
`;

export const SemiBoldText = styled.span`
  weight: 600;
`;

export const StyledInputTitle = styled(Typography)`
  color: var(--color-neutral-30);
  weight: 700;
`;

export const StyledInputDetails = styled(Typography)`
  color: var(--color-neutral-40);
`;

export const ErrorColor = styled.span`
  color: #c96c01;
`;

export const TextFieldInput = styled(TextField)`
  width: 100%;
`;

export const KeyContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: solid;
  border-radius: 12px;
  border-width: 1px;
  border-color: var(--color-neutral-70);
  margin-top: 12px;
  padding: 10px 16px;
  width: 100%;
  background-color: var(--color-neutral-95);
  word-break: break-all;
  gap: 8px;
  &:hover {
    border-width: 2px;
    border-color: var(--color-neutral-70);
  }
`;

export const KeyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const KeyTitle = styled.div`
  color: var(--color-neutral-70);
`;

export const KeyDisplayer = styled.div`
  color: var(--color-neutral-30);
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  max-width: 650px;
`;

export const TextFieldTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  padding: 2px;
  margin-bottom: 10px;
`;
