import styled from "styled-components";

export const Container = styled.div`
  margin: 40px 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  gap: 24px;

  @media screen and (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`;
