import styled from "styled-components";

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  height: 100%;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
`;

export const Tabs = styled.div`
  gap: 12px;
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  height: 80px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    height: auto;
    min-width: fit-content;
  }
`;

export const Tab = styled(Skeleton)`
  width: 200px;
  height: 52px;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Table = styled(Skeleton)`
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
`;
