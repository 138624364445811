import gql from "graphql-tag";

export const CREATE_GROUP = gql`
  mutation CreateGroup($data: CreateGroupInput) {
    createGroup(data: $data) {
      groupId
      name
    }
  }
`;
