import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const OptionSpan = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error.dark1};
`;

export const StyledIcon = styled(Icons)`
  && {
    color: ${({ theme }) => theme.colors.neutral[40]};
  }
`;
