import { ReactNode } from "react";

import {
  FooterCancelButton,
  FooterContainer,
  SubmitButton,
  SubmitButtonText,
} from "./styled";

export const Footer = ({
  onClose,
  onSubmit,
  submitText,
  loading,
  disabled,
}: {
  onClose: () => void;
  onSubmit: () => void;
  submitText: string | ReactNode;
  loading: boolean;
  disabled: boolean;
}) => {
  return (
    <FooterContainer>
      <FooterCancelButton onClick={onClose}>Cancelar</FooterCancelButton>
      <SubmitButton
        variant={"primary"}
        variantType={"error"}
        buttonType={"primary"}
        size={"medium"}
        style={{ margin: "auto 0", padding: "19px 52px" }}
        onClick={onSubmit}
        loading={loading}
        disabled={disabled}
        disableRipple
        disableTouchRipple
      >
        <SubmitButtonText disabled={disabled}>{submitText}</SubmitButtonText>
      </SubmitButton>
    </FooterContainer>
  );
};
