import { GET_COMPANY } from "@/api";
import {
  GetCompany,
  GetCompany_getCompany,
} from "@/api/queries/__generated__/GetCompany";
import { useCompany } from "@/lib";
import { dispatchToast, ErrorBoundary } from "@/utils";
import { useQuery } from "@apollo/react-hooks";
import { useState, useMemo } from "react";

export const useGetCompany = () => {
  const [company, setCompany] = useState<GetCompany_getCompany>();
  const { companyId } = useCompany();

  const variables = useMemo(() => {
    if (companyId) {
      return { companyId };
    }
    return false;
  }, [companyId]);

  const { loading } = useQuery<GetCompany>(GET_COMPANY, {
    variables: variables ? { ...variables } : undefined,
    onCompleted: (data) => {
      setCompany(data.getCompany);
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content: "Ocorreu um erro ao buscar os dados da empresa.",
      });
    },
  });

  return {
    loading,
    company,
  };
};
