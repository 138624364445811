import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { Container, Subtitle, Wrapper } from "./styled";

export const Header = ({ setIsCreateKeyModalOpen, isFeatEnabled }: any) => {
  return (
    <Container>
      <Wrapper>
        <Typography variant="headline6">
          Chaves de acesso programático
        </Typography>
        <Subtitle variant="body4">
          Utilize suas chaves de acesso programático da Flash para realizar
          integrações com outras plataformas da sua empresa.
        </Subtitle>
      </Wrapper>
      <span>
        <Button
          size="large"
          variant="primary"
          disabled={!isFeatEnabled}
          onClick={() => {
            setIsCreateKeyModalOpen(true);
          }}
        >
          Criar nova chave
          <Icons name="IconPlus" size={14} />
        </Button>
      </span>
    </Container>
  );
};
