import { setInLS } from "@flash-tecnologia/hros-web-utility";

type Company = {
  _id: string;
  id: string;
  name: string;
  registrationNumber: string;
};

type EmployeePermissions = {
  isAdmin: boolean;
  companies: CompanyWithPermissions[];
};

type CompanyWithPermissions = Omit<Company, "_id"> & {
  permissions: string[];
};

export const updateNewCompanyPermissions = (
  permissions: EmployeePermissions,
  companies: Omit<Company, "id">[],
) => {
  companies.forEach((company) => {
    permissions.companies.push({
      id: company._id,
      name: company.name,
      registrationNumber: company.registrationNumber,
      permissions: ["*"],
    });
  });

  setInLS({
    key: "employee_permissions",
    value: permissions,
  });

  dispatchEvent(
    new CustomEvent("employee_permissions_updated", {
      detail: permissions,
    }),
  );
};
