import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const TableData = styled.div`
  display: flex;
`;

export const TableDataArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const EmployeeName = styled(Typography)`
  text-transform: capitalize;
`;

export const EmailField = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;
