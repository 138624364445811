import { IconButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import {
  HeaderContainer,
  HeaderTitleContainer,
  StyledSubtitle,
  TitleContainer,
} from "./styled";

export const Header = ({
  onClose,
  loading,
}: {
  onClose: () => void;
  loading: boolean;
}) => {
  return (
    <HeaderContainer>
      <HeaderTitleContainer>
        <TitleContainer>
          <Typography variant="headline8">
            Criar nova chave de acesso programático
          </Typography>
        </TitleContainer>
        <IconButton
          icon="IconX"
          variant="line"
          size="small"
          onClick={() => onClose()}
          disabled={loading}
        />
      </HeaderTitleContainer>
      <StyledSubtitle variant="body4">
        Informe o nome da chave de acesso, copie-a e guarde-a em um local
        seguro. A chave será exibida apenas uma vez e não poderá ser visualizada
        novamente posteriormente.
      </StyledSubtitle>
    </HeaderContainer>
  );
};
