import { useMutation } from "@apollo/client";
import { CreateDepartmentInput } from "@grapql-schema";
import { dispatchToast } from "@utils";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { useNavigate } from "react-router-dom";
import { CREATE_DEPARTMENT } from "src/api";
import {
  CreateDepartment,
  CreateDepartmentVariables,
} from "src/api/mutations/__generated__/CreateDepartment";
import { routes } from "src/routes";
import { departmentErrors } from "../types";

type HookResult = {
  createDepartment: (params: CreateDepartmentParams) => void;
  loading: boolean;
};

type CreateDepartmentParams = Required<
  Pick<
    CreateDepartmentInput,
    "name" | "externalId" | "description" | "companyId"
  >
> &
  CreateDepartmentInput;

export const useCreateDepartment = (): HookResult => {
  const navigate = useNavigate();

  const [createDepartmentMutation, { loading }] = useMutation<
    CreateDepartment,
    CreateDepartmentVariables
  >(CREATE_DEPARTMENT, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: "Departamento criado com sucesso!",
      });
      navigate(routes.departments);
    },
    onError: (error) => {
      const graphqlErrors: any = error.graphQLErrors?.[0];
      const errorCode = graphqlErrors?.errorCode as string;
      if (errorCode === departmentErrors.departmentsExists) {
        dispatchToast({
          type: "error",
          content: "Erro ao criar departamento",
          description: "Departamento com mesmo nome já existe.",
        });
      } else {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content:
            "Ocorreu um erro ao criar departamento. Por favor, tente novamente mais tarde.",
        });
      }
    },
  });

  const createDepartment = ({
    name,
    externalId,
    description,
    companyId,
    employeeIds,
  }: CreateDepartmentParams): void => {
    createDepartmentMutation({
      variables: {
        data: {
          companyId,
          name,
          externalId,
          description,
          employeeIds,
        },
      },
    });
  };

  return {
    createDepartment,
    loading,
  };
};
