import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { Container, TagStyled } from "./styled";

export const TableHeader = ({
  totalCount,
  loading,
}: {
  totalCount: number;
  loading: boolean;
}) => {
  return (
    <Container>
      <Typography variant="headline8">Pessoas neste grupo</Typography>
      <TagStyled disabled={true} variant={"gray"} loading={loading}>
        {`${totalCount} pessoas`}
      </TagStyled>
    </Container>
  );
};
