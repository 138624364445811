import {
  Breadcrumbs,
  IconButton,
  Icons,
  LinkButton,
  Stepper,
} from "@flash-tecnologia/hros-web-ui-v2";
import { NavigateOptions, useNavigate } from "react-router-dom";
import { routes } from "src/routes";
import { Dispatch, SetStateAction, useMemo } from "react";
import {
  CreateDepartmentStepName,
  DepartmentFormStep,
} from "@departments/types";
import { Container, Content } from "./styled";

const steps: CreateDepartmentStepName[] = [
  CreateDepartmentStepName.FIRST,
  CreateDepartmentStepName.SECOND,
];

export type HeaderBreadcrumbs = {
  route?: string;
  label: string;
  options?: NavigateOptions;
};

interface HeaderProps {
  activeStep: DepartmentFormStep;
  setActiveStep: Dispatch<SetStateAction<DepartmentFormStep>>;
  breadcrumbs: HeaderBreadcrumbs[];
}

export const Header = ({
  activeStep,
  setActiveStep,
  breadcrumbs,
}: HeaderProps) => {
  const navigate = useNavigate();

  const navigateToDepartments = () => {
    navigate(routes.departments);
  };

  const breadcrumbItems = useMemo(
    () =>
      breadcrumbs.map(({ label, route, options }) => {
        return (
          <LinkButton
            variant="secondary"
            onClick={() => route && navigate(route, options)}
          >
            {label}
          </LinkButton>
        );
      }),
    [breadcrumbs, navigate]
  );

  return (
    <Container>
      <IconButton
        variant="line"
        size="small"
        icon="IconArrowLeft"
        onClick={navigateToDepartments}
      />
      <Content>
        <Breadcrumbs
          separator={<Icons name="IconChevronRight" fill="transparent" />}
          breadcrumbs={breadcrumbItems}
        />
        <Stepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          disableClick={activeStep !== DepartmentFormStep.SECOND}
        />
      </Content>
    </Container>
  );
};
