import { useEffect } from "react";
import { getCep } from "@flash-tecnologia/hros-web-utility";
import { FieldComponent } from "@Components/FieldComponent";
import { FieldDisplay } from "@Components/FieldDisplay";

import { model } from "./model";
import { Container } from "./styled";

interface AddressFieldsInterface {
  address: AddressType;
  editing: boolean;
  loading: boolean;
  setAddressValue: (address: AddressType) => void;
  addressValue: AddressType;
  errors?: { [key: string]: boolean };
  helperTexts?: { [key: string]: string };
}

export const AddressFields = ({
  address,
  editing,
  loading,
  setAddressValue,
  addressValue,
  errors,
  helperTexts,
}: AddressFieldsInterface) => {
  useEffect(() => {
    if (addressValue?.zipCode?.length === 9) {
      getCep(addressValue?.zipCode).then((data) => {
        if (data) {
          setAddressValue({
            ...addressValue,
            city: data?.localidade,
            state: data?.uf,
            street: data?.logradouro,
            district: data?.bairro,
          });
        }
      });
    }
  }, [addressValue?.zipCode]);

  return (
    <Container>
      {!editing && (
        <>
          {model(address).map((field) => {
            return (
              <FieldDisplay
                key={field?.key}
                name={field?.name}
                value={field?.value || "-"}
                locked={undefined}
                required={field?.required}
                statusTag={undefined}
                tag={undefined}
                tagVariant={undefined}
                link={undefined}
                component={field?.component}
                options={field?.options}
                loading={loading}
              />
            );
          })}
        </>
      )}
      {editing &&
        model(addressValue).map((field) => {
          return (
            <FieldComponent
              {...field}
              key={field?.key}
              value={addressValue?.[field?.key as keyof AddressType] || ""}
              inputProps={{ ...field?.inputProps }}
              onChange={(e) => {
                setAddressValue({
                  ...addressValue,
                  [field?.key]: e,
                });
              }}
              error={errors?.[field?.key]}
              helperText={errors?.[field?.key] ? field?.helperText : undefined}
            />
          );
        })}
    </Container>
  );
};
