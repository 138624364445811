import { Dispatch, memo, SetStateAction } from "react";
import { DepartmentTabCard } from "@departments/types";

import { Container } from "./styled";
import { TabCard } from "./TabCard";

export interface TabsInterface {
  departments: DepartmentTabCard[];
  selectedTab: DepartmentTabCard;
  setSelectedTab: Dispatch<SetStateAction<DepartmentTabCard>>;
}

const TabsComponent = ({
  departments,
  selectedTab,
  setSelectedTab,
}: TabsInterface) => {
  return (
    <Container>
      {departments?.map((department) => {
        const isSelected =
          department.departmentId === selectedTab?.departmentId;

        return (
          <TabCard
            key={department.departmentId}
            id={department.departmentId}
            title={department.name}
            isSelected={isSelected}
            setSelectedTab={(departmentId) =>
              setSelectedTab(
                departments.find(
                  (tab) => tab.departmentId === departmentId,
                ) as any,
              )
            }
            department={department}
          />
        );
      })}
    </Container>
  );
};

export const Tabs = memo(TabsComponent);
