import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes/index";

import { Container } from "./styled";

interface TableFiltersProps {
  disabled: boolean;
  groupId: string;
  groupName: string;
}

export const TableFilters = ({
  disabled,
  groupId,
  groupName,
}: TableFiltersProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <Container>
      <Button
        variant="secondary"
        size="small"
        disabled={disabled}
        onClick={() => {
          searchParams.set("groupName", groupName);
          navigate({
            pathname: routes.pageManageGroupMembers(groupId),
            search: searchParams.toString(),
          });
        }}
      >
        Adicionar integrantes
      </Button>
    </Container>
  );
};
