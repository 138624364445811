import { GROUP_EMPLOYEES_QUERY } from "@/api/queries/groups";
import { useQuery } from "@apollo/client";
import { ErrorBoundary, dispatchToast } from "@/utils";
import { useState } from "react";
import {
  GroupEmployeesQuery,
  GroupEmployeesQuery_group_employees,
} from "@/api/queries/groups/__generated__/GroupEmployeesQuery";
import { useCompany } from "@/lib";

export const useGroupEmployees = (groupId: string) => {
  const { companyId } = useCompany();
  const [groupEmployees, setGroupEmployees] = useState<
    (GroupEmployeesQuery_group_employees & {
      checked: boolean;
      hidden: boolean;
      _id: string;
    })[]
  >();

  const { loading, data } = useQuery<GroupEmployeesQuery>(
    GROUP_EMPLOYEES_QUERY,
    {
      variables: {
        groupId,
        companyId,
      },
      onCompleted: (data) => {
        setGroupEmployees(() => {
          return data.group?.employees?.map((employee) => ({
            ...employee,
            checked: false,
            hidden: false,
            _id: employee?.id,
          })) as any;
        });
      },
      onError: (error) => {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro ao buscar os membros do grupo.",
        });
      },
      fetchPolicy: "network-only",
    },
  );

  function updateEmployeesFromGroup(
    employees: (GroupEmployeesQuery_group_employees & {
      checked: boolean;
      hidden: boolean;
      _id: string;
    })[],
  ) {
    setGroupEmployees(employees);
  }

  function checkGroupEmployees(
    data: (GroupEmployeesQuery_group_employees & {
      checked: boolean;
      hidden: boolean;
      _id: string;
    })[],
    allChecked: boolean,
  ) {
    setGroupEmployees((prev: any) => {
      return data?.map((row, index) => ({
        ...prev?.[index],
        checked: allChecked !== undefined ? allChecked : row?.checked,
      }));
    });
  }

  const employeesToRemove = data?.group?.employees?.filter(
    (employee) => !groupEmployees?.some(({ id }) => id === employee?.id),
  );
  const employeesToAdd = groupEmployees?.filter(
    (employee) => !data?.group?.employees?.some(({ id }) => id === employee.id),
  );

  return {
    isGroupEmployeesLoading: loading,
    groupEmployees,
    employeesToRemove,
    employeesToAdd,
    updateEmployeesFromGroup,
    checkGroupEmployees,
  };
};
