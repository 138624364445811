import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EmptyState,
  Icons,
  LinkButton,
  PageContainer,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Table } from "@departments/components";
import { DEPARTMENTS_PAGE, DEPARTMENTS_PAGE_CREATE } from "@departments/events";
import { useGetDepartments } from "@departments/hooks";
import { useGetDepartmentWithMembers } from "@features/departments/hooks/get-department-with-members";
import { DepartmentTabCard } from "@features/departments/types";
import { ResultState, setEventTracking, usePageTracking } from "@utils";
import { routes } from "src/routes";

import { Header, Skeleton, Tabs } from "./components";
import { Content, StyledBreadcrumbs } from "./styled";

export const DepartmentsPage = () => {
  usePageTracking(DEPARTMENTS_PAGE);
  const navigate = useNavigate();
  const [selectedDepartment, setSelectedDepartment] =
    useState<DepartmentTabCard>(undefined as any);
  const isFirstRender = useRef(true);

  const { departments, state } = useGetDepartments();
  const {
    getMembers,
    members,
    loading: isGetMembersLoading,
  } = useGetDepartmentWithMembers();

  const isLoading = state === ResultState.LOADING || state === ResultState.IDLE;
  const isEmpty = !isLoading && !departments.length;
  const hasDepartments = useMemo(
    () => !isLoading && !isEmpty && !!departments.length,
    [isLoading, isEmpty, departments],
  );

  useEffect(() => {
    if (isFirstRender.current && departments.length) {
      setSelectedDepartment(departments[0]);
      isFirstRender.current = false;
    }
  }, [departments]);

  useEffect(() => {
    if (selectedDepartment?.departmentId) {
      getMembers(selectedDepartment?.departmentId);
    }
  }, [selectedDepartment?.departmentId]);

  return (
    <PageContainer style={{ height: "100%" }}>
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton
            variant="secondary"
            onClick={() => navigate(routes.pageInitial, { state: { tab: 1 } })}
          >
            Gerenciar empresa
          </LinkButton>,
          <LinkButton variant="secondary">Departamentos</LinkButton>,
        ]}
      />
      <Header departmentsQt={departments.length.toString()} />
      {isLoading && <Skeleton />}
      {isEmpty && (
        <EmptyState
          buttonText={
            <>
              <span>Criar novo departamento</span>
              <Icons name="IconPlus" />
            </>
          }
          description="Você ainda não criou nenhum departamento."
          buttonProps={{
            size: "medium",
            onClick: () => {
              navigate(routes.departmentCreate);
              setEventTracking(DEPARTMENTS_PAGE_CREATE, {
                departments_qt: departments?.length.toString(),
              });
            },
          }}
        />
      )}
      {hasDepartments && (
        <Content>
          <Tabs
            departments={departments}
            selectedTab={selectedDepartment}
            setSelectedTab={setSelectedDepartment}
          />
          <Table
            loading={isGetMembersLoading}
            members={members}
            departmentId={selectedDepartment?.departmentId || ""}
            departmentName={selectedDepartment?.name || ""}
          />
        </Content>
      )}
    </PageContainer>
  );
};
