import gql from "graphql-tag";

export const GROUP_EMPLOYEES_QUERY = gql`
  query GroupEmployeesQuery($groupId: ID!, $companyId: ID) {
    group(groupId: $groupId, companyId: $companyId) {
      employees(companyId: $companyId) {
        id
        name
        email
        groups {
          id
          name
        }
      }
    }
  }
`;
