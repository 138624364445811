import { useState } from "react";
import { useQuery } from "@apollo/client";

import { GET_ROLES } from "@/api/queries/roles";
import {
  GetRoles,
  GetRoles_roles,
} from "@/api/queries/roles/__generated__/GetRoles";
import { useCompany } from "@/lib";
import {
  dispatchToast,
  ErrorBoundary,
  HookResult,
  useResultState,
} from "@/utils";

type GetRolesHookResult = HookResult<GetRoles_roles[]> & {
  roles: GetRoles_roles[];
};

export const useGetRoles = (): GetRolesHookResult => {
  const { companyId } = useCompany();
  const { setSuccess, setFailed, ...resultStateProps } = useResultState<
    GetRoles_roles[]
  >({
    loading: true,
  });
  const [roles, setRoles] = useState<GetRoles_roles[]>([]);

  useQuery<GetRoles>(GET_ROLES, {
    variables: {
      companyId,
    },
    onCompleted: (data) => {
      setSuccess(data.roles as any);
      setRoles(data.roles as any);
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content:
          "Sentimos muito, ocorreu um erro ao buscar os cargos da empresa.",
      });
      setFailed(error);
    },
    fetchPolicy: "network-only",
  });

  return {
    roles,
    ...resultStateProps,
  };
};
