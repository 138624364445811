import { useEffect, useState } from "react";
import { Button, Icons, Toggle } from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "@Components/Divider";
import { FieldComponent } from "@Components/FieldComponent";
import { EmptyPreviewSVG } from "src/assets";
import {
  fieldComponentKeyByValue,
  FieldComponentNames,
  FieldComponentTypes,
} from "src/common/field";

import { Footer } from "./Footer";
import {
  AddOptionsWrapper,
  Container,
  Content,
  Description,
  ExitButton,
  FieldDataWrapper,
  LeftWrapper,
  OptionTitle,
  OptionWrapper,
  RightWrapper,
  StyledModal,
  Title,
  ToggleDescription,
  ToggleText,
  ToggleTextWrapper,
  ToggleWrapper,
} from "./styled";

type FieldData = {
  name: string;
  component: FieldComponentTypes;
  sensitive: boolean;
  options: string[];
};

type Field = FieldData & { value: any };

export interface ModalConfirmActionInterface {
  isOpen: boolean;
  loading: boolean;
  fieldData?: FieldData;
  onClose: () => void;
  onSubmit: ({ name, component, sensitive, options }: FieldData) => void;
}

export const ModalConfigureField = ({
  isOpen,
  loading,
  fieldData,
  onClose,
  onSubmit,
}: ModalConfirmActionInterface) => {
  const [field, setField] = useState<Field | undefined>();

  const [configureOptions, setConfigureOptions] = useState<any[] | undefined>(
    undefined,
  );

  const handleClose = () => {
    setField(undefined);
    onClose();
  };

  const handleDisabled = () => {
    const baseDisabled = !field?.name || !field?.component;

    if (field?.component === "select" || field?.component === "multiSelect") {
      return baseDisabled || field?.options?.some((o) => !o);
    } else return baseDisabled;
  };

  useEffect(() => {
    setField({
      ...(field as Field),
      options: configureOptions?.length
        ? configureOptions?.map((o) => o.value)
        : [],
    });
  }, [configureOptions]);

  useEffect(() => {
    setField({ ...fieldData, value: "" } as Field);
  }, [fieldData]);

  return (
    <StyledModal
      open={isOpen}
      onClose={handleClose}
      footer={
        <Footer
          onSubmit={() => {
            setField(undefined);
            onSubmit(field as Field);
          }}
          onClose={handleClose}
          loading={loading}
          disabled={handleDisabled()}
        />
      }
    >
      <Container>
        <Content>
          <LeftWrapper>
            <Title variant="headline8">Configurar campo</Title>
            <Description variant="body4">
              Configure as informações do campo
            </Description>
            <FieldDataWrapper>
              <div>
                <FieldComponent
                  name="Selecionar campo"
                  type={"array"}
                  component={"select"}
                  value={field?.component}
                  onChange={(value) => {
                    if (value === "select" || value === "multiSelect") {
                      setConfigureOptions([
                        {
                          name: "Insira uma opção",
                          value: "",
                          key: Math.random().toString(),
                        },
                      ]);
                    }
                    setField({
                      ...(field as Field),
                      component: value,
                    });
                  }}
                  options={FieldComponentNames.map((component) => {
                    return {
                      label: component,
                      value: fieldComponentKeyByValue(component),
                    };
                  })}
                />
              </div>
              <Divider horizontal />
              <FieldComponent
                name="Nome do campo"
                type={"text"}
                component={"shortText"}
                onChange={(value) =>
                  setField({ ...(field as Field), name: value })
                }
                value={field?.name}
              />
              <Divider horizontal />
              {(field?.component === "select" ||
                field?.component === "multiSelect") && (
                <AddOptionsWrapper>
                  <OptionTitle variant="headline8">
                    Adicione as opções
                  </OptionTitle>
                  {configureOptions?.map((option, index) => (
                    <OptionWrapper key={Math.random.toString()}>
                      <FieldComponent
                        name={option.name}
                        component={"shortText"}
                        type={"text"}
                        onChange={(value) => {
                          setConfigureOptions([
                            ...configureOptions.slice(0, index),
                            { ...option, value },
                            ...configureOptions.slice(index + 1),
                          ]);
                        }}
                      />
                      {configureOptions?.length > 1 && (
                        <Icons
                          name="IconTrash"
                          size={24}
                          fill="transparent"
                          onClick={() => {
                            setConfigureOptions([
                              ...configureOptions.slice(0, index),
                              ...configureOptions.slice(index + 1),
                            ]);
                          }}
                        />
                      )}
                    </OptionWrapper>
                  ))}
                  <Button
                    variant="primary"
                    size="small"
                    style={{ marginLeft: "auto" }}
                    disabled={
                      field?.options?.[field?.options.length - 1].length === 0
                    }
                    onClick={() =>
                      setConfigureOptions([
                        ...(configureOptions as any[]),
                        {
                          name: "Insira uma opção",
                          value: "",
                          key: Math.random().toString(),
                        },
                      ])
                    }
                  >
                    Adicionar opção
                  </Button>
                </AddOptionsWrapper>
              )}
              <ToggleWrapper>
                <Toggle
                  value={field?.sensitive}
                  onChange={(_, checked) =>
                    setField({ ...(field as Field), sensitive: checked })
                  }
                />
                <ToggleTextWrapper>
                  <ToggleText variant="headline8">Dado sensível</ToggleText>
                  <ToggleDescription variant="caption">
                    Apenas será visto por usuários autorizados.
                  </ToggleDescription>
                </ToggleTextWrapper>
              </ToggleWrapper>
            </FieldDataWrapper>
          </LeftWrapper>
          <Divider horizontal removeAt={768} />
          <Divider vertical appearAt={768} />
          <RightWrapper>
            <Title variant="headline8">Preview</Title>
            <Description variant="body4">
              Confira a pré-visualização do campo
            </Description>
            {!!!field?.component && (
              <>
                <EmptyPreviewSVG style={{ margin: "32px auto" }} />
                <Description variant="body4" style={{ textAlign: "center" }}>
                  Você ainda não selecionou nenhum campo
                </Description>
              </>
            )}
            {!!field?.component && (
              <FieldComponent
                options={
                  field?.options?.map((o) => ({ label: o, value: o })) || []
                }
                name={field?.name || ""}
                component={field?.component}
                value={
                  field?.component === "multiSelect" ? undefined : field?.value
                }
                onChange={(value) => setField({ ...field, value })}
              />
            )}
          </RightWrapper>
        </Content>
        <ExitButton
          icon="IconX"
          onClick={handleClose}
          variant="line"
          size="small"
        />
      </Container>
    </StyledModal>
  );
};
