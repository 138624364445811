import { gql } from "@apollo/client";

export const GET_ROLES = gql`
  query GetRoles($companyId: ID!) {
    roles(companyId: $companyId) {
      id
      name
      description
      cbo
    }
  }
`;
