import { Button, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div``;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-x: scroll;

  @media screen and (min-width: 1024px) {
    flex-direction: column;
    overflow-x: initial;
  }
`;

export const SectionSkeleton = styled(Skeleton)`
  width: 312px;
  min-width: 140px;
  height: 52px;
  border-radius: 6px;
`;

export const ContentSkeleton = styled(Skeleton)`
  width: 100%;
  height: 500px;
  border-radius: 6px;
`;

export const StyledButton = styled(Button)`
  width: 312px;
  min-width: fit-content;
`;

export const Content = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin-bottom: 40px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`;
