interface TransferListCellProps {
  row: { original: { name?: string; email: string } };
}

import { EmailField, EmployeeName, TableData, TableDataArea } from "./styled";

export const TransferListCell = ({
  row: {
    original: { name = "", email },
  },
}: TransferListCellProps) => (
  <TableData>
    <TableDataArea>
      <EmployeeName variant="body3">{name}</EmployeeName>
      {email && <EmailField variant="body3">{email}</EmailField>}
    </TableDataArea>
  </TableData>
);
