import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const FooterContainer = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-style: solid hidden hidden hidden;
  padding: 24px 92px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FooterCancelButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: flex-end;

  text-decoration: underline;

  text-underline-offset: 10px;

  color: var(--color-secondary-50);

  margin: 16px 0;
`;

export const SubmitButton = styled(Button)`
  color: var(--color-secondary-50);
`;

export const SubmitButtonText = styled.div`
  color: var(--color-neutral-100);
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`;
