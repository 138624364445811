import { useQuery } from "@apollo/react-hooks";
import { dispatchToast, HookResult, useResultState } from "@utils";
import { Dispatch, SetStateAction, useState } from "react";
import { GET_DEPARTMENT } from "src/api/queries/getDepartment";
import { GetDepartment } from "src/api/queries/__generated__/GetDepartment";
import { Department } from "../types";
import { useSession } from "src/common/user";
import { ErrorBoundary } from "@utils/ErrorBoundary";

type GetDepartmentsHookResult = HookResult<Department> & {
  department?: Department;
  setDepartment: Dispatch<SetStateAction<Department | undefined>>;
};

export const useGetDepartmentById = (
  departmentId: string,
): GetDepartmentsHookResult => {
  const { setSuccess, setFailed, ...resultStateProps } =
    useResultState<Department>({
      loading: true,
    });

  const { companyId } = useSession();

  const [department, setDepartment] = useState<Department>();

  useQuery<GetDepartment>(GET_DEPARTMENT, {
    variables: {
      companyId,
      departmentId,
    },
    onCompleted: ({ getDepartment }) => {
      const data: Department = {
        id: getDepartment?._id,
        name: getDepartment?.name,
        description: getDepartment?.description,
        externalId: getDepartment?.externalId,
      } as any;
      setDepartment(data);
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content:
          "Sentimos muito, ocorreu um erro ao buscar os departamentos da empresa.",
      });
      setFailed(error);
    },
    fetchPolicy: "network-only",
  });

  return {
    department,
    setDepartment,
    ...resultStateProps,
  };
};
