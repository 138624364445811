import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";

import { Footer } from "./components/Footer";
import {
  Container,
  TextWrapper,
  Subtitle,
  Title,
  StyledModal,
  Warning,
  AlertWrapper,
  AlterIcon,
} from "./styled";
import { ModalConfirmActionInterface } from "./types";
import { setEventTracking } from "@Utils/eventTracking";
import { DEPARTMENT_CREATE_SKIP_MODAL_EXIT } from "@departments/events";

export const ModalConfirmSkip = ({
  isOpen,
  loading,
  title,
  submitText,
  description,
  onClose,
  onSubmit,
}: ModalConfirmActionInterface) => {
  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      footer={
        <Footer
          onClose={onClose}
          onSubmit={onSubmit}
          submitText={submitText}
          loading={loading}
        />
      }
    >
      <Container>
        <TextWrapper>
          <AlertWrapper>
            <AlterIcon name="IconAlertCircle" size={64} fill="transparent" />
          </AlertWrapper>
          <Warning variant="body3">Atenção!</Warning>
          <Title>{title}</Title>
          <Subtitle>{description}</Subtitle>
        </TextWrapper>
        <IconButton
          icon="IconX"
          onClick={() => {
            onClose();
            setEventTracking(DEPARTMENT_CREATE_SKIP_MODAL_EXIT);
          }}
          variant="line"
          size="small"
        />
      </Container>
    </StyledModal>
  );
};
