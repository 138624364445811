import { useEffect, useMemo, useState } from "react";
import { IconButton, Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";
import { useMutation } from "@apollo/client";
import { ModalConfirmAction } from "@Components/ModalConfirmAction";
import dispatchToast from "@Utils/dispatchToast";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { UPDATE_GROUP_MEMBERS } from "src/api";

import { COMPANY_GROUPS_QUERY } from "@/api/queries/groups";

import { Option } from "./styled";
import { IEmployeeOptions, TOption } from "./types";

interface Member {
  _id: string;
  groups: {
    _id: string;
    name: string;
  }[];
}
interface UpdateGroupMembersParams {
  groupId: string;
  groupName: string;
  membersToAdd: Member[];
  membersToRemove: Member[];
}

export const EmployeeOptions = ({
  employeeId,
  groups,
  groupId,
  groupName,
}: IEmployeeOptions) => {
  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false);

  const [updateGroupMembers, { loading }] = useMutation<
    void,
    UpdateGroupMembersParams
  >(UPDATE_GROUP_MEMBERS, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: "Pessoa desvinculada com sucesso!",
      });
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content:
          "Ocorreu um erro ao desvincular a pessoa. Por favor, tente novamente mais tarde.",
      });
    },
    refetchQueries: [COMPANY_GROUPS_QUERY],
    awaitRefetchQueries: true,
  });

  const options: TOption[] = useMemo(() => {
    let opt: TOption[] = [
      {
        key: "profile",
        children: (
          <Option onClick={() => setConfirmRemoveModal(true)}>
            <Icons name="IconUserOff" fill="transparent" />
            Desvincular do grupo
          </Option>
        ),
      },
    ];

    return opt;
  }, [employeeId]);

  useEffect(() => {
    if (loading)
      dispatchToast({
        type: "warning",
        content: "Devinculando pessoa do grupo...",
      });
  }, [loading]);

  return (
    <>
      <Menu
        type={"select"}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: 0, horizontal: -220 }}
      >
        <IconButton variant="line" size="small" icon="IconDotsVertical" />
      </Menu>
      <ModalConfirmAction
        isOpen={confirmRemoveModal}
        loading={loading}
        title="Tem certeza que deseja desvincular essa pessoa?"
        description="A pessoa selecionada ficará sem atribuição de grupo e todas as configurações realizadas serão perdidas."
        confirmWord="desvincular"
        submitText={
          <>
            <span>Confirmar</span>
            <Icons name="IconTrash" size={30} fill="transparent" />
          </>
        }
        onClose={() => setConfirmRemoveModal(false)}
        onSubmit={async () => {
          setConfirmRemoveModal(false);
          updateGroupMembers({
            variables: {
              groupId: groupId,
              groupName: groupName,
              membersToAdd: [],
              membersToRemove: [
                {
                  _id: employeeId,
                  // removing _typename to avoid error on backend
                  groups: groups.map((group) => ({
                    _id: group.id,
                    name: group.name,
                  })),
                },
              ],
            },
          });
        }}
      />
    </>
  );
};
