import {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { TransferList } from "@flash-tecnologia/hros-web-ui-v2";
import { ListType } from "@flash-tecnologia/hros-web-ui-v2/dist/components/TransferList/types";
import { debounce } from "lodash-es";

import { FilterStatusEnum } from "@/common/StatusEnum";
import { Box, TransferListEmptyState } from "@/components";
import { useLazyGetEmployeesV2 } from "@/features/employees/hooks";
import { DataTypeEmployee } from "@/features/roles/types";

import { useGetDepartmentWithMembers } from "../../hooks/get-department-with-members";
import { Employee } from "../../types";

import { EmailField, EmployeeName } from "./components/TransferListCell/styled";
import {
  Container,
  StyledDescription,
  StyledSubtitle,
  StyledTitle,
  TextWrapper,
  TransferListArea,
} from "./styled";

interface CreateTransferListProps {
  title: string;
  members: Employee[];
  setMembers: Dispatch<SetStateAction<Employee[]>>;
}

const PAGE_SIZE_DEFAULT_STEP = 20;

export const CreateTransferList = ({
  title,
  members,
  setMembers,
}: CreateTransferListProps) => {
  const { departmentId } = useParams();
  const [paginationState, setPaginationState] = useState({
    pageNumber: 1,
    index: 0,
    pageSize: PAGE_SIZE_DEFAULT_STEP,
  });

  const [searchLeftState, setSearchLeftState] = useState("");
  const [searchRightState, setSearchRightState] = useState("");
  const [transferList, setTransferList] = useState<{
    employeesLeft: DataTypeEmployee[];
    membersRight: DataTypeEmployee[];
  }>({
    employeesLeft: [],
    membersRight: [],
  });

  const {
    getEmployeesV2,
    loading: getEmployeesLeftLoading,
    employees,
    totalCount: totalEmployees,
  } = useLazyGetEmployeesV2();

  const {
    getMembers,
    employees: departmentMembers,
    loading: getMembersRightLoading,
  } = useGetDepartmentWithMembers();

  useEffect(() => {
    getEmployeesV2({
      page: paginationState.pageNumber,
      limit: paginationState.pageSize,
      status: FilterStatusEnum.REMOVE_INACTIVE,
      query: searchLeftState || undefined,
    });
  }, []);

  useEffect(() => {
    if (!!employees) {
      setTransferList((prev) => ({ ...prev, employeesLeft: employees }));
    }
  }, [employees]);

  useEffect(() => {
    if (departmentId) {
      getMembers(departmentId);
    }
  }, [departmentId]);

  useEffect(() => {
    if (!!departmentMembers) {
      setTransferList((prev) => ({ ...prev, membersRight: departmentMembers }));
    }
  }, [departmentMembers]);

  useLayoutEffect(() => {
    const transferListContainer: any = document.getElementsByClassName(
      "transfer-list-filter-search-field-container",
    );

    if (!transferListContainer.length) return;

    Array.from(transferListContainer).forEach((item: any) => {
      if (!item?.childNodes?.length) return;
      item.childNodes[0].style.width = "100%";
    });
  }, []);

  const columns = [
    {
      Header: "Nome",
      accessor: "name",
      Cell: ({ row }: { row: any }) => (
        <Box display="flex">
          <Box display="flex" flexDirection="column" justifyContent="center">
            <EmployeeName variant="body3">{row.original.name}</EmployeeName>
            {row.original.email && (
              <EmailField variant="body3">{row.original.email}</EmailField>
            )}
          </Box>
        </Box>
      ),
    },
  ];

  const employeesLeft = transferList.employeesLeft.filter(
    (left) =>
      !transferList.membersRight.some((right) => right._id === left._id),
  );

  const handleCheck = ({
    data,
    allChecked,
    side,
  }: {
    data: DataTypeEmployee[];
    allChecked: boolean;
    side: "left" | "right";
  }) => {
    setTransferList((prev) => {
      if (side === "left") {
        return {
          ...prev,
          employeesLeft: data.map((item) => ({
            ...item,
            checked: allChecked !== undefined ? allChecked : item?.checked,
          })),
        };
      }
      return {
        ...prev,
        membersRight: data.map((item, index) => ({
          ...prev.membersRight[index],
          checked: allChecked !== undefined ? allChecked : item?.checked,
        })),
      };
    });
  };

  const onScrollReachEnd = (reachedEnd: boolean) => {
    const scrollReachedEnd =
      employeesLeft?.length + transferList.membersRight.length <
        totalEmployees && reachedEnd;

    const lastCellIsValidEmployee =
      typeof employeesLeft?.[employeesLeft?.length - 1]?.name === "string";

    if (scrollReachedEnd && lastCellIsValidEmployee) {
      setPaginationState((prev) => ({
        ...prev,
        pageNumber: paginationState.pageNumber,
        pageSize: paginationState.pageSize + PAGE_SIZE_DEFAULT_STEP,
      }));

      getEmployeesV2({
        page: paginationState.pageNumber,
        limit: paginationState.pageSize,
        status: FilterStatusEnum.REMOVE_INACTIVE,
        query: searchLeftState || undefined,
      });
    }
  };

  const handleLeftSearch = (value: string) => {
    setSearchLeftState(value);
    getEmployeesV2({
      page: paginationState.pageNumber,
      limit: paginationState.pageSize,
      status: FilterStatusEnum.REMOVE_INACTIVE,
      query: value || undefined,
    });
  };

  const debouncedOnLeftSearch = useMemo(() => {
    return debounce(handleLeftSearch, 300);
  }, []);

  const leftList: ListType = {
    data: employeesLeft,
    total: totalEmployees,
    title: `Todas as pessoas (${totalEmployees})`,
    loading: getEmployeesLeftLoading,
    onScroll: (_, reachedEnd) => {
      onScrollReachEnd(reachedEnd);
    },
    onCheck: ({ allChecked, data }) => {
      handleCheck({
        side: "left",
        allChecked,
        data: data as DataTypeEmployee[],
      } as any);
    },
    onSearch: (leftSearch: string) => {
      if (leftSearch == searchLeftState) return;
      debouncedOnLeftSearch(leftSearch);
    },
    emptyStageMessage: (
      <TransferListEmptyState
        empty={transferList.employeesLeft?.length === 0}
        loading={
          !transferList.employeesLeft?.length && !getEmployeesLeftLoading
        }
      />
    ),
  };

  useEffect(() => {
    return () => {
      debouncedOnLeftSearch.cancel();
    };
  }, [debouncedOnLeftSearch]);

  useEffect(() => {
    setMembers(transferList.membersRight as Employee[]);
  }, [transferList.membersRight]);

  const filteredRightEmployees = !!searchRightState.length
    ? transferList.membersRight.filter((member) =>
        member.name.toLowerCase().includes(searchRightState),
      )
    : transferList.membersRight;

  const debouncedOnRightSearch = useMemo(() => {
    return debounce((value: string) => {
      setSearchRightState(value.toLowerCase());
    }, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedOnRightSearch.cancel();
    };
  }, [debouncedOnRightSearch]);

  const rightList: ListType = {
    data: filteredRightEmployees,
    total: transferList.membersRight.length,
    title: `Selecionados para o cargo (${
      transferList.membersRight.length || 0
    })`,
    loading: getMembersRightLoading,
    onCheck: ({ allChecked, data }) => {
      handleCheck({
        side: "right",
        allChecked,
        data: data as DataTypeEmployee[],
      } as any);
    },
    onSearch: (rightSearch) => {
      debouncedOnRightSearch(rightSearch);
    },
    emptyStageMessage: (
      <TransferListEmptyState
        empty={transferList.membersRight?.length === 0}
        loading={!transferList.membersRight?.length && !getMembersRightLoading}
      />
    ),
  };

  return (
    <div>
      <StyledTitle variant="headline6">{title}</StyledTitle>
      <Container>
        <TextWrapper>
          <StyledSubtitle variant="headline8">
            Selecione pela lista
          </StyledSubtitle>
          <StyledDescription variant="body3">
            Escolha quais pessoas você gostaria de adicionar à esse
            departamento.
          </StyledDescription>
        </TextWrapper>

        <TransferListArea>
          <TransferList
            columns={columns}
            onTransfer={({ leftList, rightList }) => {
              setTransferList({
                employeesLeft: leftList.data as DataTypeEmployee[],
                membersRight: rightList.data as DataTypeEmployee[],
              });
            }}
            leftList={leftList}
            rightList={rightList}
          />
        </TransferListArea>
      </Container>
    </div>
  );
};
