import { gql } from "@apollo/client";

export const GET_DEPARTMENT = gql`
  query GetDepartment($companyId: ID!, $departmentId: ID!) {
    getDepartment(companyId: $companyId, departmentId: $departmentId) {
      _id
      name
      description
      externalId
    }
  }
`;
