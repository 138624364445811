import { CompanyGroupsQuery_getCompany_groups_employees } from "@/api/queries/groups/__generated__/CompanyGroupsQuery";

import { CardOptions } from "../CardOptions";

import { Card, TagStyled, Title, Wrapper } from "./styled";

export type BaseTabCardType = { key: string; title: string };

export interface TabCardInterface extends BaseTabCardType {
  id: string;
  isSelected: boolean;
  setSelectedTab: (key: string) => void;
  totalCount: number;
  employees: CompanyGroupsQuery_getCompany_groups_employees[];
}

export const TabCard = ({
  id,
  isSelected,
  title,
  setSelectedTab,
  totalCount,
  employees,
}: TabCardInterface) => {
  return (
    <Card key={id} selected={isSelected} onClick={() => setSelectedTab(id)}>
      <Title variant="body3" tag="span" selected={isSelected}>
        {title}
      </Title>
      <Wrapper>
        <TagStyled disabled={true} variant={isSelected ? "gray" : "primary"}>
          {`${totalCount}`}
        </TagStyled>
        <CardOptions
          groupId={id}
          active={false}
          groupName={title}
          members={employees}
        />
      </Wrapper>
    </Card>
  );
};
