import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import { AddressFields } from "@Components/AddressFields";
import { validateCNPJ } from "@Utils/validateCNPJ";
import { useFormik } from "formik";
import { routes } from "src/routes";
import * as yup from "yup";

import { FieldComponent } from "@/components/FieldComponent";
import { useGetEconomicGroupCompanies } from "@/features/economicGroup/hooks";
import { useCompany } from "@/features/economicGroup/hooks/use-company";

import { Footer, StyledButton } from "../styled";

import {
  ContentText,
  FormContainer,
  InputContainer,
  MainContainer,
  StyledInput,
  TextContainer,
  TitleText,
} from "./styles";

const validationSchema = yup.object({
  registrationNumber: yup.string().required("Favor digitar um CNPJ"),
  legalName: yup.string().required(),
  zipCode: yup.string().required(),
  street: yup.string().required(),
  number: yup.number().required(),
  district: yup.string().required(),
  state: yup.string().required(),
  city: yup.string().required(),
});

export const CompanyDataForm = forwardRef((_, ref: any) => {
  const navigate = useNavigate();
  const { company, setAddress, setFields } = useCompany();
  const [selectedCompany, setSelectedCompany] = useState();
  const [shouldUseExistingCompanyAddress, setShouldUseExistingCompanyAddress] =
    useState(false);

  const initialState = {
    legalName: "",
    name: "",
    registrationNumber: "",
    zipCode: "",
    street: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    state: "",
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
    validate: (values) => {
      let errors: any = {};

      if (!validateCNPJ(values.registrationNumber))
        errors.registrationNumber = "Favor digitar um CNPJ válido.";
      return errors;
    },

    onSubmit: async (values) => {
      setFields({
        legalName: values.legalName,
        name: values.name,
        registrationNumber: values.registrationNumber.replace(/[^\d]+/g, ""),
      });

      setAddress({
        zipCode: values.zipCode.replace(/[^\d]+/g, ""),
        street: values.street,
        number: values.number,
        complement: values.complement || undefined,
        district: values.district,
        city: values.city,
        state: values.state,
      });

      navigate(routes.pageAddCompany + "/contract");
    },
  });

  useEffect(() => {
    if (company) {
      const { setFieldValue } = formik;

      setFieldValue("legalName", company.legalName);
      setFieldValue("name", company.name);
      setFieldValue("registrationNumber", company.registrationNumber);
      setFieldValue("zipCode", company.address.zipCode);
      setFieldValue("street", company.address.street);
      setFieldValue("number", company.address.number);
      setFieldValue("complement", company.address.complement);
      setFieldValue("district", company.address.district);
      setFieldValue("city", company.address.city);
      setFieldValue("state", company.address.state);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      formik.handleSubmit();
    },
  }));

  useEffect(() => {
    // Fill address
    if (selectedCompany) {
      const { address } = companies.find(
        (c) => c.legalName === selectedCompany,
      ) as any;
      const { setFieldValue } = formik;

      setFieldValue("city", address.city);
      setFieldValue("district", address.district);
      setFieldValue("number", address.number);
      setFieldValue("state", address.state);
      setFieldValue("zipCode", address.zipCode);
      setFieldValue("street", address.street);

      if (address?.complement) setFieldValue("complement", address.complement);
    }
  }, [selectedCompany]);

  const { companies } = useGetEconomicGroupCompanies();
  const validCompanies = useMemo(
    () => companies.filter((c) => !!c?.address),
    [companies],
  );

  const companyOptions = validCompanies.map((c) => ({
    label: c.name || c.legalName,
    value: c.legalName,
  }));

  return (
    <>
      <MainContainer>
        <TextContainer>
          <TitleText
            variant="headline7"
            children="Informe os dados da empresa"
            style={{ maxWidth: "195px", color: "#F20D7A" }}
          />
          <ContentText
            variant="body3"
            children="Essas informações são importantes para a criação do contrato inicial da empresa. Ela nascerá com os mesmos produtos já contratados pela matriz."
            style={{ marginBottom: "14px" }}
          />
          <ContentText
            variant="body3"
            children="A gestão de contratos e outras configurações poderá feita na área de gerenciamento da empresa."
          />
        </TextContainer>
        <FormContainer>
          <InputContainer>
            <StyledInput
              id="registrationNumber"
              name="registrationNumber"
              label="CNPJ"
              placeholder="XX.XXX.XXX/XXXX-XX"
              value={formik.values.registrationNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.registrationNumber &&
                Boolean(formik.errors.registrationNumber)
              }
              helperText={
                formik.touched.registrationNumber &&
                formik.errors.registrationNumber
              }
              imaskProps={{
                mask: "00.000.000/0000-00",
              }}
            />
          </InputContainer>
          <InputContainer>
            <StyledInput
              id="legalName"
              name="legalName"
              label="Razão Social"
              value={formik.values.legalName}
              onChange={formik.handleChange}
              error={
                formik.touched.legalName && Boolean(formik.errors.legalName)
              }
              helperText={formik.touched.legalName && formik.errors.legalName}
            />
          </InputContainer>
          <InputContainer>
            <StyledInput
              id="name"
              name="name"
              label="Nome fantasia (Opcional)"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </InputContainer>
          {validCompanies.length > 0 && (
            <InputContainer>
              <FieldComponent
                component="checkbox"
                name="Utilizar endereço de uma empresa já cadastrada"
                onChange={() => {
                  setShouldUseExistingCompanyAddress((prev) => !prev);
                }}
                value={shouldUseExistingCompanyAddress}
              />
              {shouldUseExistingCompanyAddress && (
                <FieldComponent
                  component="select"
                  options={companyOptions}
                  name="Selecione uma empresa"
                  onChange={setSelectedCompany}
                  value={selectedCompany}
                />
              )}
            </InputContainer>
          )}
          <AddressFields
            address={{
              city: formik.values.city,
              state: formik.values.state,
              street: formik.values.street,
              district: formik.values.district,
              zipCode: formik.values.zipCode,
              number: formik.values.number,
              complement: formik.values.complement,
            }}
            editing={true}
            loading={false}
            setAddressValue={(newValue) => {
              formik.setValues({
                ...formik.values,
                city: newValue.city,
                state: newValue.state,
                street: newValue.street,
                district: newValue.district,
                zipCode: newValue.zipCode,
                number: newValue.number as string,
                complement: newValue.complement,
              });
            }}
            addressValue={{
              city: formik.values.city,
              state: formik.values.state,
              street: formik.values.street,
              district: formik.values.district,
              zipCode: formik.values.zipCode,
              number: formik.values.number,
              complement: formik.values.complement,
            }}
            errors={
              {
                city: formik.touched.city && Boolean(formik.errors.city),
                district:
                  formik.touched.district && Boolean(formik.errors.district),
                number: formik.touched.number && Boolean(formik.errors.number),
                state: formik.touched.state && Boolean(formik.errors.state),
                street: formik.touched.street && Boolean(formik.errors.street),
                zipCode:
                  formik.touched.zipCode && Boolean(formik.errors.zipCode),
              } as any
            }
            helperTexts={
              {
                city: formik.touched.city && formik.errors.city,
                district: formik.touched.district && formik.errors.district,
                number: formik.touched.number && formik.errors.number,
                state: formik.touched.state && formik.errors.state,
                street: formik.touched.street && formik.errors.street,
                zipCode: formik.touched.zipCode && formik.errors.zipCode,
              } as any
            }
          />
        </FormContainer>
      </MainContainer>
      <Footer>
        <LinkButton
          variant="primary"
          onClick={() => {
            navigate(routes.pageInitial);
          }}
          style={{ alignSelf: "center" }}
          children={"Cancelar"}
        />
        <StyledButton
          variant="primary"
          size="large"
          children={"Continuar"}
          onClick={() => {
            ref.current.handleSubmit();
          }}
        />
      </Footer>
    </>
  );
});
