import { useNavigate } from "react-router-dom";
import {
  IconButton,
  IconTypes,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PermissionsChecker } from "@Components/PermissionsChecker";
import { RouteTypes } from "src/routes";

import {
  Container,
  IconWrapper,
  StyledIcon,
  TextsWrapper,
  Wrapper,
} from "./styled";

export interface SecondaryTabCard {
  icon: IconTypes;
  title: string;
  description: string;
  redirectTo: Omit<RouteTypes, symbol>;
  isAdminRequired?: boolean;
  permissions?: string[];
  licenseFeatures?: string[];
  unleashFlag?: string;
}

export const SecondaryTabCard = ({
  icon,
  title,
  description,
  redirectTo,
  permissions,
  licenseFeatures,
  isAdminRequired,
  unleashFlag,
}: SecondaryTabCard) => {
  const navigate = useNavigate();

  return (
    <PermissionsChecker
      permissions={permissions}
      licenseFeatures={licenseFeatures}
      isAdminRequired={isAdminRequired}
      unleashFlag={unleashFlag}
    >
      <Container onClick={() => navigate(redirectTo)}>
        <Wrapper>
          <IconWrapper>
            <StyledIcon name={icon} fill={"transparent"} />
          </IconWrapper>
          <TextsWrapper>
            <Typography variant="headline8">{title}</Typography>
            <Typography variant="body4">{description}</Typography>
          </TextsWrapper>
        </Wrapper>
        <IconButton size="medium" icon="IconArrowRight" variant="filled" />
      </Container>
    </PermissionsChecker>
  );
};
