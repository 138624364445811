import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 30px;
  @media screen and (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`;

export const Wrapper = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Subtitle = styled(Typography)`
  color: var(--color-neutral-50);
`;
