import gql from "graphql-tag";

export const UPDATE_GROUP_MEMBERS = gql`
  mutation UpdateGroupMembers(
    $groupId: ID!
    $groupName: String
    $membersToAdd: [UpdateGroupMembersInput]
    $membersToRemove: [UpdateGroupMembersInput]
  ) {
    updateGroupMembers(
      groupId: $groupId
      groupName: $groupName
      membersToAdd: $membersToAdd
      membersToRemove: $membersToRemove
    )
  }
`;
