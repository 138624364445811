import {
  getFromLS,
  useSelectedCompany,
  getAccessTokenPayloadSync,
} from "@flash-tecnologia/hros-web-utility";

interface SelectedCompany {
  id: string;
  name: string;
  registrationNumber: string;
}

export const useSession = () => {
  const { selectedCompany } = useSelectedCompany();
  const { economicGroupId, employeeId } = getAccessTokenPayloadSync();
  const company = selectedCompany as SelectedCompany;
  return {
    userId: employeeId,
    economicGroupId,
    companyId: company.id,
    name: company.name,
    registrationNumber: company.registrationNumber,
  };
};
