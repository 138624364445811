import { dispatchToast, HookResult, useResultState } from "@utils";
import { useState } from "react";
import { Employee } from "../types";
import { useSession } from "src/common/user";
import { GetDepartmentWithMembers } from "src/api/queries/__generated__/GetDepartmentWithMembers";
import { GET_DEPARTMENT_WITH_MEMBERS } from "src/api";
import { useLazyQuery } from "@apollo/client";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { DataTypeEmployee } from "@/features/roles/types";

type GetDepartmentWithMembersHookResult = HookResult<Employee[]> & {
  employees: DataTypeEmployee[];
  members: Employee[];
  getMembers: (departmentId: string) => void;
  loading: boolean;
};

export const useGetDepartmentWithMembers =
  (): GetDepartmentWithMembersHookResult => {
    const { setSuccess, setFailed, setLoading, ...resultStateProps } =
      useResultState<Employee[]>({
        loading: true,
      });

    const { companyId } = useSession();

    const [members, setMembers] = useState<Employee[]>([]);

    const [employees, setEmployees] = useState<DataTypeEmployee[]>([]);

    const [getDepartmentWithMembers, { loading }] =
      useLazyQuery<GetDepartmentWithMembers>(GET_DEPARTMENT_WITH_MEMBERS, {
        onCompleted: ({ getDepartment }) => {
          const employees: Employee[] = getDepartment?.members?.map(
            (member) => ({
              ...member,
              hidden: false,
            }),
          ) as any;
          const employeesDTO = getDepartment?.members?.map<DataTypeEmployee>(
            (member: any) => {
              return {
                _id: member?._id,
                name: member?.name,
                email: member?.email ?? "",
                hidden: false,
                checked: false,
              };
            },
          );
          setEmployees(employeesDTO as any);
          setSuccess(employees);
          setMembers(employees);
        },
        onError: (error) => {
          ErrorBoundary.captureException(error);
          dispatchToast({
            type: "error",
            content:
              "Sentimos muito, ocorreu um erro ao buscar os membros do departamento.",
          });
          setFailed(error);
        },
        fetchPolicy: "network-only",
      });

    const getMembers = (departmentId: string) => {
      setLoading();

      getDepartmentWithMembers({
        variables: {
          companyId,
          departmentId,
        },
      });
    };

    return {
      employees,
      members,
      getMembers,
      loading,
      ...resultStateProps,
    };
  };
