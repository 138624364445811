import { GET_EMPLOYEES_FOR_ROLES } from "@/api/queries/roles/getEmployeesForRoles";
import {
  GetEmployeesForRoles,
  GetEmployeesForRolesVariables,
} from "@/api/queries/roles/__generated__/GetEmployeesForRoles";
import { useCompany } from "@/lib";
import { dispatchToast, ErrorBoundary } from "@/utils";
import { useLazyQuery } from "@apollo/client";
import { GetEmployeesV2Input } from "@grapql-schema";
import { useState } from "react";
import { DataTypeEmployee } from "../types";

export const useLazyGetEmployessForRoles = () => {
  const { companyId } = useCompany();

  const [employees, setEmployees] = useState<DataTypeEmployee[]>([]);

  const [getEmployeesForRolesQuery, { loading, data }] = useLazyQuery<
    GetEmployeesForRoles,
    GetEmployeesForRolesVariables
  >(GET_EMPLOYEES_FOR_ROLES, {
    onCompleted: (data) => {
      const employeesDTO = data.getEmployeesV2?.records?.map<DataTypeEmployee>(
        (record) =>
          ({
            _id: record?.id,
            name: record?.name,
            email: record?.email ?? "",
            hidden: false,
            checked: false,
          } as any),
      );
      setEmployees(employeesDTO as any);
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content: "Ocorreu um erro ao buscar os colaboradores.",
      });
    },
  });

  const getEmployeesForRoles = ({
    search,
    ...input
  }: GetEmployeesV2Input & {
    search?: string;
  }) => {
    return getEmployeesForRolesQuery({
      variables: {
        input: {
          companyId,
          sortBy: "name",
          order: "asc",
          ...(search && { query: search || undefined }),
          ...input,
        },
      },
    });
  };

  return {
    getEmployeesForRoles,
    employees,
    loading,
    totalCount: data?.getEmployeesV2?.recordsMetadata?.totalCount || 0,
    data: data?.getEmployeesV2,
  };
};

export enum FilterStatusEnum {
  REMOVE_INVITATION_PENDING = "-INVITATION_PENDING",
  REMOVE_INVITATION_SENT = "-INVITATION_SENT",
  REMOVE_INVITATION_EXPIRED = "-INVITATION_EXPIRED",
  REMOVE_ACTIVE = "-ACTIVE",
  REMOVE_INACTIVE = "-INACTIVE",
}
