import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";
import { useMutation } from "@apollo/client";
import { ModalConfirmAction } from "@Components/index";
import dispatchToast from "@Utils/dispatchToast";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { DELETE_GROUP } from "src/api/mutations/deleteGroup";
import { UPDATE_GROUP_MEMBERS } from "src/api/mutations/updateGroupMembers";
import { routes } from "src/routes";

import { COMPANY_GROUPS_QUERY } from "@/api/queries/groups";
import { useCompany } from "@/lib";

import { Option, StyledIcon } from "./styled";
import { TOption } from "./types";

export const CardOptions = ({
  groupId,
  active,
  groupName,
  members,
}: {
  groupId: string;
  active: boolean;
  groupName: string;
  members: any[];
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [deactivateModalIsOpen, setDeactivateModalIsOpen] = useState(false);
  const { companyId } = useCompany();

  const [deleteGroup, { loading }] = useMutation(DELETE_GROUP, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: "Grupo excluído com sucesso!",
      });
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content:
          "Ocorreu um erro ao excluir o grupo. Por favor, tente novamente mais tarde.",
      });
    },
    refetchQueries: [COMPANY_GROUPS_QUERY],
    awaitRefetchQueries: true,
  });

  const [updateGroupMembers, _] = useMutation(UPDATE_GROUP_MEMBERS);

  const options: TOption[] = useMemo(() => {
    return [
      {
        key: "edit",
        children: (
          <Option
            onClick={() => {
              searchParams.set("groupName", groupName);
              navigate({
                pathname: routes.pageEditGroup(groupId),
                search: searchParams.toString(),
              });
            }}
          >
            <Icons name="IconPencil" fill="transparent" />
            Editar grupo
          </Option>
        ),
      },
      {
        key: "delete",
        children: (
          <Option onClick={() => setDeactivateModalIsOpen(true)}>
            <Icons name="IconTrash" fill="transparent" color="#C96C01" />
            <span style={{ color: "#C96C01" }}>Excluir grupo</span>
          </Option>
        ),
      },
    ];
  }, [groupId, active]);

  useEffect(() => {
    if (loading)
      dispatchToast({
        type: "warning",
        content: "Excluindo grupo...",
      });
  }, [loading]);

  return (
    <>
      <Menu
        type={"select"}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: 0, horizontal: 30 }}
      >
        <StyledIcon name="IconDotsVertical" size={20} />
      </Menu>
      <ModalConfirmAction
        isOpen={deactivateModalIsOpen}
        loading={loading}
        title="Tem certeza que deseja excluir este grupo?"
        description="As pessoas deste grupo ficarão sem atribuição e todas as configurações realizadas serão perdidas."
        confirmWord="excluir"
        submitText={
          <>
            <span>Confirmar exclusão</span>
            <Icons name="IconTrash" size={30} fill="transparent" />
          </>
        }
        onClose={() => setDeactivateModalIsOpen(false)}
        onSubmit={async () => {
          setDeactivateModalIsOpen(false);
          await deleteGroup({
            variables: {
              groupId: groupId,
              companyId: companyId,
            },
          });

          updateGroupMembers({
            variables: {
              groupId: groupId,
              groupName: groupName,
              membersToAdd: [],
              membersToRemove:
                members?.map((m) => ({
                  _id: m._id,
                  groups: m?.groups.map((g: any) => ({
                    _id: g?.id,
                    name: g?.name,
                  })),
                })) || [],
            },
          });
        }}
      />
    </>
  );
};
