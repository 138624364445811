import { useState } from "react";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { CompanyFieldInput } from "@grapql-schema";
import { useQuery } from "@apollo/client";
import { Divider } from "@Components/Divider";
import { FieldComponent } from "@Components/FieldComponent";
import { FieldPreview } from "@Components/FieldPreview";
import { ModalConfigureField } from "@Components/ModalConfigureField";
import dispatchToast from "@Utils/dispatchToast";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { cloneDeep } from "lodash-es";
import {
  GetCompanySectionsFields,
  GetCompanySectionsFields_getCompany_sectionsFields,
  GetCompanySectionsFields_getCompany_sectionsFields_fields,
} from "src/api/queries/__generated__/GetCompanySectionsFields";
import { GET_COMPANY_SECTIONS_FIELDS } from "src/api/queries/getCompanySectionsFields";
import { FieldComponentTypes, FieldTypeTypes } from "src/common/field";

import {
  CancelButton,
  ConfirmButton,
  Content,
  ContentWrapper,
  DividerWrapper,
  ExistingFieldsActionsWrapper,
  ExistingFieldSelectWrapper,
  FieldComponentSkeleton,
  FormWrapper,
  SecondaryButtonsWrapper,
  SelectedFieldsWrapper,
  StyledDescription,
  StyledDividerText,
  StyledFormTitle,
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledSubtitle,
  StyledTitle,
  SubstextWrapper,
  TextWrapper,
} from "./styled";

export const SecondStepContent = ({
  preConfiguredFields,
  setPreConfiguredFields,
}: {
  preConfiguredFields: CompanyFieldInput[];
  setPreConfiguredFields: React.Dispatch<CompanyFieldInput[]>;
}) => {
  const { selectedCompany } = useSelectedCompany();
  const [selectedSection, setSelectedSection] =
    useState<GetCompanySectionsFields_getCompany_sectionsFields>();

  const [selectedFields, setSelectedFields] =
    useState<GetCompanySectionsFields_getCompany_sectionsFields_fields[]>();

  const { loading, data } = useQuery<GetCompanySectionsFields>(
    GET_COMPANY_SECTIONS_FIELDS,
    {
      variables: { companyId: selectedCompany.id },
      onError: (error) => {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content:
            "Sentimos muito, ocorreu um erro ao buscar as seções e campos. Por favor, tente novamente mais tarde.",
        });
      },
      fetchPolicy: "cache-first",
    },
  );
  const [openSelectExistingField, setOpenSelectExistingField] = useState(false);
  const [modalConfigureFieldOpen, setModalConfigureFieldOpen] = useState(false);
  const [modalEditFieldOpen, setModalEditFieldOpen] = useState({
    isOpen: false,
    fieldId: "",
    name: "",
    component: undefined,
    sensitive: false,
    options: [],
  });

  const renderButton = () => {
    return (
      <StyledPrimaryButton
        size="large"
        variant="primary"
        onClick={() => setOpenSelectExistingField(true)}
      >
        <Icons name="IconLink" size={24} fill="transparent" />
        Adicionar um campo existente
      </StyledPrimaryButton>
    );
  };

  const renderSelectExistingField = () => {
    return (
      <ExistingFieldSelectWrapper>
        {loading ? (
          <>
            <FieldComponentSkeleton />
            <FieldComponentSkeleton />
          </>
        ) : (
          <>
            <FieldComponent
              name={"Selecione uma seção existente"}
              type={"array"}
              component={"select"}
              options={data?.getCompany?.sectionsFields?.map((item) => ({
                label: item?.name,
                value: item,
              }))}
              onChange={(value) => {
                setSelectedSection(value);
                setSelectedFields([]);
              }}
            />
            <FieldComponent
              value={selectedFields}
              name={"Selecione o campo"}
              type={"array"}
              component={"multiSelect"}
              disabled={!!!selectedSection}
              options={selectedSection?.fields?.map((item) => ({
                label: item?.name,
                value: item,
              }))}
              onChange={(value) => {
                setSelectedFields(value);
              }}
            />
          </>
        )}
        <ExistingFieldsActionsWrapper>
          <CancelButton
            size="large"
            variant="secondary"
            onClick={() => {
              setSelectedSection(undefined);
              setSelectedFields(undefined);

              setOpenSelectExistingField(false);
              setSelectedFields([]);
            }}
          >
            Cancelar
          </CancelButton>
          <ConfirmButton
            size="large"
            variant="primary"
            disabled={!!!selectedFields?.length}
            onClick={() => {
              const handleValue = selectedFields?.map((item) => {
                const clonedItem = Object.assign({}, item);

                clonedItem.order = null;
                return clonedItem;
              });

              const clonesPreConfigureFields = Object.assign(
                [],
                preConfiguredFields,
              );
              handleValue?.forEach((e) => clonesPreConfigureFields?.push(e));

              setPreConfiguredFields(clonesPreConfigureFields);
              setSelectedFields([]);
              setOpenSelectExistingField(false);
            }}
          >
            Copiar
          </ConfirmButton>
        </ExistingFieldsActionsWrapper>
      </ExistingFieldSelectWrapper>
    );
  };

  return (
    <>
      <TextWrapper>
        <StyledTitle variant="headline6">Criar seção</StyledTitle>
        <StyledDescription variant="body3">
          Adicione os campos e/ou anexos pertencentes a esta seção.
        </StyledDescription>
      </TextWrapper>
      <Content>
        <SubstextWrapper>
          <StyledSubtitle variant="headline8">Campos</StyledSubtitle>
          <StyledDescription variant="body3">
            Inclua os campos e/ou anexos que pertencem a essa seção.
          </StyledDescription>
        </SubstextWrapper>
        <ContentWrapper>
          <FormWrapper>
            <StyledFormTitle variant="headline8">
              Adicionar campos à seção
            </StyledFormTitle>
            <StyledDescription variant="body3">
              Adicione um campo existente para a nova seção
            </StyledDescription>
            {openSelectExistingField && renderSelectExistingField()}
            {!openSelectExistingField && renderButton()}
            <DividerWrapper>
              <Divider horizontal />
              <StyledDividerText variant="body3">
                Ou crie novos campos personalizados
              </StyledDividerText>
              <Divider horizontal />
            </DividerWrapper>
            <SecondaryButtonsWrapper>
              <StyledSecondaryButton
                size="large"
                variant="secondary"
                onClick={() => {
                  setModalConfigureFieldOpen(true);
                }}
              >
                <Icons name="IconPlus" size={24} fill="transparent" />
                Criar novo campo
              </StyledSecondaryButton>
            </SecondaryButtonsWrapper>
          </FormWrapper>
          {!!preConfiguredFields?.length && (
            <SelectedFieldsWrapper>
              <StyledFormTitle variant="headline8">Campos</StyledFormTitle>
              {preConfiguredFields?.map((field) => {
                return (
                  <FieldPreview
                    options={{
                      editCallback: () =>
                        setModalEditFieldOpen({
                          isOpen: true,
                          fieldId: field?._id,
                          name: field?.name,
                          component: field?.component,
                          sensitive: field?.sensitive,
                          options: field?.options,
                        } as any),
                      deleteCallback: () => {
                        setPreConfiguredFields(
                          preConfiguredFields?.filter(
                            (f) => f.name !== field?.name,
                          ),
                        );
                      },
                      copyCallback: () => {
                        setPreConfiguredFields([
                          ...preConfiguredFields,
                          {
                            ...field,
                            name: `${field?.name} (copy)`,
                            _id: Math.random().toString(),
                          },
                        ]);
                      },
                    }}
                    key={Math.random().toString()}
                    field={{
                      ...field,
                      name: field?.name || "",
                      type: field?.type as FieldTypeTypes,
                      disabled: true,
                      component: field?.component as FieldComponentTypes,
                      options: field?.options?.map((o) => ({
                        label: o,
                        value: o,
                      })),
                    }}
                  />
                );
              })}
            </SelectedFieldsWrapper>
          )}
        </ContentWrapper>
      </Content>
      <ModalConfigureField
        isOpen={modalConfigureFieldOpen}
        onClose={() => setModalConfigureFieldOpen(false)}
        loading={false}
        onSubmit={(field) => {
          setModalConfigureFieldOpen(false);
          setPreConfiguredFields([
            ...(preConfiguredFields || []),
            {
              options: field?.options || undefined,
              component: field?.component,
              name: field?.name,
              sensitive: field?.sensitive || false,
              mask: undefined,
            },
          ]);
        }}
      />
      <ModalConfigureField
        isOpen={modalEditFieldOpen.isOpen}
        onClose={() =>
          setModalEditFieldOpen({
            isOpen: false,
            name: "",
            component: "Input",
            options: [],
            sensitive: false,
            fieldId: "",
          } as any)
        }
        loading={false}
        fieldData={
          {
            name: modalEditFieldOpen.name,
            component: modalEditFieldOpen.component,
            options: modalEditFieldOpen.options,
            sensitive: modalEditFieldOpen.sensitive,
          } as any
        }
        onSubmit={(field) => {
          const newFields = cloneDeep(preConfiguredFields);
          const fieldIndex = newFields?.findIndex(
            (f) => f._id === modalEditFieldOpen.fieldId,
          );
          newFields[fieldIndex] = {
            ...newFields?.[fieldIndex],
            ...field,
          };

          setPreConfiguredFields(newFields);

          setModalEditFieldOpen({
            isOpen: false,
            name: "",
            component: "Input",
            options: [],
            sensitive: false,
            fieldId: "",
          } as any);
        }}
      />
    </>
  );
};
