import gql from "graphql-tag";

export const COMPANY_GROUPS_QUERY = gql`
  query CompanyGroupsQuery($companyId: ID) {
    getCompany(companyId: $companyId) {
      _id
      groups(companyId: $companyId) {
        id
        name
        employees(companyId: $companyId) {
          id
          name
          status
          documentNumber
          phoneNumber
          email
          groups {
            id
            name
          }
        }
      }
    }
  }
`;
