import { TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;

    align-items: flex-start;
    text-align: start;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 312px;

  @media screen and (min-width: 768px) {
    align-items: flex-start;
  }
`;

export const SubstextWrapper = styled.div`
  min-width: 312px;
  margin-right: 24px;
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: 8px;
`;

export const StyledSubtitle = styled(Typography)`
  margin-bottom: 16px;
  color: var(--color-primary);
`;

export const StyledDescription = styled(Typography)`
  margin-bottom: 40px;
  color: var(--color-neutral-50);
`;

export const FormWrapper = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-radius: 12px;
  padding: 40px;
  width: 100%;
  max-width: 768px;
`;

export const StyledFormTitle = styled(Typography)`
  margin-bottom: 32px;
`;

export const FieldTitleInput = styled(TextField)`
  width: 100%;
`;
