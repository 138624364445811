import { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { dispatchToast, useResultState, ResultState, dateFormat } from "@utils";
import { GET_ECONOMIC_GROUP_COMPANIES } from "src/api";
import { EconomicGroupCompanyCard } from "../types";
import { normalizeSearchText } from "@utils/normalizeSearchText";

export const useGetEconomicGroupCompanies = () => {
  const { setSuccess, setFailed, ...resultStateProps } = useResultState<
    EconomicGroupCompanyCard[]
  >({
    loading: true,
  });
  const [companies, setCompanies] = useState<EconomicGroupCompanyCard[]>([]);
  const [mainCompany, setMainCompany] = useState<EconomicGroupCompanyCard>();

  useQuery(GET_ECONOMIC_GROUP_COMPANIES, {
    onCompleted: (data) => {
      const economicGroupId = data?.getEconomicGroupCompanies?.economicGroupId;
      const mainCompanyData = data?.getEconomicGroupCompanies?.mainCompany;
      const companies: EconomicGroupCompanyCard[] =
        data?.getEconomicGroupCompanies?.economicGroupCompanies.map(
          (company: any): EconomicGroupCompanyCard => ({
            id: company.id,
            name: company.name,
            legalName: company.legalName,
            registrationNumber: company.registrationNumber,
            economicGroupId: economicGroupId,
            address: company.address,
            createdAt: dateFormat(company.createdAt),
            normalizedFields: [
              normalizeSearchText(company.name),
              normalizeSearchText(company.legalName),
              normalizeSearchText(company.registrationNumber),
              normalizeSearchText(dateFormat(company.createdAt)),
            ].join(" "),
          }),
        );
      const mainCompany: EconomicGroupCompanyCard = {
        id: mainCompanyData.id,
        name: mainCompanyData.name,
        legalName: mainCompanyData.legalName,
        registrationNumber: mainCompanyData.registrationNumber,
        economicGroupId: mainCompanyData.economicGroupId,
        address: mainCompanyData.address,
        createdAt: dateFormat(mainCompanyData.createdAt),
        normalizedFields: [
          normalizeSearchText(mainCompanyData.name),
          normalizeSearchText(mainCompanyData.legalName),
          normalizeSearchText(mainCompanyData.registrationNumber),
          normalizeSearchText(dateFormat(mainCompanyData.createdAt)),
        ].join(" "),
      };

      setSuccess(companies);
      setCompanies(companies);
      setMainCompany(mainCompany);
    },
    onError: (error) => {
      dispatchToast({
        type: "error",
        content:
          "Sentimos muito, ocorreu um erro ao buscar as empresas do grupo.",
      });
      setFailed(error);
    },
    fetchPolicy: "network-only",
  });

  const isLoading =
    resultStateProps.state === ResultState.LOADING ||
    resultStateProps.state === ResultState.IDLE;

  return {
    companies,
    mainCompany,
    isLoading,
  };
};
