import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.button`
  border: 1px solid var(--color-neutral-90);
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: start;

  &:hover {
    background-color: #f2f2f2;
    opacity: 0.8;
  }
`;

export const TextsWrapper = styled.div``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 24px;
`;

export const IconWrapper = styled.div`
  min-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-secondary-95);
  border-radius: 50%;
`;

export const StyledIcon = styled(Icons)`
  color: var(--color-primary);
`;
