import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const FirstColumnCell = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FirstColumnCellData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 10px;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
export const SubtitleText = styled(Typography)`
  color: var(--color-neutral-60);
`;
