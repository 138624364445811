import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { CancelButtonContainer, FooterContainer } from "./styled";

export const Footer = ({
  onClose,
  onSubmit,
  submitText,
  loading,
  disabled,
  finished,
}: {
  onClose: () => void;
  onSubmit: () => void;
  submitText: string;
  loading: boolean;
  disabled: boolean;
  finished: boolean;
}) => {
  return (
    <FooterContainer>
      <CancelButtonContainer>
        <LinkButton variant="secondary" onClick={onClose} disabled={loading}>
          Cancelar
        </LinkButton>
      </CancelButtonContainer>
      <Button
        variant="primary"
        size="medium"
        onClick={finished ? onClose : onSubmit}
        loading={loading}
        disabled={disabled}
      >
        {finished ? "Concluir" : submitText}
        {!finished && <Icons name="IconKey" size={24} />}
      </Button>
    </FooterContainer>
  );
};
