import gql from "graphql-tag";

export const UPDATE_SECTION = gql`
  mutation UpdateSection($sectionId: ID!, $data: UpdateSectionInput) {
    updateSection(sectionId: $sectionId, data: $data) {
      fields {
        _id
        name
        sensitive
        order
        mask
        type
        component
        options
        expires
      }
    }
  }
`;
