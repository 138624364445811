import styled from "styled-components";

export const DataGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: none;
`;

export const DataGridTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin-bottom: 24px;
  gap: 10px;
`;
