import { useNavigate } from "react-router-dom";
import { useFlag } from "@flash-tecnologia/feature-flags";
import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { usePermissions } from "@flash-tecnologia/hros-web-utility";

import { routes } from "@/routes";

import { HeaderContainer, HeaderTitle } from "./styled";

export const Header = () => {
  const navigate = useNavigate();
  const permissions = usePermissions();
  const isCompanyCreationEnabled = !useFlag({
    flagName: "FLASH_OS_FORCE_LEGACY_COMPANY_CREATION_UX",
  });

  const canCreateCompany = permissions?.isAdmin && isCompanyCreationEnabled;

  return (
    <HeaderContainer>
      <HeaderTitle>
        <Typography variant="headline6">Empresas do grupo</Typography>
        <Typography variant="body4">
          Visualize e gerencie as empresas que fazem parte do grupo.
        </Typography>
      </HeaderTitle>
      {canCreateCompany && (
        <Button
          onClick={() => navigate(routes.pageAddCompany)}
          size="large"
          variant="primary"
        >
          Adicionar empresa <Icons name="IconPlus" size={24} />
        </Button>
      )}
    </HeaderContainer>
  );
};
