import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import { Wrapper } from "./styled";
interface IDateColumn {
  createdAt: string;
}

export const DateColumn = ({ createdAt }: IDateColumn) => {
  return (
    <Wrapper>
      <Icons name="IconCalendarEvent" size={16} fill="transparent" />{" "}
      {createdAt}
    </Wrapper>
  );
};
