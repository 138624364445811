import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
  gap: 4px;
  margin-bottom: 40px;
`;

export const Title = styled(Typography)``;

export const Description = styled(Typography)`
  color: var(--color-neutral-50);
`;
