import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icons, Tab } from "@flash-tecnologia/hros-web-ui-v2";
import _ from "lodash";
import { GetCompanySectionsFields_getCompany_sectionsFields } from "src/api/queries/__generated__/GetCompanySectionsFields";
import { routes } from "src/routes";

import { ContentCustomSection } from "../ContentCustomSection";
import { ContentDefaultSection } from "../ContentDefaultSection";

import { SectionCard } from "./SectionCard";
import {
  Container,
  Content,
  ContentSkeleton,
  SectionSkeleton,
  StyledButton,
  TabsWrapper,
} from "./styled";

export type SetSelectedSectionType = Dispatch<
  SetStateAction<GetCompanySectionsFields_getCompany_sectionsFields>
>;

type TabsType = {
  loading: boolean;
  setSelectedCustomSection: SetSelectedSectionType;
  setSelectedDefaultSection: SetSelectedSectionType;
  customSections: GetCompanySectionsFields_getCompany_sectionsFields[];
  defaultSections: GetCompanySectionsFields_getCompany_sectionsFields[];
  selectedCustomSection: GetCompanySectionsFields_getCompany_sectionsFields;
  selectedDefaultSection: GetCompanySectionsFields_getCompany_sectionsFields;
};

export const Tabs = ({
  loading,
  setSelectedCustomSection,
  setSelectedDefaultSection,
  customSections,
  defaultSections,
  selectedCustomSection,
  selectedDefaultSection,
}: TabsType) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<"custom" | "default">("custom");

  const renderSections = useMemo(
    () =>
      (
        sections: GetCompanySectionsFields_getCompany_sectionsFields[],
        selectedSection: GetCompanySectionsFields_getCompany_sectionsFields,
        setSelectedSection: SetSelectedSectionType,
      ) => {
        return (
          <Content>
            <TabsWrapper>
              {activeTab === "custom" && (
                <StyledButton
                  size="medium"
                  variant="primary"
                  onClick={() => navigate(routes.pageCreateSection)}
                >
                  Criar nova seção
                  <Icons name="IconPlus" fill="transparent" />
                </StyledButton>
              )}
              {loading
                ? Array.from({ length: 8 }, (_, index) => (
                    <SectionSkeleton key={index} />
                  ))
                : sections?.map(({ _id, name }) => {
                    const isSelected = _id === selectedSection?._id;

                    return (
                      <SectionCard
                        key={_id}
                        id={_id}
                        title={name as any}
                        isDefault={selectedSection?.type === "default"}
                        isSelected={isSelected}
                        setSelectedSection={(_id) =>
                          setSelectedSection(
                            sections.find((tab) => tab._id === _id) as any,
                          )
                        }
                      />
                    );
                  })}
            </TabsWrapper>
            {loading ? (
              <ContentSkeleton />
            ) : (
              <>
                {activeTab === "custom" && (
                  <ContentCustomSection
                    name={selectedCustomSection?.name as any}
                    fields={_.cloneDeep(selectedCustomSection?.fields) as any}
                    selectedSectionId={selectedCustomSection?._id}
                    setSelectedCustomSection={setSelectedCustomSection}
                    customSections={customSections}
                  />
                )}
                {activeTab === "default" && (
                  <ContentDefaultSection
                    name={selectedDefaultSection?.name as any}
                    fields={selectedDefaultSection?.fields as any}
                  />
                )}
              </>
            )}
          </Content>
        );
      },
    [loading, activeTab, selectedCustomSection, selectedDefaultSection],
  );

  const tabsItens = useMemo(
    () => [
      {
        label: "Personalizadas",
        component: renderSections(
          customSections,
          selectedCustomSection,
          setSelectedCustomSection,
        ),
      },
      {
        label: "Padrão",
        component: renderSections(
          defaultSections,
          selectedDefaultSection,
          setSelectedDefaultSection,
        ),
      },
    ],
    [
      defaultSections,
      customSections,
      loading,
      activeTab,
      selectedCustomSection,
      selectedDefaultSection,
    ],
  );

  return (
    <Container>
      <Tab
        tabItens={tabsItens}
        onTabChanged={(index) => {
          setActiveTab(index === 0 ? "custom" : "default");
        }}
      />
    </Container>
  );
};
