import { useLocation } from "react-router-dom";
import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";

import { CompanyCard } from "@/components";
import { useGetCompany } from "@/features/companies/hooks";

import { Header } from "./components/Header";
import { Skeleton } from "./components/Skeleton";
import { Tabs } from "./components/Tabs";

export const PageInitial = () => {
  const { state } = useLocation();
  const { tab: redirectedTab } = (state as any) || {};
  const { loading, company } = useGetCompany();
  return (
    <PageContainer>
      <Header />
      {loading && <Skeleton />}
      {!loading && <CompanyCard {...(company || {})} />}
      <Tabs redirectedTab={redirectedTab} />
    </PageContainer>
  );
};
