import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { DELETE_ROLE } from "@/api/mutations/roles";
import { DeleteRoleVariables } from "@/api/mutations/roles/__generated__/DeleteRole";
import { useCompany } from "@/lib";
import { routes } from "@/routes";
import { dispatchToast, ErrorBoundary } from "@/utils";
import { GET_ROLES } from "@/api/queries/roles";

export const useDeleteRole = (isCancel?: boolean) => {
  const { companyId } = useCompany();
  const navigate = useNavigate();

  const [deleteRoleMutation, { loading }] = useMutation<
    void,
    DeleteRoleVariables
  >(DELETE_ROLE, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: `Cargo ${isCancel ? "cancelado" : "excluído"} com sucesso!`,
      });
      navigate(routes.roles);
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content: `Ocorreu um erro ao ${
          isCancel ? "cancelar" : "excluir"
        } o cargo. Por favor, tente novamente mais tarde.`,
      });
    },
    refetchQueries: [GET_ROLES],
  });

  const deleteRole = ({
    roleId,
  }: Omit<DeleteRoleVariables, "companyId">): void => {
    if (!isCancel) {
      dispatchToast({
        type: "warning",
        content: "Excluindo cargo...",
      });
    }

    deleteRoleMutation({
      variables: {
        companyId,
        roleId,
      },
    });
  };

  return {
    deleteRole,
    loading,
  };
};
