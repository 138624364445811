import { FieldComponentInterface } from "@Components/FieldComponent";
import { RegistrationDataType } from ".";

interface ModelInterface extends FieldComponentInterface {
  key: keyof RegistrationDataType;
  locked?: boolean;
}

export const model: (
  registrationData: RegistrationDataType,
) => ModelInterface[] = (registrationData: RegistrationDataType) => {
  return [
    {
      key: "registrationNumber",
      name: "CNPJ",
      value: registrationData?.registrationNumber,
      locked: true,
      pattern: false,
      component: "number",
      required: true,
      type: "text",
      inputProps: {
        maxLength: 14,
      },
      inputMode: "numeric",
    },
    {
      key: "stateRegistration",
      name: "Inscrição estadual",
      value: registrationData?.stateRegistration,
      locked: false,
      pattern: false,
      component: "number",
      required: true,
      type: "text",
      inputMode: "numeric",
    },
    {
      key: "cityRegistration",
      name: "Inscrição municipal",
      value: registrationData?.cityRegistration,
      locked: false,
      pattern: false,
      component: "number",
      required: true,
      type: "text",
      inputMode: "numeric",
    },
    {
      key: "patRegistrationNumber",
      name: "Número de inscrição no PAT",
      value: registrationData?.patRegistrationNumber,
      locked: false,
      pattern: false,
      component: "number",
      required: true,
      type: "text",
      inputMode: "numeric",
    },
    {
      key: "legalName",
      name: "Razão social",
      value: registrationData?.legalName,
      locked: false,
      pattern: false,
      component: "shortText",
      required: true,
      type: "text",
      inputMode: "numeric",
    },
    {
      key: "name",
      name: "Nome fantasia",
      value: registrationData?.name,
      locked: false,
      pattern: false,
      component: "shortText",
      required: true,
      type: "text",
      inputMode: "numeric",
    },
    {
      key: "segment",
      name: "Ramo de atividade",
      value: registrationData?.segment,
      locked: false,
      pattern: false,
      component: "select",
      required: true,
      options: segmentList,
      type: "array",
    },
    {
      key: "phone",
      name: "Telefone",
      value: registrationData?.phone,
      locked: false,
      pattern: false,
      component: "number",
      required: true,
      type: "text",
      inputMode: "numeric",
      inputProps: {
        maxLength: 11,
      },
    },
  ];
};

const segmentList = [
  { label: "Alimentos e Bebidas", value: "Alimentos e Bebidas" },
  { label: "Arte e Antiguidades", value: "Arte e Antiguidades" },
  { label: "Artigos Religiosos", value: "Artigos Religiosos" },
  { label: "Assinaturas e Revistas", value: "Assinaturas e Revistas" },
  { label: "Automóveis e Veículos", value: "Automóveis e Veículos" },
  { label: "Bebês e Cia", value: "Bebês e Cia" },
  { label: "Blu-Ray", value: "Blu-Ray" },
  {
    label: "Brindes / Materiais Promocionais",
    value: "Brindes / Materiais Promocionais",
  },
  { label: "Brinquedos e Games", value: "Brinquedos e Games" },
  { label: "Casa e Decoração", value: "Casa e Decoração" },
  { label: "CDs", value: "CDs" },
  { label: "Colecionáveis", value: "Colecionáveis" },
  { label: "Compras Coletivas", value: "Compras Coletivas" },
  { label: "Construção e Ferramentas", value: "Construção e Ferramentas" },
  { label: "Cosméticos e Perfumaria", value: "Cosméticos e Perfumaria" },
  { label: "Cursos e Educação", value: "Cursos e Educação" },
  { label: "Discos de Vinil", value: "Discos de Vinil" },
  { label: "DVDs", value: "DVDs" },
  { label: "Eletrodomésticos", value: "Eletrodomésticos" },
  { label: "Eletrônicos", value: "Eletrônicos" },
  { label: "Emissoras de Rádio", value: "Emissoras de Rádio" },
  { label: "Emissoras de Televisão", value: "Emissoras de Televisão" },
  { label: "Empregos", value: "Empregos" },
  { label: "Empresas de Telemarketing", value: "Empresas de Telemarketing" },
  { label: "Esporte e Lazer", value: "Esporte e Lazer" },
  { label: "Fitas K7 Gravadas", value: "Fitas K7 Gravadas" },
  { label: "Flores, Cestas e Presentes", value: "Flores, Cestas e Presentes" },
  { label: "Fotografia", value: "Fotografia" },
  { label: "HD-DVD", value: "HD-DVD" },
  {
    label: "Igrejas / Templos / Instituições Religiosas",
    value: "Igrejas / Templos / Instituições Religiosas",
  },
  {
    label: "Indústria, Comércio e Negócios",
    value: "Indústria, Comércio e Negócios",
  },
  { label: "Infláveis Promocionais", value: "Infláveis Promocionais" },
  { label: "Informática", value: "Informática" },
  { label: "Ingressos", value: "Ingressos" },
  { label: "Instrumentos Musicais", value: "Instrumentos Musicais" },
  { label: "Joalheria", value: "Joalheria" },
  { label: "Lazer", value: "Lazer" },
  { label: "LD", value: "LD" },
  { label: "Livros", value: "Livros" },
  { label: "MD", value: "MD" },
  { label: "Moda e Acessórios", value: "Moda e Acessórios" },
  { label: "Motéis", value: "Motéis" },
  { label: "Música Digital", value: "Música Digital" },
  { label: "Natal", value: "Natal" },
  { label: "Negócios e Oportunidades", value: "Negócios e Oportunidades" },
  { label: "Outros Serviços", value: "Outros Serviços" },
  {
    label: "Outros Serviços de Avaliação",
    value: "Outros Serviços de Avaliação",
  },
  { label: "Papelaria e Escritório", value: "Papelaria e Escritório" },
  { label: "Páscoa", value: "Páscoa" },
  { label: "Pet Shop", value: "Pet Shop" },
  { label: "Saúde", value: "Saúde" },
  { label: "Serviço Advocaticios", value: "Serviço Advocaticios" },
  {
    label: "Serviço de Distribuição de Jornais / Revistas",
    value: "Serviço de Distribuição de Jornais / Revistas",
  },
  { label: "Serviços Administrativos", value: "Serviços Administrativos" },
  { label: "Serviços Artísticos", value: "Serviços Artísticos" },
  {
    label: "Serviços de Abatedouros / Matadouros",
    value: "Serviços de Abatedouros / Matadouros",
  },
  { label: "Serviços de Aeroportos", value: "Serviços de Aeroportos" },
  { label: "Serviços de Agências", value: "Serviços de Agências" },
  {
    label: "Serviços de Aluguel / Locação",
    value: "Serviços de Aluguel / Locação",
  },
  { label: "Serviços de Armazenagem", value: "Serviços de Armazenagem" },
  { label: "Serviços de Assessorias", value: "Serviços de Assessorias" },
  {
    label: "Serviços de Assistência Técnica / Instalações ",
    value: "Serviços de Assistência Técnica / Instalações ",
  },
  { label: "Serviços de Associações", value: "Serviços de Associações" },
  {
    label: "Serviços de Bancos de Sangue",
    value: "Serviços de Bancos de Sangue",
  },
  { label: "Serviços de Bibliotecas", value: "Serviços de Bibliotecas" },
  { label: "Serviços de Cartórios", value: "Serviços de Cartórios" },
  {
    label: "Serviços de Casas Lotéricas",
    value: "Serviços de Casas Lotéricas",
  },
  { label: "Serviços de Confecções", value: "Serviços de Confecções" },
  { label: "Serviços de Consórcios", value: "Serviços de Consórcios" },
  { label: "Serviços de Consultorias", value: "Serviços de Consultorias" },
  { label: "Serviços de Cooperativas", value: "Serviços de Cooperativas" },
  { label: "Serviços de Despachante", value: "Serviços de Despachante" },
  { label: "Serviços de Engenharia", value: "Serviços de Engenharia" },
  {
    label: "Serviços de Estacionamentos",
    value: "Serviços de Estacionamentos",
  },
  { label: "Serviços de Estaleiros", value: "Serviços de Estaleiros" },
  {
    label: "Serviços de Exportação / Importação",
    value: "Serviços de Exportação / Importação",
  },
  { label: "Serviços de Geólogos", value: "Serviços de Geólogos" },
  { label: "Serviços de joalheiros", value: "Serviços de joalheiros" },
  { label: "Serviços de Leiloeiros", value: "Serviços de Leiloeiros" },
  { label: "Serviços de limpeza", value: "Serviços de limpeza" },
  {
    label: "Serviços de Loja de Conveniência",
    value: "Serviços de Loja de Conveniência",
  },
  { label: "Serviços de Mão de Obra", value: "Serviços de Mão de Obra" },
  { label: "Serviços de Órgão Públicos", value: "Serviços de Órgão Públicos" },
  { label: "Serviços de Pesquisas", value: "Serviços de Pesquisas" },
  { label: "Serviços de Portos", value: "Serviços de Portos" },
  {
    label: "Serviços de Saúde / Bem Estar",
    value: "Serviços de Saúde / Bem Estar",
  },
  { label: "Serviços de Seguradoras", value: "Serviços de Seguradoras" },
  { label: "Serviços de Segurança", value: "Serviços de Segurança" },
  { label: "Serviços de Sinalização", value: "Serviços de Sinalização" },
  {
    label: "Serviços de Sindicatos / Federações",
    value: "Serviços de Sindicatos / Federações",
  },
  { label: "Serviços de Traduções", value: "Serviços de Traduções" },
  { label: "Serviços de Transporte", value: "Serviços de Transporte" },
  {
    label: "Serviços de Utilidade Pública",
    value: "Serviços de Utilidade Pública",
  },
  {
    label: "Serviços em Agricultura / Pecuária / Piscicultura",
    value: "Serviços em Agricultura / Pecuária / Piscicultura",
  },
  { label: "Serviços em Alimentação", value: "Serviços em Alimentação" },
  { label: "Serviços em Arte", value: "Serviços em Arte" },
  {
    label: "Serviços em Cine / Foto / Som",
    value: "Serviços em Cine / Foto / Som",
  },
  { label: "Serviços em Comunicação", value: "Serviços em Comunicação" },
  { label: "Serviços em Construção", value: "Serviços em Construção" },
  {
    label: "Serviços em Ecologia / Meio Ambiente",
    value: "Serviços em Ecologia / Meio Ambiente",
  },
  {
    label: "Serviços em Eletroeletrônica / Metal Mecânica",
    value: "Serviços em Eletroeletrônica / Metal Mecânica",
  },
  {
    label: "Serviços em Festas / Eventos",
    value: "Serviços em Festas / Eventos",
  },
  { label: "Serviços em Informática", value: "Serviços em Informática" },
  { label: "Serviços em Internet", value: "Serviços em Internet" },
  {
    label: "Serviços em Jóias / Relógios / Óticas",
    value: "Serviços em Jóias / Relógios / Óticas",
  },
  { label: "Serviços em Telefonia", value: "Serviços em Telefonia" },
  { label: "Serviços em Veículos", value: "Serviços em Veículos" },
  {
    label: "Serviços Esotéricos / Místicos",
    value: "Serviços Esotéricos / Místicos",
  },
  { label: "Serviços Financeiros", value: "Serviços Financeiros" },
  { label: "Serviços Funerários", value: "Serviços Funerários" },
  { label: "Serviços Gerais", value: "Serviços Gerais" },
  {
    label: "Serviços Gráficos / Editoriais",
    value: "Serviços Gráficos / Editoriais",
  },
  { label: "Serviços para Animais", value: "Serviços para Animais" },
  { label: "Serviços para Deficientes", value: "Serviços para Deficientes" },
  { label: "Serviços para Escritórios", value: "Serviços para Escritórios" },
  { label: "Serviços para Roupas", value: "Serviços para Roupas" },
  {
    label: "Serviços Socias / Assistenciais",
    value: "Serviços Socias / Assistenciais",
  },
  { label: "Sex Shop", value: "Sex Shop" },
  { label: "Shopping Centers", value: "Shopping Centers" },
  { label: "Tabacaria", value: "Tabacaria" },
  { label: "Tarifas Bancárias", value: "Tarifas Bancárias" },
  { label: "Tarifas Telefônicas", value: "Tarifas Telefônicas" },
  { label: "Telefonia", value: "Telefonia" },
  { label: "Turismo", value: "Turismo" },
];
