import { useMemo } from "react";
import { IconTypes } from "@flash-tecnologia/hros-web-ui-v2";
import {
  FieldComponent,
  FieldComponentInterface,
} from "@Components/FieldComponent";

import {
  Component,
  ComponentInfoWrapper,
  Container,
  FieldComponentWrapper,
  FieldWrapper,
  OptionsWrapper,
  StyledComponentIcon,
  StyledComponentTag,
  StyledOptionIcon,
} from "./styled";

export const FieldPreview = ({
  field,
  options,
}: {
  field: FieldComponentInterface;
  options?: {
    editCallback?: () => void;
    copyCallback?: () => void;
    deleteCallback?: () => void;
  };
}) => {
  const Content = (text: string, icon: IconTypes) =>
    useMemo(() => {
      return (
        <Component>
          <ComponentInfoWrapper>
            <StyledComponentIcon fill="transparent" size={24} name={icon} />
            <StyledComponentTag variant="gray" disabled={true}>
              {text}
            </StyledComponentTag>
          </ComponentInfoWrapper>
          <FieldWrapper>
            <FieldComponentWrapper>
              <FieldComponent
                {...field}
                onChange={() => {
                  return;
                }}
              />
            </FieldComponentWrapper>
            {!!options && (
              <OptionsWrapper>
                <StyledOptionIcon
                  name={"IconPencil"}
                  fill="transparent"
                  size={24}
                  onClick={options?.editCallback}
                />
                <StyledOptionIcon
                  name={"IconCopy"}
                  fill="transparent"
                  size={24}
                  onClick={options.copyCallback}
                />
                <StyledOptionIcon
                  name={"IconTrash"}
                  fill="transparent"
                  size={24}
                  color="#C96C01"
                  onClick={options.deleteCallback}
                />
              </OptionsWrapper>
            )}
          </FieldWrapper>
        </Component>
      );
    }, [field]);

  const renderContent = () => {
    switch (field?.component) {
      case "shortText":
        return Content("Campo de texto curto", "IconCursorText");
      case "longText":
        return Content("Campo de texto longo", "IconCursorText");
      case "number":
        return Content("Campo de número", "IconSquare");
      case "link":
        return Content("Campo de link", "IconLink");
      case "datePicker":
        return Content("Campo de data", "IconCalendar");
      case "timePicker":
        return Content("Campo de horário", "IconClock");
      case "checkbox":
        return Content("Campo de checkbox", "IconSquareCheck");
      case "select":
        return Content("Campo de seleção única em lista", "IconList");
      case "multiSelect":
        return Content("Campo de seleção múltipla em lista", "IconListCheck");
      case "attachment":
        return Content("Anexo", "IconPaperclip");
      default:
        return <></>;
    }
  };

  return <Container>{renderContent()}</Container>;
};
