import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useUpdateFlagsContext } from "@flash-tecnologia/feature-flags";
import {
  DepartmentEmployeesPage,
  DepartmentFormPage,
  DepartmentsManagementPage,
  DepartmentsPage,
} from "@departments/pages";
import { DepartmentsProvider } from "@departments/store";
import { EconomicGroupPage } from "@economicGroup/pages";
import {
  RoleEmployeesPage,
  RoleFormPage,
  RolesListPage,
} from "@features/roles/pages";
import {
  PageAddCompany,
  PageAddCompanyContract,
  PageCompany,
  PageCreateGroup,
  PageCreateSection,
  PageEditGroup,
  PageGroups,
  PageInitial,
  PageManageGroupMembers,
  PageSectionsFields,
} from "@Pages/index";

import { useSession } from "@/common/user";
import { CompanyProvider } from "@/features/economicGroup/hooks/use-company";
import { PageApiKeys } from "@/pages/PageApiKeys";

import { RoutesGuard } from "./routesGuard";

export const routes = {
  pageAddCompany: "/settings/add-company",
  pageInitial: "/settings",
  pageCompany: "/settings/company",
  pageSectionsFields: "/settings/sections-fields",
  pageCreateGroup: "/settings/create-group",
  pageCreateSection: "/settings/create-section",
  pageGroups: (focusedGroup?: string) =>
    focusedGroup ? `/settings/groups/${focusedGroup}` : "/settings/groups",
  pageEditGroup: (groupId: string) => `/settings/edit-group/${groupId}`,
  pageManageGroupMembers: (groupId: string) =>
    `/settings/manage-group-members/${groupId}`,
  economicGroup: "/settings/economic-group",
  departments: "/settings/departments",
  departmentCreate: "/settings/departments/create",
  departmentEmployeesEdit: (departmentId: string) =>
    `/settings/departments/${departmentId}/employees`,
  departmentEdit: (departmentId: string) =>
    `/settings/departments/${departmentId}`,

  roles: "/settings/roles",
  roleEmployees: (roleId: string) => `/settings/roles/${roleId}/employees`,
  roleForm: (roleId?: string) => `/settings/roles/${roleId || "create"}`,
  pageApiKeys: "/settings/keys",
} as const;

export type RouteTypes = typeof routes[keyof typeof routes];

const AppRouter = () => {
  const { userId, companyId, economicGroupId } = useSession();
  const updateContext = useUpdateFlagsContext();

  useEffect(() => {
    updateContext(userId, {
      economicGroupId,
      companyId,
      employeeId: userId,
    });
  }, [userId, companyId]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={<RoutesGuard permission="core_manage_company_settings" />}
        >
          <Route path={routes.pageInitial} element={<PageInitial />} />
          <Route path={routes.pageCompany} element={<PageCompany />} />
          <Route path={routes.pageCreateGroup} element={<PageCreateGroup />} />

          <Route
            path={routes.pageCreateSection}
            element={<PageCreateSection />}
          />
          <Route
            path={routes.pageSectionsFields}
            element={<PageSectionsFields />}
          />
          <Route
            path={routes.pageGroups(":focusedGroup")}
            element={<PageGroups />}
          />
          <Route path={routes.pageGroups()} element={<PageGroups />} />
          <Route
            path={routes.pageEditGroup(":groupId")}
            element={<PageEditGroup />}
          />
          <Route
            path={routes.pageManageGroupMembers(":groupId")}
            element={<PageManageGroupMembers />}
          />
          <Route path={routes.economicGroup} element={<EconomicGroupPage />} />
          <Route path={routes.departments} element={<DepartmentsProvider />}>
            <Route index element={<DepartmentsPage />} />
            <Route path="create" element={<DepartmentsManagementPage />} />
            <Route path=":departmentId" element={<DepartmentFormPage />} />
            <Route
              path=":departmentId/employees"
              element={<DepartmentEmployeesPage />}
            />
          </Route>
          <Route path={routes.pageApiKeys} element={<PageApiKeys />} />
        </Route>

        <Route path={routes.roles}>
          <Route index element={<RolesListPage />} />
          <Route path="create" element={<RoleFormPage />} />
          <Route path=":roleId" element={<RoleFormPage />} />
          <Route path=":roleId/employees" element={<RoleEmployeesPage />} />
        </Route>
        <Route path={routes.pageAddCompany}>
          <Route
            index
            element={
              <CompanyProvider>
                <PageAddCompany />
              </CompanyProvider>
            }
          />
          <Route
            path="contract"
            element={
              <CompanyProvider>
                <PageAddCompanyContract />
              </CompanyProvider>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
