import { GET_EMPLOYEES_V2 } from "@/api/queries/employees";
import {
  GetEmployeesV2,
  GetEmployeesV2Variables,
} from "@/api/queries/employees/__generated__/GetEmployeesV2";
import { DataTypeEmployee } from "@/features/roles/types";
import { useCompany } from "@/lib";
import { dispatchToast, ErrorBoundary } from "@/utils";
import { useLazyQuery } from "@apollo/client";
import { GetEmployeesV2Input } from "@grapql-schema";
import { useState } from "react";

export const useLazyGetEmployeesV2 = () => {
  const { companyId } = useCompany();

  const [employees, setEmployees] = useState<DataTypeEmployee[]>([]);

  const [getEmployeesV2Query, { loading, data }] = useLazyQuery<
    GetEmployeesV2,
    GetEmployeesV2Variables
  >(GET_EMPLOYEES_V2, {
    onCompleted: (data) => {
      const employeesDTO = data.getEmployeesV2?.records?.map<DataTypeEmployee>(
        (record) => {
          return {
            _id: record?.id,
            name: record?.name,
            email: record?.email ?? "",
            hidden: false,
            checked: false,
          } as any;
        },
      );
      setEmployees(employeesDTO as any);
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content: "Ocorreu um erro ao buscar os colaboradores.",
      });
    },
    fetchPolicy: "cache-first",
  });

  const getEmployeesV2 = (
    input: GetEmployeesV2Input & {
      search?: string;
    },
  ) => {
    return getEmployeesV2Query({
      variables: {
        input: {
          companyId,
          sortBy: "name",
          order: "asc",
          ...(input.search && { query: input.search || undefined }),
          ...input,
        },
      },
    });
  };

  return {
    getEmployeesV2,
    loading,
    employees,
    totalCount: data?.getEmployeesV2?.recordsMetadata?.totalCount || 0,
    data: data?.getEmployeesV2,
  };
};
