import { memo, useCallback } from "react";
import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const GroupListContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const StyledTag = styled(Tag)`
  white-space: nowrap;
`;

type TagItem = {
  id: string;
  name: string;
};

type TagGroupProps = {
  tags?: TagItem[];
  onClick?: (item: TagItem) => void;
};

const TagGroupComp = ({ tags, onClick }: TagGroupProps) => {
  const handleClick = useCallback(
    (item: TagItem) => () => {
      onClick?.(item);
    },
    [],
  );

  return (
    <GroupListContainer>
      {tags?.map((item, index) => {
        if (index < 3) {
          return (
            <StyledTag
              key={item.id}
              variant="gray"
              disabled={true}
              onClick={handleClick(item)}
            >
              {item.name}
            </StyledTag>
          );
        }
        if (index === 3) {
          return (
            <StyledTag key={item.id} variant="gray" disabled={true}>
              {`+${tags.length - 2}`}
            </StyledTag>
          );
        }
        return null;
      })}
    </GroupListContainer>
  );
};

export const TagGroup = memo(TagGroupComp);
