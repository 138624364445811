import { useEffect, useMemo, useState } from "react";
import { EconomicGroupCompanyCard } from "@features/economicGroup/types";
import { normalizeSearchText } from "@utils/normalizeSearchText";

import { TableSearch } from "./components/Search";
import { TableGrid } from "./components/TableGrid";
import { TableContainer } from "./styled";

interface ITableProps {
  data: EconomicGroupCompanyCard[];
  loading: boolean;
}

export const Table = ({ data, loading }: ITableProps) => {
  const [searchState, setSearchState] = useState(null);

  const searchResult = useMemo(() => {
    if (searchState) {
      const inputText = normalizeSearchText(searchState);
      const filteredCompanies = data.filter((company) =>
        company.normalizedFields.includes(inputText),
      );
      return filteredCompanies.length ? filteredCompanies : data;
    }
    return data;
  }, [data, searchState]);

  return (
    <TableContainer>
      <TableSearch onChange={setSearchState} />
      <TableGrid data={searchResult} loading={loading} />
    </TableContainer>
  );
};
