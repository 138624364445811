import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { useFormik } from "formik";
import * as yup from "yup";

import { Box, FormTemplate, Typography } from "@/components";
import { Footer, Header, HeaderBreadcrumbs } from "@/features/roles/components";
import {
  useCreateRole,
  useLazyGetRoleById,
  useUpdateRole,
} from "@/features/roles/hooks";
import { RoleFormStep } from "@/features/roles/types";
import { routes } from "@/routes";
import { ErrorBoundary, usePageTracking } from "@/utils";

import { ROLES_FORM_PAGE } from "../../types/events";

import { CBOButton, FormCard, TextFieldInput } from "./styles";

const MAX_DESCRIPTION_LENGTH = 600;

const validationSchema = yup.object({
  name: yup.string().required("Campo obrigatório"),
  description: yup.string(),
  cbo: yup.string(),
  externalId: yup.string(),
});

type RoleFormValues = {
  name: string;
  description?: string;
  cbo?: string;
  externalId?: string;
};

export const RoleFormPage = () => {
  const navigate = useNavigate();
  const { roleId } = useParams();
  const isEdit = !!roleId;
  usePageTracking(ROLES_FORM_PAGE);
  const breadcrumbs: HeaderBreadcrumbs[] = [
    {
      label: "Gerenciar empresa",
      route: routes.pageInitial,
      options: { state: { tab: 1 } },
    },
    {
      label: "...",
      route: routes.roles,
    },
    { label: `${isEdit ? "Editar" : "Criar"} cargo` },
  ];
  const pageTitle = isEdit ? "Editar Cargo" : "Criar Cargo";
  const [activeStep, setActiveStep] = useState(RoleFormStep.FIRST);

  const { role, getRoleById } = useLazyGetRoleById();
  const { createRole, loading: createRoleLoading } = useCreateRole();
  const { updateRole, loading: updateRoleLoading } = useUpdateRole(
    roleId || "",
  );

  useEffect(() => {
    if (roleId) {
      getRoleById(roleId);
    }
  }, [roleId]);

  useEffect(() => {
    if (role) {
      formik.setValues({
        name: role.name,
        description: role.description || "",
        cbo: role.cbo || "",
        externalId: role.externalId || "",
      });
    }
  }, [role]);

  const formik = useFormik<RoleFormValues>({
    initialValues: { name: "", description: "", cbo: "", externalId: "" },
    validationSchema: validationSchema,
    onSubmit: async ({ cbo, description, name, externalId }) => {
      const roleData = {
        name,
        description: description || undefined,
        cbo: cbo || undefined,
        externalId: externalId || undefined,
      };
      if (isEdit) {
        updateRole({
          ...roleData,
          roleId,
        });
      } else {
        createRole(roleData);
      }
    },
  });

  const handleCancel = () => {
    navigate(routes.roles);
  };

  const handleSubmit = async () => {
    try {
      await formik.submitForm();
    } catch (error: any) {
      ErrorBoundary.captureException(error);
    }
  };

  return (
    <FormTemplate
      title={pageTitle}
      subtitle="Informações básicas"
      subcontent="Adicione nome, descrição e outras informações básicas importantes do cargo."
      header={
        <Header
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          breadcrumbs={breadcrumbs}
        />
      }
      footer={
        <Footer
          submitButtonDisabled={!formik.values?.name}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          loading={createRoleLoading || updateRoleLoading}
          submitButtonContent={
            <>
              Continuar <Icons name="IconArrowRight" />
            </>
          }
        />
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <FormCard>
          <Typography color="neutral.30" variant="headline8">
            Nome e descrição
          </Typography>

          <Typography mt="4px" color="neutral.50" variant="body4">
            O nome do cargo será o principal texto exibido nas listas e nos
            destaques. Na descrição, você pode resumir alguns detalhes
            relacionados ao cargo.
          </Typography>

          <Typography mt={32} mb={16} color="neutral.30" variant="body3">
            * campo obrigatório
          </Typography>

          <TextFieldInput
            label="Nome do cargo *"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
          />

          <Box mt={24}>
            <TextFieldInput
              label="Descrição do cargo"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
              multiline
              rows={4}
              error={formik.touched.description && !!formik.errors.description}
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
          </Box>

          <Box display="flex" justifyContent="space-between" mt="2px">
            <Typography color="neutral.50" variant="caption">
              {`Máximo ${MAX_DESCRIPTION_LENGTH} caracteres`}
            </Typography>
            <Typography color="neutral.50" variant="caption">
              {`${
                formik.values?.description?.length ?? 0
              }/${MAX_DESCRIPTION_LENGTH}`}
            </Typography>
          </Box>
        </FormCard>

        <Box mt={40}>
          <FormCard>
            <Box display="flex" alignItems="center">
              <Typography color="neutral.30" variant="headline8">
                Código CBO
              </Typography>
              <Box ml="4px">
                <Icons
                  name="IconInfoCircle"
                  color="var(--color-neutral-dark3)"
                  size={16}
                  fill="transparent"
                />
              </Box>
            </Box>
            <Typography mt="4px" color="neutral.50" variant="body4">
              Escolha a CBO referente ao cargo descrito.
            </Typography>

            <Box mt={32} mb={24}>
              <TextFieldInput
                label="Código CBO"
                name="cbo"
                imaskProps={{
                  mask: "0000-00",
                }}
                value={formik.values.cbo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.cbo && !!formik.errors.cbo}
                helperText={formik.touched.cbo && formik.errors.cbo}
              />
            </Box>
            <a
              target="_blank"
              href="http://www.mtecbo.gov.br/cbosite/pages/pesquisas/BuscaPorTitulo.jsf"
            >
              <CBOButton variant="secondary">
                Buscar código CBO por título
                <span style={{ marginLeft: "4px" }}>
                  <Icons name="IconExternalLink" fill="transparent" size={16} />
                </span>
              </CBOButton>
            </a>
          </FormCard>
        </Box>

        <Box mt={40}>
          <FormCard>
            <Box display="flex" alignItems="center">
              <Typography color="neutral.30" variant="headline8">
                Código do cargo
              </Typography>
            </Box>
            <Typography mt="4px" color="neutral.50" variant="body4">
              Defina um código para integração com outras plataformas ou gestão
              interna dos seus cargos.
            </Typography>

            <Box mt={32}>
              <TextFieldInput
                label="Código do cargo"
                name="externalId"
                value={formik.values.externalId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.externalId && !!formik.errors.externalId}
                helperText={
                  formik.touched.externalId && formik.errors.externalId
                }
              />
            </Box>
          </FormCard>
        </Box>
      </form>
    </FormTemplate>
  );
};
