import styled from "styled-components";

import { TextField, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 520px;

  padding-left: 40px;
  padding-right: 24px;
  margin-bottom: 40px;

  @media screen and (max-width: 560px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;

  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
`;

export const StyledInput = styled(TextField)`
  width: 100%;
`;

export const InputContainer = styled.div`
  padding-bottom: 16px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 320px;
  padding-left: 24px;
  padding-bottom: 64px;

  @media screen and (max-width: 680px) {
    max-width: 230px;
  }
  @media screen and (max-width: 560px) {
    max-width: 479px;
    padding-right: 24px;
  }
  @media screen and (max-width: 420px) {
    max-width: 479px;
    padding-right: 44px;
  }
`;

export const TitleText = styled(Typography)`
  && {
    font-weight: 700;
  }
`;

export const ContentText = styled(Typography)`
  && {
    font-weight: 400;
    color: #83727d;
  }
`;
