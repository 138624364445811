import { ErrorBoundary } from "@/utils";
import {
  Axios,
  getFromLS,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import useSWR from "swr";

interface IExpensesData {
  companyId: string;
  contracts: string[];
  features: string[];
  plan: string;
  isLoading: boolean;
}

export function useLicenseFeatures() {
  const { selectedCompany } = useSelectedCompany();
  const expensesPlanFeaturesLS: string[] = getFromLS(
    `expensesPlanFeatures-${selectedCompany.id}`,
  );
  const { data: response } = useSWR<IExpensesData>(
    selectedCompany.id && !expensesPlanFeaturesLS?.length
      ? `expenses-company-data-${selectedCompany.id}`
      : null,
    () => getExpensesData(selectedCompany.id),
    {
      errorRetryCount: 3,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const expenseFeatures: string[] =
    response?.features || expensesPlanFeaturesLS;

  async function getExpensesData(companyId: string) {
    try {
      const { data } = await Axios({
        service: "expenseManagement",
        method: "get",
        url: `/api/v1/users/me/contracts`,
        axiosOptions: {
          timeout: 30000, // 30 seconds
          headers: {
            "Company-Id": companyId,
          },
        },
      });

      return { ...data, companyId };
    } catch (error: any) {
      ErrorBoundary.captureException(error);
      throw error;
    }
  }

  function checkLicenseFeature(feature: string): boolean {
    return expenseFeatures?.some((_feature) => _feature === feature);
  }

  return {
    expenseFeatures,
    checkLicenseFeature,
  };
}
