import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral.light.pure};
  padding: 16px 24px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
`;

export const FirstWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  gap: 24px;
`;

export const SecondWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`;

export const CancelButton = styled(LinkButton)`
  font-weight: 700;
`;

export const BackButton = styled(Button)``;
export const ContinueButton = styled(Button)``;
export const CreateDepartmentButton = styled(Button)``;
