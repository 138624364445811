export enum FieldComponentEnum {
  shortText = "Texto curto",
  longText = "Texto longo",
  number = "Número",
  attachment = "Anexo",
  link = "Link",
  datePicker = "Data",
  timePicker = "Horário",
  select = "Seleção única em lista",
  multiSelect = "Seleção múltipla em lista",
  checkbox = "Checkbox",
}

export const FieldComponentNames = Object.values(FieldComponentEnum);

export const fieldComponentKeyByValue = (value: any) =>
  Object.keys(FieldComponentEnum)[
    Object.values(FieldComponentEnum).indexOf(value)
  ];

export type FieldComponentTypes = keyof typeof FieldComponentEnum;

export type FieldTypeTypes =
  | "text"
  | "number"
  | "date"
  | "boolean"
  | "file"
  | "array";
