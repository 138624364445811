import {
  Button,
  Icons,
  Skeleton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid var(--color-neutral-80);
  border-radius: 12px;
  width: 100%;
  max-height: fit-content;
  height: fit-content;
`;

export const Header = styled.div`
  border-bottom: 1px solid var(--color-neutral-80);
  width: 100%;
  padding: 40px;
`;

export const Content = styled.div`
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled(Typography)`
  color: var(--color-neutral-30);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: var(--color-neutral-50);
`;

export const StyledOptionIcon = styled(Icons)<{ color?: string }>`
  cursor: pointer;

  && {
    color: ${({ color }) => color}
`;

export const ContentHeaderWrapper = styled.div``;

export const StyledSubtitle = styled(Typography)`
  color: var(--color-neutral-40);
  margin-bottom: 16px;
`;

export const ContentHeaderButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const StyledPrimaryButton = styled(Button)`
  width: 100%;
`;

export const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StyledDividerText = styled(Typography)`
  color: var(--color-neutral-50);
  min-width: fit-content;
`;

export const ExistingFieldSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
  width: 100%;
`;

export const FieldComponentSkeleton = styled(Skeleton)`
  height: 50px;
  border-radius: 12px;
`;

export const ExistingFieldsActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CancelButton = styled(Button)``;

export const ConfirmButton = styled(Button)``;
