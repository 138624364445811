import { useMutation } from "@apollo/client";
import { UpdateRoleInput } from "@grapql-schema";

import { UPDATE_ROLE } from "@/api/mutations/roles";
import { UpdateRoleVariables } from "@/api/mutations/roles/__generated__/UpdateRole";
import { useCompany } from "@/lib";
import { dispatchToast, ErrorBoundary } from "@/utils";

import { roleErrors } from "../types";
import { useNavigate } from "react-router-dom";
import { routes } from "@/routes";

export const useUpdateRole = (roleId: string) => {
  const { companyId } = useCompany();
  const navigate = useNavigate();

  const [updateRoleMutation, { loading }] = useMutation<
    void,
    UpdateRoleVariables
  >(UPDATE_ROLE, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: "Cargo atualizado com sucesso!",
      });
      navigate(routes.roleEmployees(roleId));
    },
    onError: (error) => {
      const graphqlErrors: any = error.graphQLErrors?.[0];
      const errorCode = graphqlErrors?.errorCode as string;
      if (errorCode === roleErrors.roleExists) {
        dispatchToast({
          type: "error",
          content: "Erro ao atualizar cargo",
          description: "Cargo com mesmo nome já existe.",
        });
      } else {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content:
            "Ocorreu um erro ao atualizar o cargo. Por favor, tente novamente mais tarde.",
        });
      }
    },
    refetchQueries: ["GetRoles"],
  });

  const updateRole = (input: Omit<UpdateRoleInput, "companyId">) => {
    dispatchToast({
      type: "warning",
      content: "Atualizando cargo...",
    });

    return updateRoleMutation({
      variables: {
        input: {
          companyId,
          ...input,
        },
      },
    });
  };

  return {
    updateRole,
    loading,
  };
};
