export const DEPARTMENT_CREATE_PAGE = "departments_create_screen";
export const DEPARTMENT_CREATE_CANCEL = "departments_create_cancel_clicked";
export const DEPARTMENT_CREATE_CONTINUE = "departments_create_continue_clicked";
export const DEPARTMENT_CREATE_SKIP = "departments_create_skip_clicked";
export const DEPARTMENT_CREATE_BACK = "departments_create_back_clicked";

export const DEPARTMENT_CREATE_MEMBERS_PAGE =
  "departments_create_members_screen";
export const DEPARTMENT_CREATE_TRANSFER_CLICKED =
  "departments_create_transfer_clicked";
export const DEPARTMENT_CREATE_FILTER_CLICKED =
  "departments_create_filter_clicked";
export const DEPARTMENT_CREATE_SKIP_MODAL_CONTINUE =
  "departments_create_skip_modal_continue_clicked";
export const DEPARTMENT_CREATE_SKIP_MODAL_BACK =
  "departments_create_skip_modal_back_clicked";
export const DEPARTMENT_CREATE_SKIP_MODAL_EXIT =
  "departments_create_skip_modal_exit_clicked";

export const DEPARTMENTS_PAGE = "departments_screen";
export const DEPARTMENTS_HEADER_CREATE = "departments_header_create_clicked";
export const DEPARTMENTS_PAGE_CREATE = "departments_page_create_clicked";
export const DEPARTMENTS_TAB_SELECTED = "departments_department_selected";
