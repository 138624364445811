import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const TitleText = styled(Typography)`
  && {
    font-weight: 700;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
  gap: 6px;
  padding: 34px 24px 64px 24px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: row;
`;

export const Column = styled.div`
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContractContainer = styled.div`
  width: 100%;
`;

export const ContentText = styled(Typography)`
  && {
    font-weight: 400;
    color: #83727d;
  }
`;

export const BoldTypography = styled(Typography)`
  font-weight: 700;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ebe6e9;
  padding: 16px 24px;
  position: relative;
  bottom: 0px;
`;

export const Spacer = styled.div<{ width?: number; height?: number }>`
  display: flex;
  height: ${(props) => props?.height || 1}px;
  width: ${(props) => props?.width || 1}px;
`;

export const HeaderRow = styled.div`
  padding: 40px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
