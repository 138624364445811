import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Icons, PageContainer } from "@flash-tecnologia/hros-web-ui-v2";
import {
  BasicInformation,
  Footer,
  Header,
  HeaderBreadcrumbs,
} from "@departments/components";
import {
  DEPARTMENT_CREATE_BACK,
  DEPARTMENT_CREATE_CANCEL,
  DEPARTMENT_CREATE_CONTINUE,
} from "@departments/events";
import { useGetDepartmentById } from "@departments/hooks";
import { useDepartmentsContext } from "@departments/store";
import { DepartmentFormStep } from "@departments/types";
import { setEventTracking } from "@utils/eventTracking";
import { routes } from "src/routes";

import { Body, Container, ContentWrapper } from "./styled";

export const DepartmentFormPage = () => {
  const navigate = useNavigate();
  const { departmentId } = useParams();
  const isEdit = !!departmentId;

  const breadcrumbs: HeaderBreadcrumbs[] = [
    {
      label: "Gerenciar empresa",
      route: routes.pageInitial,
      options: { state: { tab: 1 } },
    },
    {
      label: "...",
      route: routes.departments,
    },
    { label: `${isEdit ? "Editar" : "Criar"} departamento` },
  ];
  const pageTitle = isEdit ? "Editar Departamento" : "Criar Departamento";
  const [activeStep, setActiveStep] = useState(DepartmentFormStep.FIRST);

  const { department, setDepartment } = useGetDepartmentById(
    departmentId as string,
  );
  const { dispatch } = useDepartmentsContext();

  const handleCancel = () => {
    navigate(routes.departments);
    setEventTracking(DEPARTMENT_CREATE_CANCEL, {
      step: activeStep.toString(),
    });
  };

  const handleBack = () => {
    navigate(routes.departments);
    setEventTracking(DEPARTMENT_CREATE_BACK, {
      step: activeStep.toString(),
    });
  };

  const handleSubmit = () => {
    setActiveStep(DepartmentFormStep.SECOND);
    setEventTracking(DEPARTMENT_CREATE_CONTINUE, {
      step: activeStep.toString(),
      button_text: "Continuar",
    });

    dispatch({
      type: "set_department",
      payload: {
        id: departmentId,
        name: department?.name,
        description: department?.description,
        externalId: department?.externalId,
      },
    });
    navigate(routes.departmentEmployeesEdit(departmentId as string));
  };

  return (
    <Body>
      <Header
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        breadcrumbs={breadcrumbs}
      />
      <ContentWrapper>
        <PageContainer>
          <Container>
            <BasicInformation
              title={pageTitle}
              department={department}
              setDepartment={setDepartment as any}
            />
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        submitButtonDisabled={!department?.name}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onBack={handleBack}
        submitButtonContent={
          <>
            Continuar <Icons name="IconArrowRight" />
          </>
        }
      />
    </Body>
  );
};
