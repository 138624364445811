import { getFromLS } from "@flash-tecnologia/hros-web-utility";

export function useCheckUnleashFlags() {
  const unleashData = getFromLS("permissions_flags_store");
  const unleashFlags = unleashData && JSON.parse(unleashData)?.flags;

  function checkUnleashFlag(flagName: string) {
    return unleashFlags?.flags?.some((flag: any) => flag.name === flagName);
  }

  return checkUnleashFlag;
}
