import { useMutation } from "@apollo/client";
import { dispatchToast } from "@utils";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { useNavigate } from "react-router-dom";
import { DELETE_DEPARTMENT } from "src/api";
import { routes } from "src/routes";

type HookResult = {
  deleteDepartment: (params: DeleteDepartmentParams) => void;
  loading: boolean;
};

type DeleteDepartmentParams = { companyId: string; departmentId: string };

export const useDeleteDepartment = (): HookResult => {
  const navigate = useNavigate();

  const [deleteDepartmentMutation, { loading }] = useMutation<
    void,
    DeleteDepartmentParams
  >(DELETE_DEPARTMENT, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: "Departamento excluído com sucesso!",
      });
      navigate(routes.departments);
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content:
          "Ocorreu um erro ao excluir o departamento. Por favor, tente novamente mais tarde.",
      });
    },
    refetchQueries: ["GetCompanyDepartments"],
  });

  const deleteDepartment = ({
    companyId,
    departmentId,
  }: DeleteDepartmentParams): void => {
    dispatchToast({
      type: "warning",
      content: "Excluindo departamento...",
    });

    deleteDepartmentMutation({
      variables: {
        companyId,
        departmentId,
      },
    });
  };

  return {
    deleteDepartment,
    loading,
  };
};
