import { Navigate, Outlet } from "react-router-dom";
import { useCheckPlatformPermission } from "src/shared/hooks/permissions/use-check-platform-permission";

export const RoutesGuard = ({
  children,
  permission,
}: {
  children?: JSX.Element;
  permission: string;
}) => {
  const { employeeIsAdmin, checkPlatformPermission } =
    useCheckPlatformPermission();
  const isRouteAllowed = checkPlatformPermission(permission);

  if (!isRouteAllowed && !employeeIsAdmin) {
    return <Navigate to={"/home"} />;
  }
  return children ? children : <Outlet />;
};
