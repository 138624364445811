import {
  Button,
  DataGrid,
  Icons,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { DataGridContainer, DataGridTitleContainer } from "./styled";

export const KeysTable = ({
  columns,
  data,
  setIsCreateKeyModalOpen,
  loading,
  isFeatEnabled,
}: any) => {
  const pageSizeOptions = [
    {
      label: "10",
      value: 10,
    },
    {
      label: "20",
      value: 20,
    },
    {
      label: "50",
      value: 50,
    },
  ];
  return (
    <DataGridContainer>
      <DataGridTitleContainer>
        <Typography variant="headline8">Chaves de acesso</Typography>
        <Tag variant="gray">{data.length} itens</Tag>
      </DataGridTitleContainer>
      <DataGrid
        columns={columns}
        data={data}
        hasPagination={true}
        pageSizeOptions={pageSizeOptions}
        loading={loading}
        emptyState={
          !loading
            ? {
                message: "Você ainda não possui nenhuma chave de acesso.",
                isFiltered: true,
                children: (
                  <Button
                    size="large"
                    type="button"
                    variant="primary"
                    disabled={!isFeatEnabled}
                    onClick={() => {
                      setIsCreateKeyModalOpen(true);
                    }}
                  >
                    Criar nova chave
                    <Icons name="IconPlus" />
                  </Button>
                ),
              }
            : undefined
        }
      />
    </DataGridContainer>
  );
};
