import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import {
  Body,
  Container,
  ContentWrapper,
  FieldTitleInput,
  FormWrapper,
  StyledDescription,
  StyledFormDescription,
  StyledFormTitle,
  StyledSubtitle,
  StyledTitle,
  TextWrapper,
} from "./styled";

export const PageEditGroup = () => {
  const [searchParams] = useSearchParams();
  const groupName = searchParams.get("groupName");

  const [name, setName] = useState(groupName);

  useEffect(() => {
    setName(groupName);
  }, [groupName]);

  return (
    <Body>
      <Header />
      <ContentWrapper>
        <PageContainer>
          <Container>
            <TextWrapper>
              <StyledTitle variant="headline6">Editar grupo</StyledTitle>
              <StyledSubtitle variant="headline8">
                Informações básicas
              </StyledSubtitle>
              <StyledDescription variant="body3">
                Edite o nome do grupo <strong>{groupName}</strong>
              </StyledDescription>
            </TextWrapper>
            <FormWrapper>
              <StyledFormTitle variant="headline8">Nome</StyledFormTitle>
              <StyledFormDescription variant="body4">
                Edite o nome do grupo. Este é o principal texto exibido nas
                listas e nos destaques.
              </StyledFormDescription>
              <FieldTitleInput
                label="Nome do grupo"
                onChange={(e) => setName(e.target.value)}
                placeholder={groupName as any}
                value={name}
              />
            </FormWrapper>
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer
        disableForward={!!!name?.length}
        name={name as any}
        groupName={groupName as any}
      />
    </Body>
  );
};
