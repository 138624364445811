import { useMutation } from "@apollo/client";
import { UpdateRoleEmployeesInput } from "@grapql-schema";

import { useCompany } from "@/lib";
import { dispatchToast, ErrorBoundary } from "@/utils";

import { roleErrors } from "../types";
import { useNavigate } from "react-router-dom";
import { routes } from "@/routes";
import { UPDATE_ROLE_EMPLOYEES } from "@/api/mutations/roles/updateRoleEmployees";
import { UpdateRoleEmployeesVariables } from "@/api/mutations/roles/__generated__/UpdateRoleEmployees";

export const useUpdateRoleEmployees = () => {
  const { companyId } = useCompany();
  const navigate = useNavigate();

  const [updateRoleEmployeesMutation, { loading }] = useMutation<
    void,
    UpdateRoleEmployeesVariables
  >(UPDATE_ROLE_EMPLOYEES, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: "Cargo atualizado com sucesso!",
      });
      navigate(routes.roles);
    },
    onError: (error) => {
      const graphqlErrors: any = error.graphQLErrors?.[0];
      const errorCode = graphqlErrors?.errorCode as string;
      if (errorCode === roleErrors.roleExists) {
        dispatchToast({
          type: "error",
          content: "Erro ao atualizar cargo",
          description: "Cargo com mesmo nome já existe.",
        });
      } else {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content:
            "Ocorreu um erro ao atualizar o cargo. Por favor, tente novamente mais tarde.",
        });
      }
    },
    refetchQueries: ["GetRoles"],
  });

  const updateRoleEmployees = (
    input: Omit<UpdateRoleEmployeesInput, "companyId">,
  ) => {
    dispatchToast({
      type: "warning",
      content: "Atualizando cargo...",
    });

    return updateRoleEmployeesMutation({
      variables: {
        input: {
          companyId,
          ...input,
        },
      },
    });
  };

  return {
    updateRoleEmployees,
    loading,
  };
};
