import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { useMutation } from "@apollo/client";
import dispatchToast from "@Utils/dispatchToast";
import { PatchGroup } from "src/api/mutations/__generated__/PatchGroup";
import { PATCH_GROUP } from "src/api/mutations/patchGroup";
import { routes } from "src/routes/index";

import { useCompany } from "@/lib";

import {
  BackButton,
  CancelButton,
  Container,
  ContinueButton,
  Wrapper,
} from "./styled";

type FooterProps = { disableForward: boolean; name: string; groupName: string };

export const Footer = ({ disableForward, name, groupName }: FooterProps) => {
  const { groupId } = useParams();
  const [searchParams] = useSearchParams();
  const { companyId } = useCompany();
  const navigate = useNavigate();

  const [patchGroup, { loading }] = useMutation<PatchGroup>(PATCH_GROUP, {
    onCompleted: (res) => {
      dispatchToast({
        type: "success",
        content: "Grupo editado com sucesso!",
      });
      searchParams.set("groupName", name);
      navigate({
        pathname: routes.pageManageGroupMembers(groupId as any),
        search: searchParams.toString(),
      });
    },
    onError: (error) => {
      const graphqlErrors: any = error.graphQLErrors?.[0];
      const errorCode = graphqlErrors?.errorCode as string;

      if (errorCode === "GROUP_ALREADY_EXISTS_ERROR") {
        dispatchToast({
          type: "error",
          content: "Erro ao editar grupo",
          description: "Grupo com mesmo nome já existe!",
        });
      } else {
        dispatchToast({
          type: "error",
          content:
            "Ocorreu um erro ao editar o grupo. Por favor, tente novamente mais tarde.",
        });
      }
    },
  });

  return (
    <Container>
      <CancelButton
        variant="secondary"
        onClick={() => navigate(routes.pageGroups())}
      >
        Cancelar
      </CancelButton>
      <Wrapper>
        <BackButton
          size="large"
          variant="secondary"
          onClick={() => navigate(routes.pageGroups(groupId))}
        >
          <Icons name="IconArrowLeft" />
          Voltar
        </BackButton>
        <ContinueButton
          size="large"
          variant="primary"
          disabled={disableForward}
          loading={loading}
          onClick={async () => {
            if (groupName === name) {
              searchParams.set("groupName", groupName);
              navigate({
                pathname: routes.pageManageGroupMembers(groupId as any),
                search: searchParams.toString(),
              });
              return;
            }

            await patchGroup({
              variables: {
                groupId: groupId,
                companyId: companyId,
                data: {
                  name: name,
                },
              },
            });
          }}
        >
          Continuar <Icons name="IconArrowRight" />
        </ContinueButton>
      </Wrapper>
    </Container>
  );
};
