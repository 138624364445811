import { StatusEnum } from "@/common/StatusEnum";

import { StyledTag } from "./styled";

export const StatusTag = ({ status }: { status: StatusEnum | string }) => {
  switch (status) {
    case StatusEnum.ACTIVE:
    case "active":
      return (
        <StyledTag variant="success" disabled={true} hasLeftDotIcon={true}>
          Ativo
        </StyledTag>
      );

    case StatusEnum.INACTIVE:
    case "inactive":
      return (
        <StyledTag variant="error" disabled={true} hasLeftDotIcon={true}>
          Inativo
        </StyledTag>
      );

    case StatusEnum.INVITATION_SCHEDULED:
    case "inviteScheduled":
      return (
        <StyledTag variant="secondary" disabled={true} hasLeftDotIcon={true}>
          Convite agendado
        </StyledTag>
      );

    case StatusEnum.INVITATION_SENT:
    case "inviteSent":
      return (
        <StyledTag variant="secondary" disabled={true} hasLeftDotIcon={true}>
          Convite enviado
        </StyledTag>
      );

    case StatusEnum.INVITATION_EXPIRED:
    case "inviteExpired":
      return (
        <StyledTag variant="secondary" disabled={true} hasLeftDotIcon={true}>
          Convite expirado
        </StyledTag>
      );

    case StatusEnum.INVITATION_PENDING:
    case "invitePending":
      return (
        <StyledTag variant="info" disabled={true} hasLeftDotIcon={true}>
          Aguardando convite
        </StyledTag>
      );

    default:
      return <></>;
  }
};
