import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledSkeleton = styled(Skeleton)`
  margin-bottom: 0px;
  border-radius: 20px;
  max-height: "828px";
  display: "flex";
  flex-grow: 1;
`;
