import gql from "graphql-tag";

export const CREATE_SECTION = gql`
  mutation CreateSection($name: String, $fields: [CompanyFieldInput]) {
    createSection(name: $name, fields: $fields) {
      _id
      name
      description
      type
      order
      fields {
        _id
        name
        sensitive
        order
        mask
        type
        component
        options
        expires
      }
    }
  }
`;
