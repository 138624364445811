import { SecondaryTabCard } from "../SecondaryTabCard";

import { Container, StyledDescription, TabsWrapper } from "./styled";

export type TabTemplateProps = {
  description: string;
  secondaryTabs: SecondaryTabCard[];
};

export const TabTemplate = ({
  description,
  secondaryTabs,
}: TabTemplateProps) => {
  return (
    <Container>
      <StyledDescription variant="body3">{description}</StyledDescription>
      <TabsWrapper>
        {secondaryTabs.map((secondaryTab) => (
          <SecondaryTabCard key={secondaryTab.title} {...secondaryTab} />
        ))}
      </TabsWrapper>
    </Container>
  );
};
