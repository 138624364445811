import styled from "styled-components";

import { Breadcrumbs } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-neutral-90);
  padding: 16px 24px;
  background: white;
  align-items: center;
  gap: 24px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)``;
