import { useNavigate } from "react-router-dom";
import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes";

import { Container } from "./styles";

type TableFiltersProps = {
  disabled: boolean;
  roleId: string;
};

export const TableFilters = ({ disabled, roleId }: TableFiltersProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Button
        variant="secondary"
        size="small"
        disabled={disabled}
        onClick={() => {
          navigate({
            pathname: routes.roleEmployees(roleId),
            search: "?from=/roles",
          });
        }}
      >
        Atribuir integrantes
      </Button>
    </Container>
  );
};
