import { useMemo, useState } from "react";
import { IconButton, Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";
import { ModalConfirmAction } from "@Components/ModalConfirmAction";
import { useRemoveEmployeesDepartment } from "@features/departments/hooks";

import { useSession } from "@/common/user";

import { Option } from "./styled";
import { TOption } from "./types";

type Props = {
  employeeId: string;
  active: boolean;
  departmentId: string;
};

export const EmployeeOptions = ({
  employeeId,
  active,
  departmentId,
}: Props) => {
  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false);

  const { removeEmployeesDepartment } = useRemoveEmployeesDepartment();
  const { companyId } = useSession();

  const options: TOption[] = useMemo(() => {
    let opt: TOption[] = [
      {
        key: "profile",
        children: (
          <Option onClick={() => setConfirmRemoveModal(true)}>
            <Icons name="IconUserOff" fill="transparent" />
            Desvincular do departamento
          </Option>
        ),
      },
    ];

    return opt;
  }, [employeeId, active]);

  const handleSubmit = () => {
    setConfirmRemoveModal(false);
    removeEmployeesDepartment({
      departmentId,
      companyId: companyId,
      employeeIds: [employeeId],
    });
  };

  return (
    <>
      <Menu
        type={"select"}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: 0, horizontal: -220 }}
      >
        <IconButton variant="line" size="small" icon="IconDotsVertical" />
      </Menu>

      <ModalConfirmAction
        isOpen={confirmRemoveModal}
        loading={false}
        title="Tem certeza que deseja desvincular essa pessoa?"
        description="A pessoa selecionada ficará sem atribuição de departamento e todas as configurações realizadas serão perdidas."
        confirmWord="desvincular"
        submitText={
          <>
            <span>Confirmar</span>
            <Icons name="IconTrash" size={30} fill="transparent" />
          </>
        }
        onClose={() => setConfirmRemoveModal(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};
