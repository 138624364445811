import {
  createContext,
  type PropsWithChildren,
  useContext,
  useReducer,
} from "react";
import { Outlet } from "react-router-dom";

import { Department } from "../types";

type Action =
  | { type: "set_department"; payload: Partial<Department> }
  | { type: "clear_department" };
type Dispatch = (action: Action) => void;
type State = { department: Department | null };

const INITIAL_STATE: State = {
  department: null,
};

const DepartmentsContext = createContext<{
  state: State;
  dispatch: Dispatch;
} | null>(null);

const departmentsReducer = (
  state: State,
  action: Action,
): typeof INITIAL_STATE => {
  switch (action.type) {
    case "clear_department": {
      return {
        ...state,
        department: null,
      };
    }
    case "set_department": {
      return {
        ...state,
        department: {
          ...state.department,
          ...action.payload,
        } as Department,
      };
    }
  }
};

export const DepartmentsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(departmentsReducer, INITIAL_STATE);

  const value = { state, dispatch };

  return (
    <DepartmentsContext.Provider value={value}>
      {children ?? <Outlet />}
    </DepartmentsContext.Provider>
  );
};

export const useDepartmentsContext = () => {
  const context = useContext(DepartmentsContext);
  if (!context) {
    throw new Error(
      "useDepartmentContext must be used within a DepartmentsContext",
    );
  }
  return context;
};
