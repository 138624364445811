import { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { dispatchToast, useResultState, ResultState } from "@utils";
import { CREATE_API_KEY } from "@/api/mutations/apikeys";
import {
  CreateApiKey,
  CreateApiKey_createApiKey,
  CreateApiKeyVariables,
} from "@/api/mutations/apikeys/__generated__/CreateApiKey";
import { CreateApiKeyInput } from "@grapql-schema";

const ErrorMessages = {
  default:
    "Não foi possível gerar uma nova chave de acesso programático. Por favor, tente novamente.",
  conflict:
    "Já existe uma chave com o mesmo nome, por favor utilize outro nome.",
};

export const useCreateApiKey = () => {
  const [apiKey, setApiKey] = useState<CreateApiKey_createApiKey>();
  const [createApiKeyMutation, { loading }] = useMutation<
    CreateApiKey,
    CreateApiKeyVariables
  >(CREATE_API_KEY, {
    onCompleted: (data) => {
      setApiKey(data?.createApiKey);
      dispatchToast({
        type: "success",
        title: "Nova chave de acesso gerada!",
        content: undefined,
        description:
          "Copie sua chave de acesso e guarde-a em um local seguro. A chave será exibida apenas uma vez e não poderá ser visualizada novamente posteriormente.",
      });
    },
    onError: (e) => {
      const graphqlErrors: any = e.graphQLErrors?.[0];
      const errorCode = graphqlErrors?.errorCode as string;
      const msg =
        errorCode === "CONFLICT"
          ? ErrorMessages.conflict
          : ErrorMessages.default;
      dispatchToast({
        type: "error",
        title: "Erro ao gerar novas chaves!",
        content: undefined,
        description: msg,
      });
    },
    fetchPolicy: "network-only",
  });

  const createApiKey = ({ name }: CreateApiKey_createApiKey): void => {
    createApiKeyMutation({
      variables: {
        input: {
          name,
        },
      },
    });
  };

  return {
    apiKey,
    createApiKey,
    loading,
  };
};
