import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Title = styled(Typography)<{ selected: boolean }>`
  color: ${({ selected }) =>
    selected ? "var(--color-secondary-dark4)" : "var(--color-neutral-dark3)"};
  font-weight: 600 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Card = styled.button<{ selected: boolean }>`
  padding: 8px 16px;
  width: 312px;
  border-radius: 8px;
  gap: 16px;

  text-align: start;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  transition: all 0.1s;

  border: 1px solid
    ${({ selected }) =>
      selected ? "var(--color-secondary-light5)" : "var(--color-neutral-90)"};

  background: ${({ selected }) =>
    selected ? "var(--color-secondary-light2)" : "transparent"};

  &:hover {
    background: var(--color-secondary-light2);
    border: 1px solid var(--color-secondary-light2);

    ${Title} {
      color: var(--color-secondary-dark5);
    }
  }

  &:focus-visible {
    outline: 4px solid var(--color-secondary-light2);
  }

  &:active {
    background: var(--color-secondary-light5);

    ${Title} {
      color: var(--color-neutral-pureLight);
    }
  }
`;
