import { useState } from "react";
import { useLazyQuery } from "@apollo/client";

import { useCompany } from "@/lib";
import { dispatchToast, ErrorBoundary } from "@/utils";
import { DataTypeEmployee } from "../types";
import { GetRoleMembersPaginated } from "@/api/queries/roles/__generated__/GetRoleMembersPaginated";
import { GET_ROLE_MEMBERS_PAGINATED } from "@/api/queries/roles/getRoleAndMembersPaginated";
import { GetEmployeesV2Input } from "@grapql-schema";

export const useGetRoleMembersPaginated = () => {
  const { companyId } = useCompany();

  const [members, setMembers] = useState<DataTypeEmployee[]>([]);

  const [query, { loading, data }] = useLazyQuery<GetRoleMembersPaginated>(
    GET_ROLE_MEMBERS_PAGINATED,
    {
      onCompleted: ({ employees }) => {
        const members = employees?.records?.map<DataTypeEmployee>(
          (member) =>
            ({
              _id: member?.id,
              name: member?.name,
              email: member?.email ?? "",
              documentNumber: member?.documentNumber,
              phoneNumber: member?.phoneNumber,
              groups: member?.groups,
              hidden: false,
              checked: false,
            } as any),
        );
        setMembers(members as any);
      },
      onError: (error) => {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content:
            "Sentimos muito, ocorreu um erro ao buscar os membros do cargo.",
        });
      },
      fetchPolicy: "network-only",
    },
  );

  const getRoleMembers = ({
    search,
    roleId,
    ...input
  }: Omit<GetEmployeesV2Input, "companyId"> & {
    roleId: GetEmployeesV2Input["roleId"];
    search?: string;
  }) => {
    query({
      variables: {
        input: {
          companyId,
          roleId,
          sortBy: "name",
          order: "asc",
          ...(search && { query: search || undefined }),
          ...input,
        },
      },
    });
  };

  return {
    members,
    getRoleMembers,
    loading,
    totalCount: data?.employees?.recordsMetadata?.totalCount || 0,
    data: data?.employees,
  };
};
