import styled from "styled-components";

const ComponentDivider = styled.div<DividerInterface>`
  background: var(--color-neutral-light2);

  ${({ horizontal }) => horizontal && "width: 100%; height: 1px;"}
  ${({ vertical }) => vertical && "width: 1px; height: 100%;"}

  ${({ appearAt }) =>
    appearAt &&
    `
      display: none; 
      @media screen and (min-width: ${appearAt}px)  { 
        display: block; 
      }
    `}


  ${({ removeAt }) =>
    removeAt &&
    `
    @media screen and (min-width: ${removeAt}px) {
        display: none;
      }
    `}
`;

interface DividerInterface {
  horizontal?: boolean;
  vertical?: boolean;
  removeAt?: number;
  appearAt?: number;
}

export const Divider = (props: DividerInterface) => {
  return <ComponentDivider {...props} />;
};
