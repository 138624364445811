type FetchEmployeesRequest = {
  companyIds: string[];
  filter: FetchEmployeesFilter;
};

type FilterSort = {
  field: string;
  type: string;
};

type FetchEmployeesFilter = {
  index: number;
  pageSize: number;
  search: string;
  sort: FilterSort;
  hasFirstLogin?: boolean;
  active: boolean;
  fields: string[];
};

export class FetchEmployeesRequestBuilder {
  private companyIds: string[] = [];
  private filter: FetchEmployeesFilter = {
    index: 0,
    pageSize: 20,
    search: "",
    sort: {
      field: "name",
      type: "asc",
    },
    hasFirstLogin: undefined,
    active: true,
    fields: [],
  };

  withCompanyIds(companyIds: string[]): FetchEmployeesRequestBuilder {
    this.companyIds = companyIds;
    return this;
  }
  withIndex(index: number): FetchEmployeesRequestBuilder {
    this.filter.index = index;
    return this;
  }

  withPageSize(pageSize: number): FetchEmployeesRequestBuilder {
    this.filter.pageSize = pageSize;
    return this;
  }

  withSearch(search: string): FetchEmployeesRequestBuilder {
    this.filter.search = search;
    return this;
  }

  withSortField(field: string): FetchEmployeesRequestBuilder {
    this.filter.sort.field = field;
    return this;
  }

  withSortType(type: string): FetchEmployeesRequestBuilder {
    this.filter.sort.type = type;
    return this;
  }

  withHasFirstLogin(hasFirstLogin: boolean): FetchEmployeesRequestBuilder {
    this.filter.hasFirstLogin = hasFirstLogin;
    return this;
  }

  withActive(active: boolean): FetchEmployeesRequestBuilder {
    this.filter.active = active;
    return this;
  }

  withFields(fields: string[]): FetchEmployeesRequestBuilder {
    this.filter.fields = fields;
    return this;
  }

  build(): FetchEmployeesRequest {
    return {
      companyIds: this.companyIds,
      filter: this.filter,
    };
  }
}
