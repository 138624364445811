import gql from "graphql-tag";

export const ADD_COMPANY_TO_GROUP = gql`
  mutation AddCompanyIntoEconomicGroup(
    $input: AddCompanyIntoEconomicGroupInput!
  ) {
    addCompanyIntoEconomicGroup(input: $input) {
      companies {
        _id
        name
        legalName
        registrationNumber
        address {
          street
          number
          complement
          district
          city
          state
          zipCode
        }
      }
    }
  }
`;
