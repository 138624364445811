import { useEffect, useMemo } from "react";
import { debounce } from "lodash-es";
import styled from "styled-components";

import { DataTypeEmployee } from "../../types";

import { useRolesTableContext } from "./table-context";
import { TableFilters } from "./table-filters";
import { TableGrid } from "./table-grid";
import { TableHeader } from "./table-header";
import { TablePagination } from "./table-pagination";
import { TableSearch } from "./table-search";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
  width: 100%;
  height: 100%;
`;

type TableProps<T = DataTypeEmployee> = {
  data: T[];
  loading: boolean;
  roleId: string;
  pagination?: boolean;
};

export const Table = ({
  data = [],
  loading,
  roleId,
  pagination,
}: TableProps) => {
  const {
    state: { paginationState },
    dispatch,
  } = useRolesTableContext();

  const debouncedOnChange = useMemo(() => {
    return debounce((value: string) => {
      dispatch({ type: "set_search_value", payload: value });
    }, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  }, [debouncedOnChange]);

  useEffect(() => {
    dispatch({ type: "clear_search_value" });
  }, [roleId]);

  const handlePageChange = ({
    pageNumber,
    pageSize,
  }: {
    pageNumber?: number;
    pageSize?: number;
  }) => {
    dispatch({
      type: "set_pagination_state",
      payload: {
        pageNumber,
        pageSize,
      },
    });
  };

  return (
    <Container>
      <TableHeader totalCount={paginationState.totalCount} loading={loading} />
      <TableSearch onChange={debouncedOnChange} disabled={false} />
      <TableFilters roleId={roleId} disabled={!roleId} />
      <TableGrid
        data={data as any}
        pageSize={paginationState.pageSize}
        roleId={roleId}
        loading={loading}
      />
      {!!pagination && (
        <TablePagination
          totalPages={paginationState.totalPages}
          pageNumber={paginationState.pageNumber}
          pageSize={paginationState.pageSize}
          onPageNumberChange={(pageNumber) => handlePageChange({ pageNumber })}
          onPageSizeChange={(pageSize) => handlePageChange({ pageSize })}
        />
      )}
    </Container>
  );
};
