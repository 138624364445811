import {
  IconButton,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  HeaderContainer,
  HeaderTitleContainer,
  TitleContainer,
  TypoErrorColor,
} from "./styled";

export const Header = ({ onClose }: { onClose: () => void }) => {
  return (
    <HeaderContainer>
      <HeaderTitleContainer>
        <TitleContainer>
          <ShapeIcon
            name="IconAlertCircle"
            variant="error"
            size={48}
            // fill="error"
            stroke="error"
          />
          <TypoErrorColor variant="headline8">Atenção!</TypoErrorColor>
        </TitleContainer>
        <IconButton
          icon="IconX"
          variant="line"
          size="small"
          onClick={() => onClose()}
        />
      </HeaderTitleContainer>
    </HeaderContainer>
  );
};
