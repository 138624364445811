import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type CompanyFields = {
  name: string;
  legalName: string;
  registrationNumber: string;
};

type CompanyContextValue = {
  company: {
    name: string;
    legalName: string;
    registrationNumber: string;
    address: AddressType;
  };
  setAddress: (updatedFields: Partial<AddressType>) => void;
  setFields: (updatedFields: Partial<CompanyFields>) => void;
};

const CompanyContext = createContext<CompanyContextValue | null>(null);

const emptyCompany = {
  name: "",
  legalName: "",
  registrationNumber: "",
  address: {
    zipCode: "",
    street: "",
    number: "",
    complement: "",
    district: "",
    state: "",
    city: "",
  },
};

export function useCompany() {
  const value = useContext(CompanyContext);

  if (value === null) {
    throw new Error("useCompany cannot be called without a CompanyProvider");
  }

  return value;
}

type CompanyProviderProps = {
  children: React.ReactNode;
};

export function CompanyProvider({ children }: CompanyProviderProps) {
  const [company, setCompany] = useState(emptyCompany);

  const setFields = useCallback((fields: any) => {
    setCompany((prev) => ({ ...prev, ...fields }));
  }, []);

  const setAddress = useCallback((fields: any) => {
    setCompany((prev) => ({
      ...prev,
      address: {
        ...prev?.address,
        ...fields,
      },
    }));
  }, []);

  const value = useMemo(
    () => ({ company, setAddress, setFields }),
    [company, setAddress, setFields],
  );

  return (
    <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>
  );
}
