import {
  dayjs,
  Icons,
  LinkButton,
  PDFViewer,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2";
import { StatusTag } from "@Components/StatusTag";

import {
  FieldContainer,
  FieldName,
  FieldValue,
  FieldValueWrapper,
  LockIcon,
  ObjectValueWrapper,
  StyledTag,
} from "./styled";

export const FieldDisplay = ({
  name,
  value,
  locked,
  required,
  statusTag,
  tag,
  tagVariant,
  link,
  component,
  options,
  loading,
}: any) => {
  const renderValue = () => {
    if (loading) {
      return <Skeleton variant="text" width={"100px"} />;
    } else if (tag) {
      return <StyledTag variant={tagVariant}>{value as string}</StyledTag>;
    } else if (statusTag) {
      return <StatusTag status={value} />;
    } else if (!!value && typeof value === "object") {
      return (
        <ObjectValueWrapper>
          {value.map((value: any) => {
            return (
              <StyledTag variant={tagVariant} key={value?.label}>
                {value?.label}
              </StyledTag>
            );
          })}
        </ObjectValueWrapper>
      );
    } else {
      switch (component) {
        case "link":
          return (
            <a target="_blank" rel="noopener noreferrer" href={link.toString()}>
              <LinkButton variant={"primary"}>
                {value as string}
                <Icons name="IconExternalLink" fill="transparent" />
              </LinkButton>
            </a>
          );

        case "select":
          const option = options.find(
            (opt: any) => (opt || { value: "" }).value == value,
          );
          return <>{option?.label}</>;

        case "checkbox":
          return <>{value ? "Sim" : "Não"}</>;

        case "datePicker":
          return (
            <>
              {value && dayjs(value as any).isValid()
                ? dayjs(value as any).format("DD/MM/YYYY")
                : value}
            </>
          );

        case "pdf":
          return (
            <PDFViewer
              height={"400px"}
              src={value as string}
              options={{
                showToolbar: true,
                fit: "height",
                page: 1,
              }}
            />
          );

        default:
          return <>{value}</>;
      }
    }
  };

  return (
    <FieldContainer>
      {name && (
        <FieldName variant="caption">
          {name}
          {!required && " (opcional)"}
        </FieldName>
      )}
      <FieldValueWrapper>
        <FieldValue variant="body3">
          {!!value || value === 0 || value?.toString() === "false"
            ? renderValue()
            : "-"}
        </FieldValue>
        {locked && <LockIcon name="IconLock" fill="transparent" size={16} />}
      </FieldValueWrapper>
    </FieldContainer>
  );
};
