import { useEffect, useState } from "react";

export enum ResultState {
  IDLE = "idle",
  LOADING = "loading",
  FAILED = "failed",
  SUCCESS = "success",
}

interface HookResultIdleState {
  state: ResultState.IDLE;
}

interface HookResultLoadingState {
  state: ResultState.LOADING;
}

interface HookResultFailedState<E> {
  state: ResultState.FAILED;
  error: E;
}

interface HookResultSuccessState<T> {
  state: ResultState.SUCCESS;
  data: T;
}

export type HookResult<T, E = Error> =
  | HookResultIdleState
  | HookResultLoadingState
  | HookResultFailedState<E>
  | HookResultSuccessState<T>;

type Props = {
  loading: boolean;
};

type UseResultState<T, E> = HookResult<T, E> & {
  setSuccess(data: T): void;
  setFailed(err: E): void;
  setLoading(): void;
};

export const useResultState = <T, E = Error>({
  loading,
}: Props): UseResultState<T, E> => {
  const [state, setState] = useState<ResultState>(ResultState.IDLE);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<E>();

  const setSuccess = (response: T) => {
    setData(response);
    setState(ResultState.SUCCESS);
  };

  const setFailed = (err: E) => {
    setError(err);
    setState(ResultState.FAILED);
  };

  const setLoading = () => {
    setState(ResultState.LOADING);
  };

  const actions = { setFailed, setSuccess, setLoading };

  useEffect(() => {
    if (loading) {
      setLoading();
    }
  }, [loading]);

  switch (state) {
    case ResultState.SUCCESS:
      return { ...actions, state, data } as any;
    case ResultState.FAILED:
      return { ...actions, state, error } as any;
    default:
      return { ...actions, state };
  }
};
