import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 40px;

  border: 1px solid var(--color-neutral-light2);
  border-radius: 8px;

  padding: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
`;

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleWrapper = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
`;
