import { Button, Skeleton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;

    align-items: flex-start;
    text-align: start;
    margin-bottom: 120px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 312px;

  @media screen and (min-width: 768px) {
    align-items: flex-start;
  }
`;

export const SubstextWrapper = styled.div`
  min-width: 312px;
  margin-right: 24px;
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: 8px;
`;

export const StyledSubtitle = styled(Typography)`
  margin-bottom: 16px;
  color: var(--color-primary);
`;

export const StyledDescription = styled(Typography)`
  margin-bottom: 40px;
  color: var(--color-neutral-50);
`;

export const FormWrapper = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-radius: 12px;
  padding: 40px;
  width: 100%;
`;

export const StyledFormTitle = styled(Typography)`
  margin-bottom: 24px;
`;

export const StyledPrimaryButton = styled(Button)`
  width: 100%;
  margin-bottom: 24px;
`;

export const StyledSecondaryButton = styled(Button)`
  width: 100%;
`;

export const SecondaryButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 24px;
`;

export const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
`;

export const StyledDividerText = styled(Typography)`
  color: var(--color-neutral-50);
  min-width: fit-content;
`;

export const ExistingFieldSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

export const FieldComponentSkeleton = styled(Skeleton)`
  height: 50px;
  border-radius: 12px;
`;

export const ExistingFieldsActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const CancelButton = styled(Button)``;

export const ConfirmButton = styled(Button)``;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 1024px;
`;

export const SelectedFieldsWrapper = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-radius: 12px;
  padding: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
