import { useState } from "react";
import { useParams } from "react-router-dom";
import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";
import { DEPARTMENT_CREATE_PAGE } from "@departments/events";
import { useCreateDepartment } from "@departments/hooks";
import { DepartmentFormStep, Employee } from "@departments/types";
import { usePageTracking } from "@Utils/usePageTracking";
import { routes } from "src/routes";

import { useSession } from "@/common/user";

import {
  BasicInformation as FirstStep,
  CreateTransferList as SecondStep,
  Footer,
  Header,
  HeaderBreadcrumbs,
} from "./components";
import { Body, Container, ContentWrapper } from "./styled";

export const DepartmentsManagementPage = () => {
  usePageTracking(DEPARTMENT_CREATE_PAGE);

  const { departmentId } = useParams();
  const isEdit = !!departmentId;

  const breadcrumbs: HeaderBreadcrumbs[] = [
    {
      label: "Gerenciar empresa",
      route: routes.pageInitial,
      options: { state: { tab: 1 } },
    },
    {
      label: "...",
      route: routes.departments,
    },
    { label: `${isEdit ? "Editar" : "Criar"} departamento` },
  ];
  const pageTitle = isEdit ? "Editar Departamento" : "Criar Departamento";

  const { companyId } = useSession();
  const [name, setName] = useState("");
  const [externalId, setExternalId] = useState("");
  const [description, setDescription] = useState("");
  const [activeStep, setActiveStep] = useState(DepartmentFormStep.FIRST);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [members, setMembers] = useState<Employee[]>([]);
  const [totalEmployees, setTotalEmployees] = useState(0);

  const { createDepartment, loading } = useCreateDepartment();

  const onSubmit = () => {
    const employeeIds = members.map((employee) => employee._id);
    createDepartment({
      name,
      description,
      externalId,
      employeeIds,
      companyId,
    });
  };

  return (
    <Body>
      <Header
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        breadcrumbs={breadcrumbs}
      />
      <ContentWrapper>
        <PageContainer>
          <Container>
            {activeStep === DepartmentFormStep.FIRST && (
              <FirstStep
                title={pageTitle}
                name={name}
                setName={setName}
                description={description}
                setDescription={setDescription}
                externalId={externalId}
                setExternalId={setExternalId}
              />
            )}
            {activeStep === DepartmentFormStep.SECOND && (
              <SecondStep
                employees={employees}
                setEmployees={setEmployees}
                members={members}
                setMembers={setMembers}
                totalEmployees={totalEmployees}
                setTotalEmployees={setTotalEmployees}
              />
            )}
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer
        loading={loading}
        disableForwardFirstStep={!name.length}
        disableForwardSecondStep={!members.length}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        onSubmit={onSubmit}
      />
    </Body>
  );
};
