import { useMutation } from "@apollo/client";
import { UPDATE_GROUP_MEMBERS } from "@/api";
import { ErrorBoundary, dispatchToast } from "@/utils";
import { useNavigate } from "react-router-dom";
import { routes } from "@/routes";
import {
  UpdateGroupMembers,
  UpdateGroupMembersVariables,
} from "@/api/mutations/__generated__/UpdateGroupMembers";

export const useUpdateGroupMembers = (groupId: string) => {
  const navigate = useNavigate();

  const [mutate, { loading }] = useMutation<
    UpdateGroupMembers,
    UpdateGroupMembersVariables
  >(UPDATE_GROUP_MEMBERS, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: "Membros do grupo alterados com sucesso!",
      });
      navigate(routes.pageGroups(groupId));
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content:
          "Ocorreu um erro ao alterar os membros do grupo. Por favor, tente novamente mais tarde.",
      });
    },
  });

  const updateGroupMembers = async (input: {
    groupName: string;
    employeesToAdd: any[];
    employeesToRemove: any[];
  }) => {
    if (groupId && input.groupName) {
      await mutate({
        variables: {
          groupId: groupId,
          groupName: input.groupName,
          membersToAdd: input.employeesToAdd?.map((m) => ({
            _id: m._id,
            groups: m?.groups?.map((g: any) => ({ _id: g?.id, name: g?.name })),
          })),
          membersToRemove: input.employeesToRemove?.map((m) => ({
            _id: m.id,
            groups: m?.groups.map((g: any) => ({ _id: g?.id, name: g?.name })),
          })),
        },
      });
    }
  };

  return { updateGroupMembers, loading };
};
