import gql from "graphql-tag";

export const GET_COMPANY_SECTIONS_FIELDS = gql`
  query GetCompanySectionsFields($companyId: ID) {
    getCompany(companyId: $companyId) {
      _id
      sectionsFields {
        _id
        name
        description
        type
        order
        fields {
          _id
          name
          sensitive
          order
          mask
          type
          component
          options
          expires
        }
      }
    }
  }
`;
