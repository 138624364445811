import { COMPANY_GROUPS_QUERY } from "@/api/queries/groups";
import { dispatchToast, HookResult, useResultState, ResultState } from "@utils";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { CompanyGroupsQuery } from "@/api/queries/groups/__generated__/CompanyGroupsQuery";
import { useCompany } from "@/lib";
import { Group } from "../types";

type GetCompanyGroupsHookResult = HookResult<Group[]> & {
  groups: Group[];
  loading: boolean;
};

export const useGetGroups = (): GetCompanyGroupsHookResult => {
  const [groups, setGroups] = useState<Group[]>([]);
  const { companyId } = useCompany();
  const { setSuccess, setFailed, setLoading, ...resultStateProps } =
    useResultState<Group[]>({
      loading: true,
    });

  const variables = useMemo(() => {
    if (companyId) {
      setLoading();
      return { companyId };
    }
    return false;
  }, [companyId]);

  useQuery(COMPANY_GROUPS_QUERY, {
    variables: variables ? { ...variables } : undefined,
    onCompleted: (data) => {
      const groups: Group[] = data?.getCompany?.groups?.map(
        (group: any): Group => ({
          ...group,
          _id: group.id,
          key: group.id,
          title: group.name,
        }),
      );
      setSuccess(groups);
      setGroups(groups);
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content:
          "Sentimos muito, ocorreu um erro ao buscar os grupos da empresa.",
      });
    },
    fetchPolicy: "network-only",
  });

  const loading =
    resultStateProps.state === ResultState.LOADING ||
    resultStateProps.state === ResultState.IDLE;

  return {
    groups,
    loading,
    ...resultStateProps,
  };
};
