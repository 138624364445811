import {
  IconButton,
  Modal,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledModal = styled(Modal.Root)`
  && {
    .modal-content-area {
      padding: 76px 40px 40px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const LeftWrapper = styled.div`
  width: 100%;
  min-width: 312px;
`;

export const RightWrapper = styled.div`
  width: 100%;
  min-width: 312px;
`;

export const Title = styled(Typography)`
  margin-bottom: 4px;
`;

export const Description = styled(Typography)`
  color: var(--color-neutral-50);
  margin-bottom: 24px;
`;

export const FieldDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
`;

export const ToggleTextWrapper = styled.div`
  color: var(--color-neutral-30);
`;

export const ToggleText = styled(Typography)`
  font-size: 16px !important;
`;

export const ToggleDescription = styled(Typography)``;

export const ExitButton = styled(IconButton)`
  && {
    position: absolute;
    right: 0;
    top: -40px;
  }
`;

export const AddOptionsWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const OptionWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: center;
`;

export const OptionTitle = styled(Typography)`
  font-size: 16px !important;
`;
