import { useFlag } from "@flash-tecnologia/feature-flags";
import { TabTemplateProps } from "./components/TabTemplate";
import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";

export type TabType = {
  label: string;
} & TabTemplateProps;

export const useTabs = () => {
  const permissions = usePermissions();
  const { selectedCompany } = useSelectedCompany();
  const showAdminTabs =
    permissions.isAdmin ||
    permissions.companies
      .find((company) => company.id === selectedCompany.id)
      ?.permissions?.includes("core_contract_products");
  const rolesEnabled = useFlag({
    flagName: "FLASH_OS_COMPANY_MANAGEMENT_ROLES",
  });
  const subsidiaryEnabled = useFlag({
    flagName: "FLASH_OS_EXPENSE_MANAGEMENT_SUBSIDIARIES",
  });
  const programmaticAccessEnabled = useFlag({
    flagName: "FLASH_OS_PROGRAMMATIC_ACCESS",
  });

  const tabCompany: TabType = {
    label: "Empresa",
    description:
      "Consulte e gerencie as informações cadastrais da empresa e personalize aparência da plataforma.",
    secondaryTabs: [
      {
        icon: "IconInfoCircle",
        title: "Informações da empresa",
        description:
          "Consulte e gerencie as informações cadastrais da empresa e personalize a aparência da plataforma.",
        redirectTo: "/settings/company",
      },
      {
        icon: "IconBuildingSkyscraper",
        title: "Empresas do Grupo",
        description:
          "Visualize e gerencie todas as empresas que fazem parte do grupo.",
        redirectTo: "/settings/economic-group",
        isAdminRequired: true,
      },
      {
        icon: "IconShieldCheck",
        title: "Super administradores",
        description:
          "Gerencie os administradores que possuem nível máximo de permissão na plataforma.",
        redirectTo: "/settings/permissions/administrators",
        isAdminRequired: true,
      },
    ],
  };

  const tabPlatform: TabType = {
    label: "Plataforma",
    description:
      "Configure diversos campos da plataforma de acordo com as necessidades da sua empresa.",
    secondaryTabs: [
      {
        icon: "IconLayoutGrid",
        title: "Departamentos",
        description:
          "Agrupe pessoas de acordo com os departamentos da empresa.",
        redirectTo: "/settings/departments",
      },
    ],
  };

  if (rolesEnabled) {
    tabPlatform.secondaryTabs.push({
      icon: "IconBriefcase",
      title: "Cargos",
      description: "Crie e gerencie cargos e seus integrantes.",
      redirectTo: "/settings/roles",
    });
  }

  tabPlatform.secondaryTabs.push(
    {
      icon: "IconUsers",
      title: "Grupos de pessoas",
      description: "Crie grupos de pessoas personalizados.",
      redirectTo: "/settings/groups",
    },
    {
      icon: "IconShieldCheck",
      title: "Acessos e permissões",
      description:
        "Defina diferentes níveis de permissões para as pessoas da empresa.",
      redirectTo: "/settings/permissions",
      permissions: ["core_manage_permissions"],
    },
    {
      icon: "IconCoin",
      title: "Centro de custo",
      description: "Crie e gerencie os centros de custo de despesas.",
      redirectTo: "/expenseManagement/settings/costCenters",
      permissions: ["expense_configuration_cost_center"],
      licenseFeatures: ["INTERMEDIATE_COST_CENTER"],
    },
    {
      icon: "IconForms",
      title: "Seções e campos",
      description:
        "Crie e gerencie seções e campos que são utilizadas como um padrão em todo o sistema.",
      redirectTo: "/settings/sections-fields",
    },
    {
      icon: "IconBuilding",
      title: "Clientes",
      description:
        "Crie e gerencie as empresas que serão utilizados no lançamento de despesas.",
      redirectTo: "/expenseManagement/settings/clients",
      permissions: ["expense_configuration_client"],
      licenseFeatures: ["INTERMEDIATE_CLIENT"],
    },
    {
      icon: "IconFile",
      title: "Projetos",
      description: "Adicione os projetos em que as despesas serão lançadas.",
      redirectTo: "/expenseManagement/settings/projects",
      permissions: ["expense_configuration_projects"],
      licenseFeatures: ["INTERMEDIATE_PROJECT"],
    },
  );

  if (subsidiaryEnabled) {
    tabPlatform.secondaryTabs.push({
      icon: "IconBuildingWarehouse",
      title: "Unidades de negócios",
      description:
        "Adicione unidades de negócio aos quais os colaboradores estão vinculados",
      redirectTo: "/expenseManagement/subsidiaries",
      permissions: ["expense_configuration_subsidiaries"],
      licenseFeatures: ["INTERMEDIATE_SUBSIDIARY"],
    });
  }

  if (programmaticAccessEnabled) {
    tabPlatform.secondaryTabs.push({
      icon: "IconKey",
      title: "Chaves de acesso programático",
      description:
        "Utilize suas chaves de acesso programático da Flash para realizar integrações com outras plataformas da sua empresa.",
      redirectTo: "/settings/keys",
    });
  }

  const tabFinance: TabType = {
    label: "Financeiro",
    description:
      "Confira e gerencie as informações de pagamento, transações, planos e contratos da empresa.",
    secondaryTabs: [
      {
        icon: "IconCash",
        title: "Pagamento e cobranças",
        description: "Visualize e gerencie seus pagamentos e cobranças.",
        redirectTo: "/billing",
      },
      {
        icon: "IconLicense",
        title: "Planos e contratos",
        description: "Visualize e gerencie seus planos e contratos.",
        redirectTo: "/contract-manager/plan",
      },
    ],
  };

  const tabContracts: TabType = {
    label: "Planos e contratos",
    description:
      "Confira e gerencie as informações de pagamento, transações, planos e contratos da empresa.",
    secondaryTabs: [
      {
        icon: "IconCash",
        title: "Planos",
        description: "Visualize e gerencie seus planos.",
        redirectTo: "/contract-manager/plan",
      },
      {
        icon: "IconLicense",
        title: "Contratos e aditivos",
        description: "Visualize seus contratos e aditivos.",
        redirectTo: "/contract-manager/contracts-and-amendments",
      },
    ],
  };

  if (showAdminTabs) {
    return [tabCompany, tabPlatform, tabFinance, tabContracts];
  }

  return [tabCompany, tabPlatform, tabFinance];
};
