import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Card = styled.button<{ selected: boolean }>`
  padding: 13px 16px;
  width: 200px;
  border-radius: 6px;

  text-align: start;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  border: 1px solid
    ${({ selected }) =>
      selected ? "var(--color-secondary-light5)" : "var(--color-neutral-90)"};

  background: ${({ selected }) =>
    selected ? "var(--color-secondary-light2)" : "transparent"};
  gap: 12px;
`;

export const Title = styled(Typography)<{ selected: boolean }>`
  color: ${({ selected }) =>
    selected ? "var(--color-secondary-dark4)" : "var(--color-neutral-dark3)"};
  font-weight: 600 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TagStyled = styled(Tag)((props) => ({
  height: props.theme.spacings.xs,
  padding: `${props.theme.spacings.xs5} ${props.theme.spacings.xs3}`,
  margin: `auto 0`,
}));
