import {
  createContext,
  type PropsWithChildren,
  useContext,
  useReducer,
} from "react";

type PaginationState = {
  totalPages: number;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
};

type Action =
  | { type: "set_selected_item"; payload: string }
  | { type: "set_pagination_state"; payload: Partial<PaginationState> }
  | { type: "set_search_value"; payload: string }
  | { type: "clear_search_value" };
type Dispatch = (action: Action) => void;
type State = {
  searchValue: string;
  paginationState: PaginationState;
  selectedId?: string;
};

const INITIAL_STATE: State = {
  searchValue: "",
  selectedId: undefined,
  paginationState: {
    totalCount: 0,
    totalPages: 1,
    pageNumber: 1,
    pageSize: 10,
  },
};

const RolesTableContext = createContext<{
  state: State;
  dispatch: Dispatch;
} | null>(null);

const rolesReducer = (state: State, action: Action): typeof INITIAL_STATE => {
  switch (action.type) {
    case "set_search_value": {
      return {
        ...state,
        searchValue: action.payload,
      };
    }
    case "clear_search_value": {
      return {
        ...state,
        searchValue: "",
      };
    }
    case "set_selected_item": {
      return {
        ...state,
        selectedId: action.payload,
      };
    }
    case "set_pagination_state": {
      return {
        ...state,
        paginationState: {
          totalCount:
            action.payload.totalCount ?? state.paginationState.totalCount,
          totalPages:
            action.payload.totalPages ?? state.paginationState.totalPages,
          pageSize: action.payload.pageSize ?? state.paginationState.pageSize,
          pageNumber:
            action.payload.pageNumber ?? state.paginationState.pageNumber,
        },
      };
    }
  }
};

export const RoleTableProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(rolesReducer, INITIAL_STATE);

  const value = { state, dispatch };

  return (
    <RolesTableContext.Provider value={value}>
      {children}
    </RolesTableContext.Provider>
  );
};

export const useRolesTableContext = () => {
  const context = useContext(RolesTableContext);
  if (!context) {
    throw new Error(
      "useRolesTableContext must be used within a RolesTableContext",
    );
  }
  return context;
};
