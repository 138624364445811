import styled from "styled-components";

import { Breadcrumbs } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: flex-start;
  border-bottom: 1px solid var(--color-neutral-90);
  padding: 16px 24px;
  background: white;
  align-items: center;

  gap: 24px;
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)``;
