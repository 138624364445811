import {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Loader, TransferList } from "@flash-tecnologia/hros-web-ui-v2";
import { useLazyQuery } from "@apollo/client";
import {
  DEPARTMENT_CREATE_MEMBERS_PAGE,
  DEPARTMENT_CREATE_TRANSFER_CLICKED,
} from "@departments/events";
import { CreateDepartmentStepName, Employee } from "@departments/types";
import { setEventTracking, usePageTracking } from "@utils";
import dispatchToast from "@Utils/dispatchToast";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import {
  GetEmployees,
  GetEmployees_getEmployees_metadata_fields,
} from "src/api/queries/__generated__/GetEmployees";
import { GET_EMPLOYEES } from "src/api/queries/getEmployees";

import { useSession } from "@/common/user";

import { TransferListCell } from "./components/TransferListCell";
import { TransferListEmptyState } from "./components/TransferListEmptyState";
import { FetchEmployeesRequestBuilder } from "./helpers/";
import EmptyTrasferListCellBuilder from "./helpers/empty-transfer-list-cell-builder";
import {
  Container,
  StyledDescription,
  StyledSubtitle,
  StyledTitle,
  TextWrapper,
  TransferListArea,
} from "./styled";

interface CreateTransferListProps {
  employees: Employee[];
  setEmployees: Dispatch<SetStateAction<Employee[]>>;
  totalEmployees: number;
  setTotalEmployees: Dispatch<SetStateAction<number>>;
  members: Employee[];
  setMembers: Dispatch<SetStateAction<Employee[]>>;
}

const PAGE_SIZE_DEFAULT_STEP = 20;

export const CreateTransferList = ({
  employees,
  setEmployees,
  totalEmployees,
  setTotalEmployees,
  members,
  setMembers,
}: CreateTransferListProps) => {
  usePageTracking(DEPARTMENT_CREATE_MEMBERS_PAGE);
  const { companyId } = useSession();
  const [searchState, setSearchState] = useState("");
  const [hasReachedEnd, setHasReachedEnd] = useState(false);
  const [isAwaitingNewSearch, setIsAwaitingNewSearch] = useState(false);
  const [totalFields, setTotalFields] =
    useState<GetEmployees_getEmployees_metadata_fields[]>();
  const [paginationState, setPaginationState] = useState({
    pageNumber: 1,
    index: 0,
    pageSize: PAGE_SIZE_DEFAULT_STEP,
  });

  const [fetchEmployees, { loading, data }] = useLazyQuery<GetEmployees>(
    GET_EMPLOYEES,
    {
      variables: new FetchEmployeesRequestBuilder()
        .withCompanyIds([companyId])
        .withIndex(paginationState?.index || 0)
        .withPageSize(paginationState.pageSize || PAGE_SIZE_DEFAULT_STEP)
        .build(),
      onCompleted: () => {
        setIsAwaitingNewSearch(false);
        setEmployees(handleFilterLeftList(data?.getEmployees?.employees));
        setTotalEmployees(data?.getEmployees?.count as any);
        if (!totalFields)
          setTotalFields(data?.getEmployees?.metadata?.fields as any);
      },
      onError: (error) => {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content: "Ocorreu um erro ao buscar os colaboradores.",
        });
      },
    },
  );

  useLayoutEffect(() => {
    const transferListContainer: any = document.getElementsByClassName(
      "transfer-list-filter-search-field-container",
    );

    if (!transferListContainer.length) return;

    Array.from(transferListContainer).forEach((item: any) => {
      if (!item?.childNodes?.length) return;
      item.childNodes[0].style.width = "100%";
    });
  }, []);

  const handleFilterLeftList = (employees: any) => {
    return employees?.filter(
      ({ _id }: { _id: string }) =>
        !members?.some(({ _id: _idRight }) => _idRight === _id),
    );
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (isAwaitingNewSearch) {
        setPaginationState({
          index: 0,
          pageSize: 20,
          pageNumber: 1,
        });
        await fetchEmployees({
          variables: new FetchEmployeesRequestBuilder()
            .withCompanyIds([companyId])
            .withSearch(searchState)
            .build(),
        });
        setIsAwaitingNewSearch(false);
      } else {
        fetchEmployees({
          variables: new FetchEmployeesRequestBuilder()
            .withCompanyIds([companyId])
            .withIndex(paginationState?.index || 0)
            .withPageSize(paginationState?.pageSize || PAGE_SIZE_DEFAULT_STEP)
            .withSearch(searchState)
            .build(),
        });
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchState, paginationState]);

  useEffect(() => {
    if (loading && hasReachedEnd) {
      setEmployees((prev) => {
        const newEmployees = [...prev];
        newEmployees.push(
          new EmptyTrasferListCellBuilder()
            .withName(<Loader size="small" variant="primary" />)
            .build(),
        );
        return newEmployees;
      });
    }
  }, [loading]);

  useEffect(() => {
    if (employees?.length === 0) {
      fetchEmployees({
        variables: new FetchEmployeesRequestBuilder()
          .withCompanyIds([companyId])
          .withPageSize(paginationState?.pageSize + PAGE_SIZE_DEFAULT_STEP)
          .withSearch(searchState)
          .build(),
      });
    }
  }, [employees?.length]);

  const columns = [
    {
      Header: "Nome",
      accessor: "name",
      Cell: ({ row }: any) => <TransferListCell row={row} />,
    },
  ];

  const dataToListItem = (employees: Employee[]) => {
    return employees.map((employee) => ({
      ...employee,
      checked: employee?.checked || false,
      hidden: employee?.hidden || false,
    }));
  };

  const onCheckSetState = (
    set: Dispatch<SetStateAction<Employee[]>>,
    allChecked?: boolean,
    data?: any,
  ) => {
    set((prev) => {
      setEventTracking(DEPARTMENT_CREATE_TRANSFER_CLICKED);
      return data?.map((row: any, index: number) => ({
        ...prev[index],
        checked: allChecked !== undefined ? allChecked : row?.checked,
      }));
    });
  };

  const onScrollReachEnd = (hasReachedEnd: boolean) => {
    const scrollReachedEnd =
      employees?.length < totalEmployees && hasReachedEnd;
    const lastCellIsValidEmployee =
      typeof employees?.[employees.length - 1]?.name === "string";
    if (scrollReachedEnd && lastCellIsValidEmployee) {
      setPaginationState({
        ...paginationState,
        index: paginationState.index,
        pageSize: paginationState.pageSize + PAGE_SIZE_DEFAULT_STEP,
      });
      fetchEmployees({
        variables: new FetchEmployeesRequestBuilder()
          .withCompanyIds([companyId])
          .withIndex(paginationState.index)
          .withPageSize(paginationState.pageSize + PAGE_SIZE_DEFAULT_STEP)
          .withSearch(searchState),
      });
    }
  };

  return (
    <div>
      <StyledTitle variant="headline6">Criar departamento</StyledTitle>
      <Container>
        <TextWrapper>
          <StyledSubtitle variant="headline8">
            {CreateDepartmentStepName.SECOND}
          </StyledSubtitle>
          <StyledDescription variant="body3">
            Escolha quais pessoas você gostaria de adicionar a esse
            departamento.
          </StyledDescription>
        </TextWrapper>
        <TransferListArea>
          <TransferList
            columns={columns as any}
            onTransfer={({ leftList, rightList }) => {
              setEmployees(leftList?.data as any);
              setMembers(rightList?.data as any);
            }}
            leftList={{
              data: isAwaitingNewSearch
                ? []
                : (dataToListItem(employees) as any),
              total: totalEmployees || 0,
              title: `Todas as pessoas (${totalEmployees || 0})`,
              loading: false,
              onScroll: (_, hasReachedEnd) => {
                setHasReachedEnd(hasReachedEnd);
                onScrollReachEnd(hasReachedEnd);
              },
              onCheck: ({ allChecked, data }) =>
                onCheckSetState(setEmployees, allChecked, data),
              onSearch: (leftSearch: string) => {
                if (leftSearch == searchState) return;
                setIsAwaitingNewSearch(true);
                setSearchState(leftSearch);
              },
              emptyStageMessage: (
                <TransferListEmptyState
                  data={employees}
                  loading={loading}
                  searching={isAwaitingNewSearch}
                />
              ),
            }}
            rightList={{
              data: dataToListItem(members) as any,
              total: 0,
              title: `Selecionados para o departamento (${
                members?.length || 0
              })`,
              loading: false,
              onCheck: ({ allChecked, data }) =>
                onCheckSetState(setMembers, allChecked, data),
              onSearch: (rightSearch: string) => {},
              emptyStageMessage: (
                <TransferListEmptyState data={employees} loading={false} />
              ),
            }}
          />
        </TransferListArea>
      </Container>
    </div>
  );
};
