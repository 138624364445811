import { useMutation } from "@apollo/react-hooks";
import { dispatchToast } from "@utils";
import { DELETE_API_KEY } from "@/api/mutations/apikeys";
import {
  DeleteApiKey,
  DeleteApiKeyVariables,
} from "@/api/mutations/apikeys/__generated__/DeleteApiKey";

export const useDeleteApiKeys = (setShowModal: any, refetch: any) => {
  const [deleteApiKeyMutation, { loading }] = useMutation<
    DeleteApiKey,
    DeleteApiKeyVariables
  >(DELETE_API_KEY, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        title: "Chave de excluida com sucesso!",
        content: undefined,
        description:
          "A chave de acesso programático foi desativada! A partir de agora, os sistemas ou módulos que utilizavam esta chave perderão as permissões.",
      });
      setShowModal(false);
      refetch();
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content:
          "Sentimos muito, ocorreu um erro ao tentar excluir a chave de API.",
      });
    },
    fetchPolicy: "network-only",
  });

  const deleteApiKey = ({ keyId }: DeleteApiKeyVariables): void => {
    deleteApiKeyMutation({
      variables: { keyId },
    });
  };

  return {
    deleteApiKey,
    loading,
  };
};
