import { Modal, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledModal = styled(Modal.Root)`
  && {
    .modal-content-area {
      padding: 0px 36px;
    }
  }
`;

export const BoldText = styled.span`
  weight: 700;
`;

export const StyledTitle = styled(Typography)`
  color: var(--color-neutral-10);
`;

export const StyledDetails = styled(Typography)`
  color: var(--color-neutral-40);
`;

export const ErrorColor = styled.span`
  color: #c96c01;
`;

export const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 408px;
  gap: 10px;
  margin-bottom: 12px;
`;
