import { gql } from "@apollo/client";

export const GET_DEPARTMENT_WITH_MEMBERS = gql`
  query GetDepartmentWithMembers($companyId: ID!, $departmentId: ID!) {
    getDepartment(companyId: $companyId, departmentId: $departmentId) {
      _id
      name
      description
      members {
        _id
        name
        cpf
        email
        phone
      }
    }
  }
`;
