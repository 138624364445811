import { useState } from "react";

import { GET_ROLE_BY_ID } from "@/api/queries/roles";
import {
  GetRoleById,
  GetRoleById_role,
} from "@/api/queries/roles/__generated__/GetRoleById";
import { useCompany } from "@/lib";
import { dispatchToast, ErrorBoundary } from "@/utils";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { routes } from "@/routes";

export const useLazyGetRoleById = () => {
  const navigate = useNavigate();
  const { companyId } = useCompany();
  const [role, setRole] = useState<GetRoleById_role>();

  const [getRoleByIdQuery, { loading }] = useLazyQuery<GetRoleById>(
    GET_ROLE_BY_ID,
    {
      onCompleted: ({ role }) => {
        setRole(role as any);
      },
      onError: (error) => {
        const graphqlErrors: any = error.graphQLErrors?.[0];
        const errorCode = graphqlErrors?.errorCode as string;
        if (errorCode === "ROLE_DOES_NOT_EXISTS_ERROR") {
          dispatchToast({
            type: "error",
            content: "Sentimos muito, o cargo informado não existe.",
          });
        } else {
          ErrorBoundary.captureException(error);
          dispatchToast({
            type: "error",
            content:
              "Sentimos muito, ocorreu um erro ao buscar os cargos da empresa.",
          });
        }
        navigate(routes.roles);
      },
      fetchPolicy: "network-only",
    },
  );

  const getRoleById = (roleId: string) => {
    getRoleByIdQuery({
      variables: {
        companyId,
        roleId,
      },
    });
  };

  return {
    role,
    getRoleById,
    loading,
  };
};
