import { ReactNode, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  DataGrid,
  GenericProfilePicture,
  Icons,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Employee } from "@features/roles/types";
import { routes } from "src/routes";

import { TagGroup } from "@/components";

import { EmployeeOptions } from "./components/employee-options";
import {
  DataGridWrapper,
  EmailIcon,
  EmailText,
  EmailWrapper,
  FirstColumnCell,
  FirstColumnCellData,
} from "./styles";

type Props = {
  data: Record<string, ReactNode>[];
  pageSize: number;
  roleId: string;
  loading: boolean;
};

export const TableGrid = ({ data, pageSize, roleId, loading }: Props) => {
  const navigate = useNavigate();
  const tableRef = useRef(null);

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        Cell: ({ cell, value }: any) => {
          const email = cell?.row?.original?.email;
          return (
            <FirstColumnCell>
              <GenericProfilePicture
                size={40}
                name={cell?.row?.original?.name}
                source={cell?.row?.original?.profilePicture?.source}
              />
              <FirstColumnCellData>
                <Typography variant="body4">{value}</Typography>
                {email && (
                  <EmailWrapper>
                    <EmailIcon name="IconMail" size={16} fill="transparent" />
                    <EmailText variant="caption">{email}</EmailText>
                  </EmailWrapper>
                )}
              </FirstColumnCellData>
            </FirstColumnCell>
          );
        },
      },
      {
        Header: "CPF",
        accessor: "documentNumber",
      },
      {
        Header: "Telefone",
        accessor: "phoneNumber",
      },
      {
        Header: "Grupos",
        accessor: "groups",
        Cell: ({ value }: any) => !!value && <TagGroup tags={value} />,
        disableSortBy: true,
      },
      {
        Header: "Ações",
        Cell: (cellProps: any) => (
          <EmployeeOptions
            employeeId={cellProps?.cell?.row?.original?._id}
            active={cellProps?.cell?.row?.original?.active}
            roleId={roleId}
          />
        ),
        sticky: "right",
        disableSortBy: true,
      },
    ],
    [roleId],
  );

  return (
    <DataGridWrapper loading={loading} ref={tableRef}>
      <DataGrid<keyof Employee>
        loading={loading}
        columns={columns}
        data={data ?? []}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          message: "Você ainda não atribuiu nenhum integrante a este cargo",
          isFiltered: true,
          children: (
            <Button
              size="large"
              type="button"
              variant="primary"
              onClick={() => {
                navigate(routes.roleEmployees(roleId));
              }}
            >
              Atribuir integrantes
              <Icons name="IconPlus" />
            </Button>
          ),
        }}
      />
    </DataGridWrapper>
  );
};
