import { gql } from "@apollo/client";

export const GET_ROLE_MEMBERS_PAGINATED = gql`
  query GetRoleMembersPaginated($input: GetEmployeesV2Input) {
    employees(input: $input) {
      records {
        id
        name
        documentNumber
        phoneNumber
        email
        status
        companyId
        groups {
          id
          name
        }
      }
      recordsMetadata {
        totalCount
        totalPages
        currentPage
        nextPage
        prevPage
      }
      metadata {
        fields {
          label
          name
          options {
            fieldId
            name
          }
        }
      }
    }
  }
`;
