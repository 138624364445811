import gql from "graphql-tag";

export const GET_EMPLOYEES = gql`
  query GetEmployees($companyIds: [ID]!, $filter: GetEmployeesFilterType) {
    getEmployees(companyIds: $companyIds, filter: $filter) {
      count
      employeeIds
      employees {
        _id
        active
        companyId
        contacts {
          type
          value
        }
        cpf
        documentNumber
        email
        groups {
          _id
          name
        }
        name
        phone
        roles
        # status
        # profilePicture {
        #   source
        # }
      }
      metadata {
        fields {
          label
          name
          options {
            fieldId
            name
          }
        }
      }
    }
  }
`;
