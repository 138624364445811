import { useMemo, useState } from "react";

import { CompanyGroupsQuery_getCompany_groups_employees } from "@/api/queries/groups/__generated__/CompanyGroupsQuery";

import { Container } from "./styled";
import { TableFilters } from "./TableFilters";
import { TableGrid } from "./TableGrid";
import { TableHeader } from "./TableHeader";
import { TableSearch } from "./TableSearch";

export const Table = ({
  employees,
  loading,
  groupId,
  groupName,
}: {
  employees: CompanyGroupsQuery_getCompany_groups_employees[];
  loading: boolean;
  groupId: string;
  groupName: string;
}) => {
  const [search, setSearch] = useState<string>("");

  const filteredEmployees = useMemo(() => {
    return employees?.filter((e) =>
      e.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [employees, search]);

  return (
    <Container>
      <TableHeader totalCount={employees?.length} loading={loading} />
      <TableSearch
        onChange={(e) => setSearch(e)}
        disabled={!!!employees?.length}
      />
      <TableFilters
        groupId={groupId}
        groupName={groupName}
        disabled={!groupId}
      />
      <TableGrid
        data={filteredEmployees}
        pageSize={10}
        groupId={groupId}
        groupName={groupName}
      />
    </Container>
  );
};
