import styled from "styled-components";
import { Breadcrumbs } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin: 40px 0;
`;

export const NameCell = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
`;

export const ActionCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 160px;
`;

export const DateCell = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  min-width: 200px;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;
export const BoldText = styled.span`
  font-weight: 700;
`;

export const DateText = styled.span`
  margin-left: 10px;
  font-weight: 600;
`;
