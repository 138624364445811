import { memo } from "react";
import { FieldComponent } from "@Components/FieldComponent";
import { FieldDisplay } from "@Components/FieldDisplay";
import { GetCompany_getCompany } from "src/api/queries/__generated__/GetCompany";

import { model } from "./model";
import { Container } from "./styled";

export type RegistrationDataType = GetCompany_getCompany;

export const CompanyRegistrationDataComponent = ({
  registrationData,
  editing,
  loading,
  setRegistrationDataValue,
  registrationDataValue,
}: {
  registrationData: RegistrationDataType;
  editing: any;
  loading: boolean;
  setRegistrationDataValue: (newValue: RegistrationDataType) => void;
  registrationDataValue: RegistrationDataType;
}) => {
  return (
    <Container>
      {!editing.isEditing && (
        <>
          {model(registrationData).map((field) => {
            return (
              <FieldDisplay
                key={field?.key}
                name={field?.name}
                value={field?.value || "-"}
                locked={field?.locked}
                required={field?.required}
                statusTag={undefined}
                tag={undefined}
                tagVariant={undefined}
                link={undefined}
                component={field?.component}
                options={field?.options}
                loading={loading}
              />
            );
          })}
        </>
      )}
      {editing.isEditing && (
        <>
          {model(registrationDataValue).map((field) => {
            return (
              <FieldComponent
                {...field}
                key={field?.key}
                disabled={field?.locked}
                value={registrationDataValue?.[field?.key] || ""}
                inputProps={{ ...field?.inputProps }}
                onChange={(e) => {
                  setRegistrationDataValue({
                    ...registrationDataValue,
                    [field?.key]: e,
                  });
                }}
              />
            );
          })}
        </>
      )}
    </Container>
  );
};

export const CompanyRegistrationData = memo(CompanyRegistrationDataComponent);
