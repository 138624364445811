import {
  GenericProfilePicture,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  FirstColumnCell,
  FirstColumnCellData,
  SubtitleText,
  SubtitleWrapper,
} from "./styled";

interface IProfileColumn {
  name: string;
  imgSource: string;
  legalName: string;
}

export const ProfileColumn = ({
  name,
  imgSource,
  legalName,
}: IProfileColumn) => {
  return (
    <FirstColumnCell>
      <GenericProfilePicture size={40} name={name} source={imgSource} />
      <FirstColumnCellData>
        <Typography variant="body4">{name}</Typography>
        {!!legalName && (
          <SubtitleWrapper>
            <SubtitleText variant="caption">{legalName}</SubtitleText>
          </SubtitleWrapper>
        )}
      </FirstColumnCellData>
    </FirstColumnCell>
  );
};
