import { Employee } from "@departments/types";

export default class EmptyTrasferListCellBuilder {
  private name: string | any = "";
  private email?: string = undefined;
  private _id: string = "";
  private cpf: string = "";
  private phone: string = "";

  withName(name: string | any): EmptyTrasferListCellBuilder {
    this.name = name;
    return this;
  }
  build(): Employee {
    return {
      name: this.name,
      email: this.email,
      _id: this._id,
      cpf: this.cpf,
      phone: this.phone,
      hidden: false,
    };
  }
}
