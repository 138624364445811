import { useQuery } from "@apollo/client";
import { dispatchToast, HookResult, useResultState } from "@utils";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { useState, useMemo } from "react";
import { GET_COMPANY_DEPARTMENTS } from "src/api/queries/getDepartments";
import {
  GetCompanyDepartments,
  GetCompanyDepartments_getCompany_departments,
} from "src/api/queries/__generated__/GetCompanyDepartments";
import { DepartmentTabCard } from "../types";
import { useCompany } from "@/lib";

type GetDepartmentsHookResult = HookResult<DepartmentTabCard[]> & {
  departments: DepartmentTabCard[];
};

export const useGetDepartments = (): GetDepartmentsHookResult => {
  const { setSuccess, setFailed, setLoading, ...resultStateProps } =
    useResultState<DepartmentTabCard[]>({
      loading: true,
    });
  const [departments, setDepartments] = useState<DepartmentTabCard[]>([]);
  const { companyId } = useCompany();

  const variables = useMemo(() => {
    if (companyId) {
      setLoading();
      return { companyId };
    }
    return false;
  }, [companyId]);

  useQuery<GetCompanyDepartments>(GET_COMPANY_DEPARTMENTS, {
    variables: variables ? { ...variables } : undefined,
    onCompleted: (data) => {
      const departments: DepartmentTabCard[] | undefined =
        data?.getCompany?.departments?.map(
          (department: any): DepartmentTabCard =>
            ({
              departmentId: department._id,
              companyId: data.getCompany?._id,
              name: department.name,
              description: department.description,
            } as any),
        );
      setSuccess(departments as any);
      setDepartments(departments as any);
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content:
          "Sentimos muito, ocorreu um erro ao buscar os departamentos da empresa.",
      });
      setFailed(error);
    },
    fetchPolicy: "network-only",
  });

  return {
    departments,
    ...resultStateProps,
  };
};
