import { useNavigate, useSearchParams } from "react-router-dom";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { useMutation } from "@apollo/client";
import dispatchToast from "@Utils/dispatchToast";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { CreateGroup } from "src/api/mutations/__generated__/CreateGroup";
import { CREATE_GROUP } from "src/api/mutations/createGroup";
import { routes } from "src/routes/index";

import { COMPANY_GROUPS_QUERY } from "@/api/queries/groups";
import { useCompany } from "@/lib";

import {
  BackButton,
  CancelButton,
  Container,
  ContinueButton,
  Wrapper,
} from "./styled";

export const Footer = ({
  disableForward,
  name,
}: {
  disableForward: boolean;
  name: string;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { companyId } = useCompany();

  const [createGroup, { loading }] = useMutation<CreateGroup>(CREATE_GROUP, {
    onCompleted: (res) => {
      dispatchToast({
        type: "success",
        content: "Grupo criado com sucesso!",
      });
      searchParams.set("groupName", res.createGroup!.name!);
      navigate({
        pathname: routes.pageManageGroupMembers(res.createGroup!.groupId),
        search: searchParams.toString(),
      });
    },
    onError: (error) => {
      const graphqlErrors: any = error.graphQLErrors?.[0];
      const errorCode = graphqlErrors?.errorCode as string;
      if (errorCode === "GROUP_ALREADY_EXISTS_ERROR") {
        dispatchToast({
          type: "error",
          content: "Erro ao criar grupo",
          description: "Grupo com mesmo nome já existe!",
        });
      } else {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content:
            "Ocorreu um erro ao criar o grupo. Por favor, tente novamente mais tarde.",
        });
      }
    },
    refetchQueries: [COMPANY_GROUPS_QUERY],
    awaitRefetchQueries: true,
  });

  return (
    <Container>
      <CancelButton
        variant="secondary"
        onClick={() => navigate(routes.pageGroups())}
      >
        Cancelar
      </CancelButton>
      <Wrapper>
        <BackButton
          size="large"
          variant="secondary"
          onClick={() => navigate(routes.pageGroups())}
        >
          <Icons name="IconArrowLeft" />
          Voltar
        </BackButton>
        <ContinueButton
          size="large"
          variant="primary"
          disabled={disableForward}
          loading={loading}
          onClick={async () => {
            await createGroup({
              variables: {
                data: {
                  name: name,
                  companyId: companyId,
                },
              },
            });
          }}
        >
          Continuar <Icons name="IconArrowRight" />
        </ContinueButton>
      </Wrapper>
    </Container>
  );
};
