import { Search } from "./styled";

export const TableSearch = ({
  onChange,
}: {
  onChange: (value: any) => void;
}) => {
  const handleOnChange = (value: any) => {
    if (onChange) {
      onChange(value);
    }
  };
  const handleOnBlur = (value: any) => {
    const target = value?.target?.value;
    if (onChange) {
      onChange(target || "");
    }
  };
  const handleOnInput = (value: any) => {
    const target = value?.target?.value;
    if (onChange) {
      onChange(target || "");
    }
  };

  return (
    <Search
      onChange={(e) => handleOnChange(e.target.value)}
      onSearchChange={handleOnBlur}
      onInputChange={handleOnInput}
      label="Buscar empresas do grupo"
    />
  );
};
