import { useCheckPlatformPermission } from "src/shared/hooks/permissions/use-check-platform-permission";
import { useCheckUnleashFlags } from "src/shared/hooks/permissions/use-check-unleash-flags";
import { useLicenseFeatures } from "src/shared/hooks/permissions/use-license-features";

interface IProps {
  permissions?: string[];
  licenseFeatures?: string[];
  unleashFlag?: string;
  hidden?: boolean;
  isAdminRequired?: boolean;
  children?: React.ReactNode;
}

export function PermissionsChecker({
  permissions,
  licenseFeatures,
  unleashFlag,
  isAdminRequired = false,
  hidden = false,
  children,
}: IProps) {
  const { employeeIsAdmin, checkPlatformPermission } =
    useCheckPlatformPermission();
  const { checkLicenseFeature } = useLicenseFeatures();
  const checkUnleashFlag = useCheckUnleashFlags();

  function checkPermissions() {
    let allowed = true;

    if (permissions) {
      allowed = permissions.some((flag) => checkPlatformPermission(flag));
    }

    if (licenseFeatures) {
      allowed =
        allowed &&
        licenseFeatures.some((feature) => checkLicenseFeature(feature));
    }

    if (unleashFlag) {
      allowed = allowed && checkUnleashFlag(unleashFlag);
    }

    if (isAdminRequired) {
      allowed = allowed && employeeIsAdmin;
    }

    return allowed;
  }

  return checkPermissions() && !hidden ? <>{children}</> : null;
}
