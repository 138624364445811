import {
  Content,
  FieldTitleInput,
  FormWrapper,
  StyledDescription,
  StyledFormTitle,
  StyledSubtitle,
  StyledTitle,
  SubstextWrapper,
  TextWrapper,
} from "./styled";

export const FirstStepContent = ({
  setName,
  name,
}: {
  setName: (name: string) => void;
  name: string;
}) => {
  return (
    <>
      <TextWrapper>
        <StyledTitle variant="headline6">Criar seção</StyledTitle>
        <StyledDescription variant="body3">
          Adicione os campos e/ou anexos pertencentes a esta seção.
        </StyledDescription>
      </TextWrapper>
      <Content>
        <SubstextWrapper>
          <StyledSubtitle variant="headline8">
            Informações básicas
          </StyledSubtitle>
          <StyledDescription variant="body3">
            Dê um nome á seção e descreva o seu objetivo.
          </StyledDescription>
        </SubstextWrapper>
        <FormWrapper>
          <StyledFormTitle variant="headline8">Nome</StyledFormTitle>
          <FieldTitleInput
            label="Nome da seção"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </FormWrapper>
      </Content>
    </>
  );
};
