import {
  usePermissions,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";

export function useCheckPlatformPermission() {
  const { companies, isAdmin } = usePermissions();
  const { selectedCompany: companyData } = useSelectedCompany();
  const currencyCompanyPermissions = companies?.find(
    (company) =>
      company?.registrationNumber === companyData?.registrationNumber,
  );
  const permissions = currencyCompanyPermissions?.permissions;

  function checkPlatformPermission(slug: string) {
    return permissions?.some(
      (_slug) => _slug === slug || permissions?.includes("*"),
    );
  }

  return {
    employeeIsAdmin: isAdmin,
    checkPlatformPermission,
  };
}
