import { SearchField } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

import { useDepartmentsTableContext } from "../table-context";

const Search = styled(SearchField)`
  .text-field-custom-theme div.MuiFilledInput-root {
    border-radius: 30px;
  }
  width: 100%;
  height: 60px;
`;

type TableSearchProps = {
  onChange?: (value: string) => void;
  disabled?: boolean;
};

export const TableSearch = ({ onChange, disabled }: TableSearchProps) => {
  const { state, dispatch } = useDepartmentsTableContext();

  const handleOnChange = (value: string) => {
    dispatch({ type: "set_search_value", payload: value });
    onChange?.(value);
  };
  const handleOnBlur = (value: any) => {
    const target = value?.target?.value;
    if (onChange) {
      onChange(target || "");
    }
  };
  const handleOnInput = (value: any) => {
    const target = value?.target?.value;
    if (onChange) {
      onChange(target || "");
    }
  };

  return (
    <Search
      value={state.searchValue}
      onChange={(e) => handleOnChange(e.target.value)}
      onSearchChange={handleOnBlur}
      onInputChange={handleOnInput}
      label="Buscar por pessoas atribuídas"
      disabled={disabled}
    />
  );
};
