import { Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import {
  Container,
  FooterCancelButton,
  SubmitButton,
  SubmitButtonText,
} from "./styled";

export const Footer = ({
  onSubmit,
  onClose,
  loading,
  disabled,
}: {
  onSubmit: () => void;
  onClose: () => void;
  loading: boolean;
  disabled: boolean;
}) => {
  return (
    <Container>
      <FooterCancelButton variant="secondary" onClick={onClose} size="large">
        Cancelar
      </FooterCancelButton>
      <SubmitButton
        variant={"primary"}
        size={"large"}
        onClick={onSubmit}
        loading={loading}
        disabled={disabled}
        disableRipple
        disableTouchRipple
      >
        <SubmitButtonText disabled={disabled}>
          Concluir <Icons name="IconCheck" fill="transparent" size={24} />
        </SubmitButtonText>
      </SubmitButton>
    </Container>
  );
};
