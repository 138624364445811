export const normalizeSearchText = (input: string) => {
  if (input) {
    const normalizedText = input
      .toLowerCase()
      .normalize("NFD")
      .replace(/[^\w ]/g, "");
    return normalizedText;
  }
  return "";
};
