import { Icons, Tag } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-radius: 12px;
  padding: 24px 40px;
`;

export const ComponentInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const StyledComponentIcon = styled(Icons)`
  color: var(--color-neutral-40);
`;

export const StyledComponentTag = styled(Tag)`
  max-height: 26px;
`;

export const Component = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 9;
  position: relative;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: var(--color-neutral-50);
`;

export const FieldComponentWrapper = styled.div`
  flex: 1;
`;

export const StyledOptionIcon = styled(Icons)<{ color?: string }>`
  cursor: pointer;

  && {
    color: ${({ color }) => color}
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
`;
