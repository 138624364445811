import styled from "styled-components";

export const HeaderContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  gap: 24px;

  @media screen and (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`;

export const HeaderTitle = styled.div`
  width: 50%;
`;
