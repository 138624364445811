import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import {
  StyledDetails,
  StyledModal,
  StyledTitle,
  TextFieldContainer,
} from "./styled";

export const DeleteKeyModal = ({
  disabled,
  isOpen,
  loading,
  onClose,
  onSubmit,
}: any) => {
  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      header={<Header onClose={onClose} />}
      footer={
        <Footer
          onClose={onClose}
          onSubmit={onSubmit}
          loading={loading}
          disabled={disabled}
        />
      }
    >
      <TextFieldContainer>
        <StyledTitle variant="headline7">
          Tem certeza que deseja excluir esta chave de acesso programático?
        </StyledTitle>
        <StyledDetails variant="body4">
          Se você desativar esta chave, todos os sistemas ou módulos que
          utilizavam essa conexão perderão o acesso e ela nunca mais poderá ser
          utilizada.
        </StyledDetails>
      </TextFieldContainer>
    </StyledModal>
  );
};
