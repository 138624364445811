import { gql } from "@apollo/client";

export const GET_API_KEYS = gql`
  query GetApiKeys {
    getApiKeys {
      id
      alias
      createdAt
      name
      obfuscatedKey
    }
  }
`;
