export enum StatusEnum {
  INVITATION_SCHEDULED = "INVITATION_SCHEDULED",
  INVITATION_PENDING = "INVITATION_PENDING",
  INVITATION_SENT = "INVITATION_SENT",
  INVITATION_EXPIRED = "INVITATION_EXPIRED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum FilterStatusEnum {
  REMOVE_INVITATION_SCHEDULED = "-INVITATION_SCHEDULED",
  REMOVE_INVITATION_PENDING = "-INVITATION_PENDING",
  REMOVE_INVITATION_SENT = "-INVITATION_SENT",
  REMOVE_INVITATION_EXPIRED = "-INVITATION_EXPIRED",
  REMOVE_ACTIVE = "-ACTIVE",
  REMOVE_INACTIVE = "-INACTIVE",
}
