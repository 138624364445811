import { Dot } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 36px;
`;

export const BoldText = styled.span`
  font-weight: 700;
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListContainer = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: row;
`;
