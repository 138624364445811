import styled from "styled-components";

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyCard = styled(Skeleton)`
  width: 100%;
  height: 110px;
  margin-bottom: 40px;
`;
