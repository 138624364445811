import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Icons, PageContainer } from "@flash-tecnologia/hros-web-ui-v2";
import {
  CreateTransferList,
  Footer,
  Header,
  HeaderBreadcrumbs,
} from "@departments/components";
import {
  DEPARTMENT_CREATE_BACK,
  DEPARTMENT_CREATE_CANCEL,
  DEPARTMENT_CREATE_SKIP,
} from "@departments/events";
import {
  useCreateDepartment,
  useGetDepartmentById,
  useUpdateDepartment,
} from "@departments/hooks";
import { useDepartmentsContext } from "@departments/store";
import { DepartmentFormStep, Employee } from "@departments/types";
import { useGetDepartmentWithMembers } from "@features/departments/hooks/get-department-with-members";
import { setEventTracking } from "@utils/eventTracking";
import { routes } from "src/routes";

import { useSession } from "@/common/user";

import { Body, Container, ContentWrapper } from "./styled";

const EditDepartmentSubmitButton = () => (
  <>
    Editar departamento
    <Icons name="IconCheck" fill="transparent" />
  </>
);

const CreateDepartmentSubmitButton = () => (
  <>
    Criar departamento
    <Icons name="IconCheck" fill="transparent" />
  </>
);

export const DepartmentEmployeesPage = () => {
  const navigate = useNavigate();
  const { departmentId } = useParams();
  const isEdit = !!departmentId;
  const mainTitle = `${isEdit ? "Editar" : "Criar"} departamento`;
  const breadcrumbs: HeaderBreadcrumbs[] = [
    {
      label: "Gerenciar empresa",
      route: routes.pageInitial,
      options: { state: { tab: 1 } },
    },
    {
      label: "...",
      route: routes.departments,
    },
    { label: mainTitle },
  ];

  const { companyId } = useSession();
  const [activeStep, setActiveStep] = useState(DepartmentFormStep.FIRST);
  const [members, setMembers] = useState<Employee[]>([]);

  const { department } = useGetDepartmentById(departmentId as string);
  const { createDepartment, loading } = useCreateDepartment();
  const { updateDepartment } = useUpdateDepartment();
  const { getMembers, members: departmentMembers } =
    useGetDepartmentWithMembers();

  const { dispatch, state } = useDepartmentsContext();

  useEffect(() => {
    if (
      (!state.department && department) ||
      (state.department && department?.name === state.department?.name)
    ) {
      dispatch({ type: "set_department", payload: { id: department.id } });
    }
  }, [state.department?.id, department?.id]);

  useEffect(() => {
    if (departmentId) {
      getMembers(departmentId);
    }
  }, [departmentId]);

  useEffect(() => {
    if (departmentMembers) {
      setMembers(departmentMembers);
    }
  }, [departmentMembers]);

  const createOrUpdateDepartment = () => {
    if (isEdit) {
      updateDepartment({
        input: {
          companyId,
          departmentId: state.department?.id,
          name: state.department?.name,
          externalId: state.department?.externalId,
          description: state.department?.description,
          employeeIds: members?.map((member) => member?._id) as string[],
        },
      } as any);
    } else {
      createDepartment({
        name: state.department?.name,
        description: state.department?.description || "",
        externalId: state.department?.externalId || "",
        companyId,
      } as any);
    }

    dispatch({ type: "clear_department" });
    navigate(routes.departments);
  };

  const handleSubmit = () => {
    createOrUpdateDepartment();
  };

  const handleCancel = () => {
    navigate(routes.departments);
    setEventTracking(DEPARTMENT_CREATE_CANCEL, {
      step: activeStep.toString(),
    });
    dispatch({ type: "clear_department" });
  };

  const handleBack = () => {
    if (isEdit) {
      navigate(routes.departmentEdit(departmentId));
    } else {
      navigate(routes.departmentCreate);
    }
    setEventTracking(DEPARTMENT_CREATE_BACK, {
      step: activeStep.toString(),
    });
  };

  const handleSkip = () => {
    setEventTracking(DEPARTMENT_CREATE_SKIP, {
      step: activeStep.toString(),
    });
    createOrUpdateDepartment();
  };

  const SubmitButtonContent = () =>
    isEdit ? <EditDepartmentSubmitButton /> : <CreateDepartmentSubmitButton />;

  return (
    <Body>
      <Header
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        breadcrumbs={breadcrumbs}
      />
      <ContentWrapper>
        <PageContainer>
          <Container>
            <CreateTransferList
              title={mainTitle}
              members={members}
              setMembers={setMembers}
            />
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer
        loading={loading}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        onBack={handleBack}
        onSubmit={handleSubmit}
        onSkip={handleSkip}
        modalSkip={
          !isEdit
            ? {
                title:
                  "Você não adicionou niguém ao departamento. Deseja sair mesmo assim?",
                description:
                  "Você poderá adicionar pessoas a este departamento em outro momento.",
                submitButtonContent: <SubmitButtonContent />,
              }
            : undefined
        }
        onCancel={handleCancel}
        submitButtonContent={<SubmitButtonContent />}
      />
    </Body>
  );
};
