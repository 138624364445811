import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  padding: 10px 10px 16px 36px;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 480px;
  gap: 8px;
`;

export const HeaderTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  font-color: #c96c01;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  gap: 8px;
`;

export const BoldText = styled.span`
  weight: 700;
`;

export const TypoErrorColor = styled(Typography)`
  color: #c96c01;
`;
