import styled from "styled-components";
import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-style: solid hidden hidden hidden;

  padding: 24px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FooterCancelButton = styled(Button)`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  color: var(--color-neutral-50);
`;

export const SubmitButton = styled(Button)``;

export const SubmitButtonText = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`;
