import { gql } from "@apollo/client";

export const GET_COMPANY_DEPARTMENTS = gql`
  query GetCompanyDepartments($companyId: ID) {
    getCompany(companyId: $companyId) {
      _id
      departments(companyId: $companyId) {
        _id
        name
        description
        isActive
        # members {
        #   _id
        #   active
        #   companyId
        #   contacts {
        #     type
        #     value
        #   }
        #   cpf
        #   documentNumber
        #   email
        #   groups {
        #     _id
        #     name
        #   }
        #   name
        #   phone
        #   roles
        #   # status
        #   # profilePicture {
        #   #   source
        #   # }
        # }
      }
    }
  }
`;
