import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFlag } from "@flash-tecnologia/feature-flags";
import {
  Button,
  Icons,
  LinkButton,
  PageContainer,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes";

import {
  useCreateApiKey,
  useDeleteApiKeys,
  useGetApiKeys,
} from "@/features/apikeys/hooks";
import {
  CreateApiKeyResponse,
  GetApiKeysResponse,
} from "@/features/apikeys/types";
import { dateFormat } from "@/utils";

import { CreateKeyModal } from "./components/CreateKeyModal";
import { KeysTable } from "./components/DataGrid";
import { DeleteKeyModal } from "./components/DeleteKeyModal";
import { Header } from "./components/Header";
import { NotificationCard } from "./components/NotificationCard";
import {
  ActionCell,
  BoldText,
  DateCell,
  DateText,
  HorizontalContainer,
  NameCell,
  StyledBreadcrumbs,
} from "./styled";

export const PageApiKeys = () => {
  const navigate = useNavigate();
  const [isCreateKeyModalOpen, setIsCreateKeyModalOpen] = useState(false);
  const [isDeleteKeyModalOpen, setIsDeleteKeyModalOpen] = useState(false);
  const [generatedKey, setGeneratedKey] =
    useState<CreateApiKeyResponse | null>();
  const [selectedKey, setSelectedKey] = useState<GetApiKeysResponse>();

  const { apiKeys, loading: fetchKeysLoading, refetch } = useGetApiKeys();
  const { apiKey, createApiKey, loading: creationLoading } = useCreateApiKey();
  const { deleteApiKey, loading: deletionLoading } = useDeleteApiKeys(
    setIsDeleteKeyModalOpen,
    refetch,
  );

  const isFeatEnabled = useFlag({
    flagName: "FLASH_OS_PROGRAMMATIC_ACCESS",
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "Nome e chave",
        disableSortBy: true,
        Cell: (cellProps: any) => {
          const { name = "", obfuscatedKey = "" } =
            cellProps?.cell?.row?.original;
          return (
            <NameCell>
              <Typography variant="body3">
                <BoldText>{name}</BoldText>
              </Typography>
              <HorizontalContainer>
                <Icons name="IconKey" size={15} />
                <Typography variant="body4">
                  {obfuscatedKey?.slice(-50)}
                </Typography>
              </HorizontalContainer>
            </NameCell>
          );
        },
      },
      {
        Header: "Data de criação",
        disableSortBy: true,
        Cell: (cellProps: any) => {
          const creationDate = cellProps?.cell?.row?.original?.createdAt;
          return (
            <DateCell>
              <Icons name="IconCalendarEvent" size={18} />
              <Typography variant="body3">
                <DateText>{dateFormat(creationDate)}</DateText>
              </Typography>
            </DateCell>
          );
        },
      },
      {
        Header: "Ações",
        sticky: "right",
        disableSortBy: true,
        Cell: (cellProps: any) => {
          const selectedKey = cellProps?.cell?.row?.original;
          return (
            <ActionCell>
              <Button
                size="small"
                variantType="neutral"
                buttonType="secondary"
                onClick={() => {
                  setSelectedKey(selectedKey);
                  setIsDeleteKeyModalOpen(true);
                }}
              >
                <Icons name="IconTrash" size={15} />
                Excluir chave
              </Button>
            </ActionCell>
          );
        },
      },
    ];
  }, [apiKeys]);

  useEffect(() => {
    setGeneratedKey(apiKey as any);
  }, [apiKey]);

  return (
    <PageContainer style={{ height: "100%" }}>
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton
            variant="secondary"
            onClick={() => navigate(routes.pageInitial, { state: { tab: 1 } })}
          >
            Gerenciar empresa
          </LinkButton>,
          <LinkButton variant="secondary">
            Chaves de acesso programático
          </LinkButton>,
        ]}
      />
      <Header
        setIsCreateKeyModalOpen={setIsCreateKeyModalOpen}
        isFeatEnabled={isFeatEnabled}
      />
      <NotificationCard />
      <KeysTable
        columns={columns}
        data={apiKeys}
        setIsCreateKeyModalOpen={setIsCreateKeyModalOpen}
        loading={fetchKeysLoading}
        isFeatEnabled={isFeatEnabled}
      />
      <CreateKeyModal
        disabled={creationLoading}
        isOpen={isCreateKeyModalOpen}
        loading={creationLoading}
        onClose={() => setIsCreateKeyModalOpen(false)}
        onSubmit={createApiKey}
        submitText="Criar nova chave"
        plainTextKey={generatedKey?.plainTextKey}
        refetch={refetch}
        setGeneratedKey={setGeneratedKey}
      />
      <DeleteKeyModal
        disabled={deletionLoading}
        isOpen={isDeleteKeyModalOpen}
        loading={deletionLoading}
        onClose={() => {
          setSelectedKey(undefined);
          setIsDeleteKeyModalOpen(false);
        }}
        onSubmit={() => {
          deleteApiKey({ keyId: selectedKey?.id } as any);
        }}
      />
    </PageContainer>
  );
};
