import {
  NotificationCard as Card,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  BoldText,
  Container,
  ListContainer,
  StyledInfoContainer,
} from "./styled";

export const NotificationCard = () => {
  return (
    <Container>
      <Card.Root
        type="info"
        showCloseButton={true}
        autoClose={true}
        variant="outlined"
      >
        <Card.Icon iconName="IconAlertCircle" />
        <StyledInfoContainer>
          <Typography variant="body3">
            <BoldText>Dicas e informações sobre as chaves</BoldText>
          </Typography>
          <ListContainer>
            <ul>
              <li>
                <Typography variant="body3">
                  Essas chaves fornecem acesso total para realizar alterações,
                  então salve-as em um local seguro e não as compartilhe
                  publicamente.
                </Typography>
              </li>
              <li>
                <Typography variant="body3">
                  Essas chaves são únicas para toda a sua conta na Flash.
                </Typography>
              </li>
              <li>
                <Typography variant="body3">
                  Você pode desativar/revogar ou criar uma nova chave a qualquer
                  momento.
                </Typography>
              </li>
            </ul>
          </ListContainer>
        </StyledInfoContainer>
      </Card.Root>
    </Container>
  );
};
