import {
  Accordion,
  IconButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 24px 40px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 312px;

  @media screen and (min-width: 768px) {
    align-items: flex-start;
  }
`;

export const StyledSubtitle = styled(Typography)`
  margin-bottom: 16px;
  color: var(--color-primary);
`;

export const StyledDescription = styled(Typography)`
  margin-bottom: 40px;
  color: var(--color-neutral-50);
`;

export const AccordionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledAccordion = styled(Accordion)`
  &.MuiPaper-root {
    &::before {
      background-color: transparent;
    }
  }
`;

export const AccordtionTitle = styled(Typography)`
  color: var(--color-neutral-30);
  margin-bottom: 4px;
`;

export const AccordionDescription = styled(Typography)`
  color: var(--color-neutral-50);
  margin-bottom: 16px;
`;

export const IconButtonStyled = styled(IconButton)<{ editing?: boolean }>`
  && {
    ${({ editing }) =>
      editing &&
      css`
        &.MuiButtonBase-root {
          border-color: ${({ theme }) => theme.colors.feedback.success[40]};

          svg {
            stroke: ${({ theme }) => theme.colors.feedback.success[40]};
          }
        }
      `}
  }
`;
