import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

export const useCompany = () => {
  const { selectedCompany } = useSelectedCompany();
  const company = selectedCompany;

  return {
    companyId: company.id,
    name: company.name,
    registrationNumber: company.registrationNumber,
  };
};
