import { useMutation } from "@apollo/client";
import { dispatchToast } from "@utils";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { useNavigate } from "react-router-dom";
import { UPDATE_DEPARTMENT } from "src/api";
import { routes } from "src/routes";
import { Department, departmentErrors } from "../types";

type HookResult = {
  updateDepartment: (params: UpdateDepartmentParams) => void;
  loading: boolean;
};

type UpdateDepartmentParams = {
  input: {
    companyId: string;
    departmentId: string;
    employeeIds?: string[];
  } & Omit<Department, "id">;
};

export const useUpdateDepartment = (): HookResult => {
  const navigate = useNavigate();

  const [updateDepartmentMutation, { loading }] = useMutation<
    void,
    UpdateDepartmentParams
  >(UPDATE_DEPARTMENT, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: "Departamento atualizado com sucesso!",
      });
      navigate(routes.departments);
    },
    onError: (error) => {
      const graphqlErrors: any = error.graphQLErrors?.[0];
      const errorCode = graphqlErrors?.errorCode as string;
      if (errorCode === departmentErrors.departmentsExists) {
        dispatchToast({
          type: "error",
          content: "Erro ao atualizar departamento",
          description: "Departamento com mesmo nome já existe.",
        });
      } else {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content:
            "Ocorreu um erro ao atualizar o departamento. Por favor, tente novamente mais tarde.",
        });
      }
    },
    refetchQueries: ["GetCompanyDepartments"],
  });

  const updateDepartment = ({ input }: UpdateDepartmentParams): void => {
    dispatchToast({
      type: "warning",
      content: "Atualizando departamento...",
    });

    updateDepartmentMutation({
      variables: {
        input,
      },
    });
  };

  return {
    updateDepartment,
    loading,
  };
};
