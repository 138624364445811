import styled from "styled-components";

export const FooterContainer = styled.div`
  border: 1px solid var(--color-neutral-light2);
  border-style: solid hidden hidden hidden;
  padding: 16px 36px;
  min-width: 400px;
  max-width: 650px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
`;

export const CancelButtonContainer = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;
