import { gql } from "@apollo/client";

export const GET_ROLE_BY_ID = gql`
  query GetRoleById($companyId: ID!, $roleId: ID!) {
    role(companyId: $companyId, roleId: $roleId) {
      id
      name
      description
      cbo
      externalId
    }
  }
`;
