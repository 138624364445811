import { useMemo, useState } from "react";
import { Employee } from "@features/departments/types";

import { Container } from "./styled";
import { DepartmentsTableProvider } from "./table-context";
import { TableFilters } from "./table-filters";
import { TableGrid } from "./table-grid";
import { TableHeader } from "./table-header";
import { TableSearch } from "./table-search";

type Props = {
  members: Employee[];
  loading: boolean;
  departmentId: string;
  departmentName: string;
};

export const Table = ({
  members,
  loading,
  departmentId,
  departmentName,
}: Props) => {
  const [filter, setFilter] = useState<string>("");

  const visibleMembers = useMemo(() => {
    if (filter?.length) {
      return members?.filter((member) =>
        member.name?.toLowerCase().includes(filter),
      );
    }
    return members;
  }, [members, filter]);

  return (
    <Container>
      <DepartmentsTableProvider>
        <TableHeader totalCount={visibleMembers?.length} loading={loading} />
        <TableSearch
          onChange={(value) => {
            setFilter(value);
          }}
          disabled={loading}
        />
        <TableFilters
          departmentId={departmentId}
          departmentName={departmentName}
          disabled={!departmentId}
        />
        <TableGrid
          data={visibleMembers}
          pageSize={10}
          departmentId={departmentId}
          loading={loading}
        />
      </DepartmentsTableProvider>
    </Container>
  );
};
