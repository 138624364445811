import { useMutation } from "@apollo/client";
import { CreateRoleInput } from "@grapql-schema";

import { CREATE_ROLE } from "@/api/mutations/roles";
import {
  CreateRole,
  CreateRoleVariables,
} from "@/api/mutations/roles/__generated__/CreateRole";
import { useCompany } from "@/lib";
import { dispatchToast, ErrorBoundary } from "@/utils";

import { roleErrors } from "../types";
import { useNavigate } from "react-router-dom";
import { routes } from "@/routes";

export const useCreateRole = () => {
  const navigate = useNavigate();
  const { companyId } = useCompany();

  const [createRoleMutation, { loading }] = useMutation<
    CreateRole,
    CreateRoleVariables
  >(CREATE_ROLE, {
    onCompleted: (data: any) => {
      dispatchToast({
        type: "success",
        content: "Cargo criado com sucesso!",
      });

      navigate({
        pathname: routes.roleEmployees(data?.createRole?.id),
        search: "?from=/roles/create",
      });
    },
    onError: (error) => {
      const graphqlErrors: any = error.graphQLErrors?.[0];
      const errorCode = graphqlErrors?.errorCode as string;

      if (errorCode === roleErrors.roleExists) {
        dispatchToast({
          type: "error",
          content: "Erro ao criar cargo",
          description: "Cargo com mesmo nome já existe.",
        });
      } else {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content:
            "Ocorreu um erro ao criar cargo. Por favor, tente novamente mais tarde.",
        });
      }
    },
  });

  const createRole = ({
    name,
    description,
    cbo,
    externalId,
  }: Omit<CreateRoleInput, "companyId">) => {
    return createRoleMutation({
      variables: {
        input: {
          companyId,
          name,
          description,
          cbo,
          externalId,
        },
      },
    });
  };

  return {
    createRole,
    loading,
  };
};
