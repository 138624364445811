import { gql } from "@apollo/client";

export const GET_ROLE_WITH_MEMBERS = gql`
  query GetRoleWithMembers($companyId: ID!, $roleId: ID!) {
    role(companyId: $companyId, roleId: $roleId) {
      id
      name
      description
      cbo
      members(companyId: $companyId) {
        _id
        name
        email
      }
    }
  }
`;
