import { useState } from "react";
import { IconButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { dispatchToast } from "@/utils";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import {
  ErrorColor,
  IconContainer,
  KeyContainer,
  KeyDisplayer,
  KeyTitle,
  KeyWrapper,
  SemiBoldText,
  StyledInputDetails,
  StyledInputTitle,
  StyledModal,
  TextFieldContainer,
  TextFieldInput,
  TextFieldTitleContainer,
} from "./styled";

export const CreateKeyModal = ({
  disabled,
  isOpen,
  loading,
  onClose,
  onSubmit,
  submitText,
  plainTextKey,
  refetch,
  setGeneratedKey,
}: any) => {
  const [keyName, setKeyName] = useState<string | null>();
  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    dispatchToast({
      content: "Chave de acesso copiada",
      type: "success",
    });
  };

  const handleOnFinish = () => {
    setKeyName(null);
    setGeneratedKey(null);
    onClose();
    refetch();
  };

  const isFinished = !!plainTextKey;

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      header={
        <Header
          onClose={isFinished ? handleOnFinish : onClose}
          loading={loading}
        />
      }
      loading={loading}
      footer={
        <Footer
          onClose={isFinished ? handleOnFinish : onClose}
          onSubmit={() => onSubmit({ name: keyName })}
          submitText={submitText}
          loading={loading}
          disabled={!keyName || disabled}
          finished={isFinished}
        />
      }
    >
      <TextFieldContainer>
        <TextFieldTitleContainer>
          <StyledInputTitle variant="headline8">
            Dados da chave de acesso
          </StyledInputTitle>
          <StyledInputDetails variant="body4">
            <ErrorColor>*</ErrorColor> campo obrigatório
          </StyledInputDetails>
        </TextFieldTitleContainer>
        <TextFieldInput
          label="Nome da chave"
          required={true}
          value={keyName}
          onChange={(e) => setKeyName(e.target.value)}
        />
        {plainTextKey && (
          <KeyContainer>
            <KeyWrapper>
              <KeyTitle>
                <Typography variant="caption">
                  <SemiBoldText>Chave de acesso:</SemiBoldText>
                </Typography>
              </KeyTitle>
              <KeyDisplayer>
                <Typography variant="body3">{plainTextKey}</Typography>
              </KeyDisplayer>
            </KeyWrapper>
            <IconContainer>
              <IconButton
                size="medium"
                variant="filled"
                icon="IconCopy"
                onClick={() => copyToClipboard(plainTextKey)}
              />
            </IconContainer>
          </KeyContainer>
        )}
      </TextFieldContainer>
    </StyledModal>
  );
};
