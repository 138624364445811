import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { CancelButtonContainer, FooterContainer } from "./styled";

export const Footer = ({ onClose, onSubmit, loading, disabled }: any) => {
  return (
    <FooterContainer>
      <CancelButtonContainer>
        <LinkButton variant="secondary" onClick={onClose} disabled={loading}>
          Cancelar
        </LinkButton>
      </CancelButtonContainer>
      <Button
        variant="primary"
        size="medium"
        buttonType="primary"
        variantType="error"
        onClick={onSubmit}
        loading={loading}
        disabled={disabled}
      >
        Sim, excluir
        <Icons name="IconPower" size={24} />
      </Button>
    </FooterContainer>
  );
};
