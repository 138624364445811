import {
  GenericProfilePicture,
  IconButton,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  ContentContainer,
  ContentWrapper,
  Description,
  FooterCancelButton,
  FooterContainer,
  NewPic,
  OldPic,
  ProfilePictureChange,
  StyledModal,
  SubmitButton,
  TextsWrapper,
  Title,
} from "./styled";

type ModalDeleteProfilePictureProps = {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  source: string;
  onSubmit: () => void;
  loading: boolean;
};

export const ModalDeleteProfilePicture = ({
  isOpen,
  onClose,
  name,
  source,
  onSubmit,
  loading,
}: ModalDeleteProfilePictureProps) => {
  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      footer={
        <FooterContainer>
          <FooterCancelButton onClick={onClose}>
            <Icons name="IconArrowLeft" fill="transparent" size={24} />
            <span>Cancelar</span>
          </FooterCancelButton>
          <SubmitButton
            variant={"primary"}
            buttonType={"primary"}
            size={"small"}
            style={{ margin: "auto 0", padding: "19px 52px" }}
            onClick={onSubmit}
            loading={loading}
            disabled={loading}
            disableRipple
            disableTouchRipple
          >
            <span>Confirmar exclusão</span>
            <Icons name="IconTrash" fill="transparent" size={32} />
          </SubmitButton>
        </FooterContainer>
      }
    >
      <ContentContainer>
        <ContentWrapper>
          <ProfilePictureChange>
            <OldPic>
              <GenericProfilePicture name={name} size={120} source={source} />
            </OldPic>
            <NewPic>
              <GenericProfilePicture name={name} size={120} />
            </NewPic>
          </ProfilePictureChange>
          <TextsWrapper>
            <Title variant="headline6">
              Tem certeza que deseja excluir o logo?
            </Title>
            <Description variant="body3">
              Essa ação removerá o logo atual e sua empresa será identificada
              apenas pelas iniciais.
            </Description>
          </TextsWrapper>
        </ContentWrapper>
        <IconButton
          variant="line"
          size="small"
          icon="IconX"
          onClick={onClose}
        />
      </ContentContainer>
    </StyledModal>
  );
};
