import { useState } from "react";
import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";

import { Footer } from "./components/Footer";

import {
  Body,
  ContentWrapper,
  Container,
  TextWrapper,
  FormWrapper,
  StyledTitle,
  StyledSubtitle,
  StyledDescription,
  StyledFormTitle,
  StyledFormDescription,
  FieldTitleInput,
} from "./styled";

import { Header } from "./components/Header";

export const PageCreateGroup = () => {
  const [name, setName] = useState("");

  return (
    <Body>
      <Header />
      <ContentWrapper>
        <PageContainer>
          <Container>
            <TextWrapper>
              <StyledTitle variant="headline6">Criar grupo</StyledTitle>
              <StyledSubtitle variant="headline8">
                Informações básicas
              </StyledSubtitle>
              <StyledDescription variant="body3">
                Adicione o nome do grupo.
              </StyledDescription>
            </TextWrapper>
            <FormWrapper>
              <StyledFormTitle variant="headline8">Nome</StyledFormTitle>
              <StyledFormDescription variant="body4">
                Dê um nome ao grupo, este será o principal texto exibido nas
                listas e nos destaques.
              </StyledFormDescription>
              <FieldTitleInput
                label="Nome do grupo"
                onChange={(e) => setName(e.target.value)}
              />
            </FormWrapper>
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer disableForward={!!!name.length} name={name} />
    </Body>
  );
};
