import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  padding: 10px 36px 16px 36px;
  min-width: 400px;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`;

export const StyledSubtitle = styled(Typography)`
  color: var(--color-neutral-40);
`;

export const HeaderTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  padding-top: 15px;
`;

export const BoldText = styled.span`
  weight: 700;
`;
