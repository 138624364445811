import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 24px;
  gap: 24px;
  border-bottom: 1px solid var(--color-neutral-90);
  -webkit-box-pack: justify;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: white;
  align-items: center;
`;
