import { gql } from "@apollo/client";

export const GET_ECONOMIC_GROUP_COMPANIES = gql`
  query GetEconomicGroupCompanies {
    getEconomicGroupCompanies {
      economicGroupId
      mainCompany {
        id
        economicGroupId
        name
        legalName
        registrationNumber
        createdAt
        address {
          street
          zipCode
          complement
          city
          district
          number
          state
        }
      }
      economicGroupCompanies {
        id
        name
        legalName
        registrationNumber
        createdAt
        address {
          street
          zipCode
          complement
          city
          district
          number
          state
        }
      }
    }
  }
`;
