import { Dispatch, SetStateAction } from "react";
import { Department } from "@departments/types";

import {
  Container,
  FieldSpacing,
  FormWrapper,
  StyledDescription,
  StyledFormDescription,
  StyledFormSubtitle,
  StyledFormTitle,
  StyledSubtitle,
  StyledSubtitleContainer,
  StyledTitle,
  StyledTypography,
  TextFieldInput,
  TextWrapper,
} from "./styled";

interface BasicInformationProps {
  title: string;
  department?: Department;
  setDepartment: Dispatch<SetStateAction<Department>>;
}

export const BasicInformation = ({
  title,
  department,
  setDepartment,
}: BasicInformationProps) => {
  const MAX_DESCRIPTION_LENGTH = 600;
  const name = department?.name;
  const description = department?.description;
  const externalId = department?.externalId;

  return (
    <div>
      <StyledTitle variant="headline6">{title}</StyledTitle>
      <Container>
        <TextWrapper>
          <StyledSubtitle variant="headline8">
            Informações básicas
          </StyledSubtitle>
          <StyledDescription variant="body3">
            Adicione nome, descrição e outras informações básicas importantes do
            departamento.
          </StyledDescription>
        </TextWrapper>

        <FormWrapper>
          <StyledFormTitle variant="headline8">
            Nome e descrição
          </StyledFormTitle>
          <StyledFormDescription variant="body4">
            Dê um nome ao departamento, este será o principal texto exibido nas
            listas e nos destaques. Na descrição você pode descrever um resumo e
            qual o objetivo do departamento
          </StyledFormDescription>

          <StyledFormSubtitle variant="body3">
            * campo obrigatório
          </StyledFormSubtitle>

          <TextFieldInput
            label="Nome do departamento *"
            value={name || ""}
            onChange={(e) =>
              setDepartment((state) => ({ ...state, name: e.target.value }))
            }
          />
          <FieldSpacing />
          <TextFieldInput
            label="Descrição do departamento"
            value={description || ""}
            onChange={(e) =>
              setDepartment((state) => ({
                ...state,
                description: e.target.value,
              }))
            }
            inputProps={{ maxLength: MAX_DESCRIPTION_LENGTH }}
            multiline
            rows={4}
          />

          <StyledSubtitleContainer>
            <StyledTypography variant="caption">
              {`Máximo ${MAX_DESCRIPTION_LENGTH} caracteres`}
            </StyledTypography>
            <StyledTypography variant="caption">
              {`${description?.length ?? 0}/${MAX_DESCRIPTION_LENGTH}`}
            </StyledTypography>
          </StyledSubtitleContainer>
        </FormWrapper>
      </Container>
      <Container isReferenceCode={true}>
        <FormWrapper>
          <StyledFormTitle variant="headline8">
            Código do departamento
          </StyledFormTitle>
          <StyledFormDescription variant="body4">
            Defina um código para integração com outra plataforma ou gestão
            interna dos seus departamentos.
          </StyledFormDescription>

          <TextFieldInput
            label="Código do departamento"
            value={externalId || ""}
            onChange={(e) =>
              setDepartment((state) => ({
                ...state,
                externalId: e.target.value,
              }))
            }
          />
        </FormWrapper>
      </Container>
    </div>
  );
};
