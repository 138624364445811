import { Breadcrumbs } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin: 40px 0;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

export const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const OptionSpan = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error.dark1};
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;

  @media screen and (min-width: 768px) {
    gap: 0;
    flex-direction: row;
  }
`;

export const HeaderWrapper = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BoldText = styled.span`
  font-weight: 700;
`;
