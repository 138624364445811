import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledDescription = styled(Typography)`
  color: var(--color-neutral-dark5);

  @media screen and (min-width: 768px) {
    flex: 1;
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: ${({ theme }) => theme.spacings.xs};

  @media screen and (min-width: 768px) {
    flex: 3;
  }
`;
