import { gql } from "@apollo/client";

export const CREATE_API_KEY = gql`
  mutation CreateApiKey($input: CreateApiKeyInput!) {
    createApiKey(input: $input) {
      alias
      createdAt
      id
      name
      obfuscatedKey
      plainTextKey
    }
  }
`;
