import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { Container, TagSkeleton, TagStyled } from "./styles";

type Props = {
  totalCount: number;
  loading: boolean;
};

export const TableHeader = ({ totalCount, loading }: Props) => {
  return (
    <Container>
      <Typography variant="headline8">Pessoas neste cargo</Typography>
      {loading && <TagSkeleton variant="rounded" />}
      {!loading && (
        <TagStyled disabled variant="gray">
          {`${totalCount} pessoas`}
        </TagStyled>
      )}
    </Container>
  );
};
