import { gql } from "@apollo/client";

export const EMPLOYEES_TRANSFER_LIST_QUERY = gql`
  query EmployeesTransferListQuery($input: GetEmployeesV2Input) {
    employees(input: $input) {
      records {
        id
        name
        email
        groups {
          id
          name
        }
      }
      recordsMetadata {
        totalCount
        totalPages
        currentPage
        nextPage
        prevPage
      }
    }
  }
`;
