import styled from "styled-components";

export const Container = styled.div`
  gap: 12px;
  display: grid;
  grid-auto-flow: column;
  overflow-x: scroll;
  overflow-y: hidden;
  min-height: 55px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 6px;
    padding-bottom: 10px;
    max-height: 520px;
  }
`;
