import { Loader } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

import { Typography } from "../typography";

type TransferListEmptyStateProps = {
  empty?: boolean;
  loading?: boolean;
};

export const TransferListEmptyState = ({
  empty,
  loading,
}: TransferListEmptyStateProps) => {
  if (empty) return <>Nenhum colaborador encontrado</>;
  if (loading)
    return (
      <LoadingWrapper>
        <Typography color="neutral.50" variant="body3">
          Carregando colaboradores...
        </Typography>
        <Loader size="small" variant="primary" />
      </LoadingWrapper>
    );
  return null;
};

export const LoadingWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
`;
