import { useNavigate } from "react-router-dom";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes/index";

import {
  BackButton,
  CancelButton,
  Container,
  ContinueButton,
  Wrapper,
} from "./styled";

type FooterProps = {
  disableForward: boolean;
  activeStep: number;
  setActiveStep: (activeStep: number) => void;
  createSection: () => void;
  loading: boolean;
};

export const Footer = ({
  disableForward,
  activeStep,
  setActiveStep,
  createSection,
  loading,
}: FooterProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      <CancelButton
        variant="secondary"
        onClick={() => navigate(routes.pageSectionsFields)}
      >
        Cancelar
      </CancelButton>
      <Wrapper>
        {activeStep !== 0 && (
          <BackButton
            size="large"
            variant="secondary"
            onClick={() => setActiveStep(activeStep - 1)}
          >
            <Icons name="IconArrowLeft" />
            Voltar
          </BackButton>
        )}
        <ContinueButton
          size="large"
          variant="primary"
          disabled={disableForward}
          loading={loading}
          onClick={() => {
            activeStep === 0 ? setActiveStep(activeStep + 1) : createSection();
          }}
        >
          {activeStep === 0 && (
            <>
              Continuar <Icons name="IconArrowRight" />
            </>
          )}
          {activeStep === 1 && (
            <>
              Concluir <Icons name="IconCheck" fill="transparent" />
            </>
          )}
        </ContinueButton>
      </Wrapper>
    </Container>
  );
};
