import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Icons,
  LinkButton,
  Stepper,
} from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes";

import { Container, StyledBreadcrumbs, Wrapper } from "./styled";

type HeaderProps = {
  activeStep: number;
  setActiveStep: (activeStep: number) => void;
};

export const Header = ({ activeStep, setActiveStep }: HeaderProps) => {
  const navigate = useNavigate();

  const steps = ["Informações básicas", "Campos"];

  return (
    <Container>
      <Wrapper>
        <IconButton
          variant={"line"}
          size={"small"}
          icon="IconArrowLeft"
          onClick={() => navigate(routes.pageSectionsFields)}
        />
        <StyledBreadcrumbs
          separator={<Icons name="IconChevronRight" fill="transparent" />}
          breadcrumbs={[
            <LinkButton
              variant="secondary"
              onClick={() =>
                navigate(routes.pageInitial, { state: { tab: 1 } })
              }
            >
              Gerenciar empresa
            </LinkButton>,
            <LinkButton
              variant="secondary"
              onClick={() => navigate(routes.pageSectionsFields)}
            >
              Seções e campos
            </LinkButton>,
            <LinkButton variant="secondary">Criar seção</LinkButton>,
          ]}
        />
      </Wrapper>
      <Stepper
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        disableClick={activeStep < 1}
      />
    </Container>
  );
};
