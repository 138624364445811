import { PropsWithChildren } from "react";
import {
  Typography as BaseTypography,
  TypographyProps as BaseTypographyProps,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  IColors,
  IColorsTheme,
} from "@flash-tecnologia/hros-web-ui-v2/dist/types/themeGlobalTokens/colors";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

type PrimaryColor = {
  color: keyof Pick<IColorsTheme, "primary">;
};

type OtherColors = {
  color: `${keyof Omit<IColorsTheme, "primary">}.${keyof IColors}`;
};

type TypographyProps = BaseTypographyProps &
  SpaceProps &
  (PrimaryColor | OtherColors);

const StyledTypography = styled(BaseTypography)<
  TypographyProps & {
    type: string;
    level?: string;
  }
>`
  ${space}
  color: ${(props) =>
    props.color === "primary"
      ? props.theme.colors[props.type as keyof IColorsTheme]
      : (props.theme.colors as any)[props.type][props.level as any]};
`;

export const Typography = ({
  ...props
}: PropsWithChildren<TypographyProps>) => {
  const [type, level] = props.color.split(".");
  return <StyledTypography {...props} type={type} level={level} />;
};
