import { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { dispatchToast } from "@utils";
import { GET_API_KEYS } from "@/api/queries/apikeys";
import { GetApiKeysResponse } from "../types";

export const useGetApiKeys = () => {
  const [apiKeys, setApiKeys] = useState<GetApiKeysResponse[]>([]);
  const { loading, refetch } = useQuery(GET_API_KEYS, {
    onCompleted: (data) => {
      setApiKeys(data?.getApiKeys);
    },
    onError: () => {
      dispatchToast({
        type: "error",
        content: "Sentimos muito, ocorreu um erro ao buscar as chaves de API.",
      });
    },
    fetchPolicy: "network-only",
  });

  return {
    apiKeys,
    loading,
    refetch,
  };
};
