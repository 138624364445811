import { Employee } from "@departments/types";
import { LoadingWrapper } from "./styled";
import { Typography, Loader } from "@flash-tecnologia/hros-web-ui-v2";

interface TransferListEmptyStateProps {
  data: Employee[];
  loading: boolean;
  searching?: boolean;
}

export const TransferListEmptyState = ({
  data,
  loading,
  searching,
}: TransferListEmptyStateProps) => {
  return (
    <>
      {!data?.length && !loading && !searching ? (
        <>Nenhum colaborador encontrado</>
      ) : (
        <LoadingWrapper>
          <Typography variant="body3">Carregando colaboradores...</Typography>
          <Loader size="small" variant="primary" />
        </LoadingWrapper>
      )}
    </>
  );
};
