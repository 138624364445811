import { useState } from "react";
import { GenericProfilePicture } from "@flash-tecnologia/hros-web-ui-v2";
import { useUpdateCompany } from "@companies/hooks";
import { AddressFields } from "@Components/AddressFields";

import { CompanyRegistrationData } from "../CompanyRegistrationData";
import { ModalDeleteProfilePicture } from "../ModalDeleteProfilePicture";
import { ModalEditProfilePicture } from "../ModalEditProfilePicture";

import {
  AccordionDescription,
  AccordionsWrapper,
  AccordtionTitle,
  Container,
  IconButtonStyled,
  StyledAccordion,
  StyledDescription,
  StyledSubtitle,
  TextWrapper,
} from "./styled";

export const CompanyData = (data: any) => {
  const { __typename, active, status, ...companyData } = data || {};
  const { name = "", logo = {}, address = {} } = companyData || {};
  delete address?.__typename;
  delete logo?.__typename;

  const [editingAddress, setEditingAddress] = useState<{
    isEditing: boolean;
    address: any;
  }>({
    isEditing: false,
    address: null,
  });

  const [editingRegistrationData, setEditingRegistrationData] = useState<{
    isEditing: boolean;
    registrationData: any;
  }>({
    isEditing: false,
    registrationData: null,
  });

  const [modalEditProfilePicture, setModalEditProfilePicture] = useState(false);
  const [modalDeleteProfilePicture, setModalDeleteProfilePicture] =
    useState(false);

  const { updateCompany, loading } = useUpdateCompany();

  const registrationDataActions = [
    {
      children: (
        <IconButtonStyled
          size="small"
          variant="line"
          editing={editingRegistrationData.isEditing}
          icon={editingRegistrationData.isEditing ? "IconCheck" : "IconPencil"}
          disabled={loading}
          onClick={(e) => {
            const {
              logo,
              segment,
              cityRegistration,
              stateRegistration,
              ...body
            } = companyData;

            if (
              editingRegistrationData.isEditing &&
              editingRegistrationData.registrationData
            ) {
              const {
                registrationNumber = null,
                name,
                phone,
                legalName,
                segment,
                cityRegistration,
                stateRegistration,
                patRegistrationNumber,
              } = editingRegistrationData.registrationData;

              updateCompany({
                data: {
                  ...body,
                  registrationNumber,
                  name,
                  phone,
                  legalName,
                  segment,
                  cityRegistration,
                  stateRegistration,
                  patRegistrationNumber,
                },
              });
            } else {
              setEditingRegistrationData({
                registrationData: companyData,
                isEditing: true,
              });
            }

            e.stopPropagation();
          }}
        />
      ),
      onClick: () => {},
    },
  ];

  const addressActions = [
    {
      children: (
        <IconButtonStyled
          size="small"
          variant="line"
          editing={editingAddress.isEditing}
          icon={editingAddress.isEditing ? "IconCheck" : "IconPencil"}
          disabled={loading}
          onClick={(e) => {
            const { logo, ...body } = companyData;

            if (editingAddress.isEditing && editingAddress.address) {
              updateCompany({
                data: {
                  ...body,
                  address: {
                    street: editingAddress.address.street!,
                    number: editingAddress.address.number,
                    complement: editingAddress.address.complement,
                    district: editingAddress.address.district,
                    city: editingAddress.address.city,
                    state: editingAddress.address.state,
                    zipCode: editingAddress.address.zipCode,
                  },
                },
              });
            } else {
              setEditingAddress({
                address: address,
                isEditing: true,
              });
            }

            e.stopPropagation();
          }}
        />
      ),
      onClick: () => {},
    },
  ];

  if (editingAddress.isEditing)
    addressActions.push({
      children: (
        <IconButtonStyled
          size="small"
          variant="line"
          icon="IconX"
          onClick={(e) => {
            setEditingAddress({
              address: address,
              isEditing: !editingAddress.isEditing,
            });
            e.stopPropagation();
          }}
        />
      ),
      onClick: () => {},
    });

  if (editingRegistrationData.isEditing)
    registrationDataActions.push({
      children: (
        <IconButtonStyled
          size="small"
          variant="line"
          icon="IconX"
          onClick={(e) => {
            setEditingRegistrationData({
              registrationData: companyData,
              isEditing: !editingRegistrationData.isEditing,
            });
            e.stopPropagation();
          }}
        />
      ),
      onClick: () => {},
    });

  return (
    <>
      <Container>
        <TextWrapper>
          <StyledSubtitle variant="headline8">
            Informações da empresa
          </StyledSubtitle>
          <StyledDescription variant="body3">
            Consulte e gerencie as informações cadastrais da empresa e
            personalize a aparência da plataforma.
          </StyledDescription>
        </TextWrapper>

        <AccordionsWrapper>
          <StyledAccordion
            variant="default"
            title="Logo da empresa"
            description="Personalize a aparência da plataforma com o logotipo da sua organização."
          >
            <AccordtionTitle variant="headline8">
              Logo da empresa
            </AccordtionTitle>
            <AccordionDescription variant="body3">
              Adicione o logotipo da sua empresa.
            </AccordionDescription>
            <GenericProfilePicture
              name={name}
              size={120}
              source={logo?.source}
              enableEdit
              editCallback={() => setModalEditProfilePicture(true)}
              deleteCallback={() => setModalDeleteProfilePicture(true)}
            />
          </StyledAccordion>

          <StyledAccordion
            variant="default"
            title="Dados cadastrais"
            description="Consulte e gerencie as informações cadastrais da empresa."
            actions={registrationDataActions}
          >
            <CompanyRegistrationData
              registrationData={companyData}
              editing={editingRegistrationData}
              loading={loading}
              setRegistrationDataValue={(newValue) => {
                setEditingRegistrationData({
                  ...editingRegistrationData,
                  registrationData: newValue,
                });
              }}
              registrationDataValue={editingRegistrationData?.registrationData}
            />
          </StyledAccordion>

          <StyledAccordion
            variant="default"
            title="Endereço"
            description="Visualize seus dados de endereço da empresa."
            actions={addressActions}
          >
            <AddressFields
              address={address}
              editing={editingAddress.isEditing}
              loading={loading}
              setAddressValue={(newValue) => {
                setEditingAddress({ ...editingAddress, address: newValue });
              }}
              addressValue={editingAddress.address}
            />
          </StyledAccordion>
        </AccordionsWrapper>
      </Container>
      <ModalEditProfilePicture
        isOpen={modalEditProfilePicture}
        onClose={() => setModalEditProfilePicture(false)}
        loading={loading}
        onSubmit={(logo) => {
          updateCompany({
            data: {
              ...companyData,
              logo,
            },
          });
        }}
      />
      <ModalDeleteProfilePicture
        isOpen={modalDeleteProfilePicture}
        onClose={() => setModalDeleteProfilePicture(false)}
        name={name}
        source={logo?.source}
        loading={loading}
        onSubmit={async () => {
          try {
            await updateCompany({
              data: {
                ...companyData,
                logo: null,
              },
            });

            setModalDeleteProfilePicture(false);
          } catch (err) {
            setModalDeleteProfilePicture(false);
          }
        }}
      />
    </>
  );
};
