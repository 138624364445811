import gql from "graphql-tag";

export const GET_COMPANY = gql`
  query GetCompany($companyId: ID) {
    getCompany(companyId: $companyId) {
      _id
      name
      legalName
      active
      status
      registrationNumber
      stateRegistration
      cityRegistration
      phone
      segment
      patRegistrationNumber
      address {
        street
        number
        complement
        district
        city
        state
        zipCode
      }
      logo {
        path
        source
      }
    }
  }
`;
