import { useLazyQuery } from "@apollo/client";
import {
  EMPLOYEES_TRANSFER_LIST_QUERY,
  EmployeesTransferListQuery,
  EmployeesTransferListQueryVariables,
  EmployeesTransferListQuery_employees_records,
} from "@/api/queries/employees";
import { useCallback, useEffect, useState } from "react";
import { StatusEnum } from "@/common/StatusEnum";
import { ErrorBoundary, dispatchToast } from "@/utils";

type Employee = EmployeesTransferListQuery_employees_records & {
  checked: boolean;
  hidden: boolean;
  _id: string;
};

export const useEmployees = (groupEmployees: any) => {
  const [employees, setEmployees] = useState<Employee[]>([]);

  const [fetch, { loading, data }] = useLazyQuery<
    EmployeesTransferListQuery,
    EmployeesTransferListQueryVariables
  >(EMPLOYEES_TRANSFER_LIST_QUERY, {
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content: "Ocorreu um erro ao buscar os colaboradores.",
      });
    },
  });

  const fetchEmployees = useCallback(
    async (params: {
      companyId: string;
      pageNumber: number;
      pageSize: number;
      status?: StatusEnum;
      search: string;
      isFetchingMore?: boolean;
    }) => {
      const { data } = await fetch({
        variables: {
          input: {
            companyId: params.companyId,
            page: params.pageNumber,
            limit: params.pageSize,
            sortBy: "name",
            order: "asc",
            ...(params.status && { status: params.status }),
            ...(params.search && { query: params.search }),
          },
        },
      });

      const filteredEmployees =
        data?.employees?.records?.filter(
          ({ id }: any) =>
            !groupEmployees?.some(
              ({ id: groupEmployeeId }: any) => id === groupEmployeeId,
            ),
        ) || [];

      setEmployees((prev: any) => {
        return params.isFetchingMore
          ? [
              ...prev,
              ...filteredEmployees?.map((employee) => ({
                ...employee,
                checked: false,
                hidden: false,
                _id: employee?.id,
              })),
            ]
          : filteredEmployees?.map((employee) => ({
              ...employee,
              checked: false,
              hidden: false,
              _id: employee?.id,
            }));
      });
    },
    [],
  );

  useEffect(() => {
    const filteredEmployees = employees.filter(
      ({ id }) =>
        !groupEmployees?.some(
          ({ id: groupEmployeeId }: { id: string }) => id === groupEmployeeId,
        ),
    );

    setEmployees(filteredEmployees);
  }, [groupEmployees]);

  function updateEmployees(employees: Employee[]) {
    setEmployees(employees);
  }

  function checkEmployees(data: any[], allChecked: boolean) {
    setEmployees((prev) => {
      return data?.map((row, index) => ({
        ...prev[index],
        checked: allChecked !== undefined ? allChecked : row?.checked,
      }));
    });
  }

  return {
    isEmployeesLoading: loading,
    employees,
    totalEmployees: data?.employees?.recordsMetadata?.totalCount,
    fetchEmployees,
    checkEmployees,
    updateEmployees,
  };
};
