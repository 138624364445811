import { useMutation } from "@apollo/client";
import { dispatchToast } from "@utils";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { useNavigate } from "react-router-dom";
import { REMOVE_EMPLOYEES_DEPARTMENT } from "src/api";
import { routes } from "src/routes";

type HookResult = {
  removeEmployeesDepartment: (params: RemoveEmployeesDepartmentParams) => void;
  loading: boolean;
};

type RemoveEmployeesDepartmentParams = {
  companyId: string;
  departmentId: string;
  employeeIds: string[];
};

type RemoveEmployeesDepartmentInput = {
  input: RemoveEmployeesDepartmentParams;
};

export const useRemoveEmployeesDepartment = (): HookResult => {
  const navigate = useNavigate();

  const [removeEmployeesDepartmentMutation, { loading }] = useMutation<
    void,
    RemoveEmployeesDepartmentInput
  >(REMOVE_EMPLOYEES_DEPARTMENT, {
    onCompleted: () => {
      dispatchToast({
        type: "success",
        content: "Pessoa desvinculada com sucesso!",
      });
      navigate(routes.departments);
    },
    onError: (error) => {
      ErrorBoundary.captureException(error);
      dispatchToast({
        type: "error",
        content:
          "Ocorreu um erro ao desvicular a pessoa. Por favor, tente novamente mais tarde.",
      });
    },
    refetchQueries: ["GetDepartmentWithMembers"],
  });

  const removeEmployeesDepartment = ({
    companyId,
    departmentId,
    employeeIds,
  }: RemoveEmployeesDepartmentParams): void => {
    removeEmployeesDepartmentMutation({
      variables: {
        input: {
          companyId,
          departmentId,
          employeeIds,
        },
      },
    });
  };

  return {
    removeEmployeesDepartment,
    loading,
  };
};
