import {
  createContext,
  type PropsWithChildren,
  useContext,
  useReducer,
} from "react";

type Action =
  | { type: "set_search_value"; payload: string }
  | { type: "clear_search_value" };
type Dispatch = (action: Action) => void;
type State = { searchValue: string };

const INITIAL_STATE: State = {
  searchValue: "",
};

const DepartmentsTableContext = createContext<{
  state: State;
  dispatch: Dispatch;
} | null>(null);

const departmentsReducer = (
  state: State,
  action: Action,
): typeof INITIAL_STATE => {
  switch (action.type) {
    case "set_search_value": {
      return {
        ...state,
        searchValue: action.payload,
      };
    }
    case "clear_search_value": {
      return {
        ...state,
        searchValue: "",
      };
    }
  }
};

export const DepartmentsTableProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(departmentsReducer, INITIAL_STATE);

  const value = { state, dispatch };

  return (
    <DepartmentsTableContext.Provider value={value}>
      {children}
    </DepartmentsTableContext.Provider>
  );
};

export const useDepartmentsTableContext = () => {
  const context = useContext(DepartmentsTableContext);
  if (!context) {
    throw new Error(
      "useDepartmentsTableContext must be used within a DepartmentsTableContext",
    );
  }
  return context;
};
