import { Container, Description, Title } from "./styled";

export const Header = () => {
  return (
    <Container>
      <Title variant="headline6">Seções e campos</Title>
      <Description variant="body4">
        Uma seção é um <strong>agrupamento de campos</strong> e pode representar
        um documento (ex.: RG, CPF) ou um <strong>formulário</strong> (ex.:
        lista de equipamentos desejados, lista de EPIs).{" "}
        <p>
          <strong>Seções padrão</strong> já existentes não podem ser editadas,
          porém é possível criar <strong>seções personalizadas</strong> e
          incluir campos diversos. As seções e campos são utilizadas em todo o
          sistema, como em cadastros, admissão, etc -{" "}
          <strong>alterar algo aqui irá refletir em diversos lugares.</strong>
        </p>
      </Description>
    </Container>
  );
};
