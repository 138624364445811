import { PropsWithChildren, ReactNode } from "react";

import { Wrapper } from "./styles";

type PageWrapperProps = {
  header?: ReactNode;
  footer?: ReactNode;
};

export const PageWrapper = ({
  header,
  children,
  footer,
}: PropsWithChildren<PageWrapperProps>) => {
  return (
    <Wrapper>
      {header}
      {children}
      {footer}
    </Wrapper>
  );
};
