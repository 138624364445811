import { segmentEventTrack } from "../shared/segment";

export const setEventTracking = (
  name: string,
  params?: Record<string, string>
) => {
  segmentEventTrack({
    name,
    params,
    module: "company-management",
    businessUnit: "platform",
  });
};
