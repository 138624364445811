import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  EmptyState,
  Icons,
  LinkButton,
  PageContainer,
} from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "src/routes";

import { Header } from "./components/Header";
import { Skeleton } from "./components/Skeleton";
import { Table } from "./components/Table";
import { Tabs } from "./components/Tabs";
import { useGetGroups } from "./hooks/useGetGroups";
import { Content, StyledBreadcrumbs } from "./styled";

export const PageGroups = () => {
  const { focusedGroup } = useParams();
  const [selectedGroupId, setSelectedGroupId] = useState<string>(
    focusedGroup ? focusedGroup : "",
  );
  const navigate = useNavigate();

  const { groups, loading } = useGetGroups();

  const selectedGroup = useMemo(
    () => groups?.find((g) => g.id === selectedGroupId) || groups?.[0],
    [groups, selectedGroupId],
  );

  return (
    <PageContainer style={{ height: "100%" }}>
      <StyledBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={[
          <LinkButton
            variant="secondary"
            onClick={() => navigate(routes.pageInitial, { state: { tab: 1 } })}
          >
            Gerenciar empresa
          </LinkButton>,
          <LinkButton variant="secondary">Grupos de pessoas</LinkButton>,
        ]}
      />
      <Header />
      {loading && <Skeleton />}
      {!loading && groups?.length > 0 && (
        <Content>
          <Tabs
            tabs={groups}
            selectedTab={selectedGroup}
            setSelectedTab={(id) => setSelectedGroupId(id)}
          />
          <Table
            employees={selectedGroup?.employees}
            loading={loading}
            groupId={selectedGroup?.id}
            groupName={selectedGroup?.name}
          />
        </Content>
      )}
      {!loading && !groups?.length && (
        <EmptyState
          buttonText={"Criar novo grupo"}
          description={"Você ainda não criou nenhum grupo."}
          buttonProps={{
            size: "medium",
            onClick: () => navigate(routes.pageCreateGroup),
          }}
        />
      )}
    </PageContainer>
  );
};
