import { PropsWithChildren } from "react";
import styled from "styled-components";
import {
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  height,
  HeightProps,
  minWidth,
  MinWidthProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  width,
  WidthProps,
} from "styled-system";

const StyledBox = styled.div`
  ${space}
  ${display}
  ${position}
  ${height}
  ${width}
  ${minWidth}
  ${flexbox}
`;

type BoxProps = PositionProps &
  SpaceProps &
  WidthProps &
  MinWidthProps &
  HeightProps &
  DisplayProps &
  FlexboxProps;

export const Box = ({ children, ...props }: PropsWithChildren<BoxProps>) => {
  return <StyledBox {...props}>{children}</StyledBox>;
};
