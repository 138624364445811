import { FlagsProvider } from "@flash-tecnologia/feature-flags";
import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";
import { ApolloProvider } from "@apollo/react-hooks";

import "../src/i18n";

import { useSession } from "./common/user";
import { env } from "./lib/env";
import { makeApolloClient } from "./utils/apollo";
import { APP_NAME } from "./utils/constants";
import { trpc, trpcClient, queryClient } from "./api/client";
import AppRouter from "./routes";

const apolloClient = makeApolloClient();

export default function Root() {
  const { userId, companyId, economicGroupId } = useSession();

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <FlagsProvider
        appName={APP_NAME}
        url={env.UNLEASH_BENEFITS_URL}
        token={env.UNLEASH_BENEFITS_PROXY_KEY}
        refreshIntervalSeconds={1800}
        initialContext={{
          userId,
          properties: {
            economicGroupId,
            companyId,
            employeeId: userId,
          },
        }}
      >
        <ApolloProvider client={apolloClient}>
          <ThemeProvider>
            <AppRouter />
          </ThemeProvider>
        </ApolloProvider>
      </FlagsProvider>
    </trpc.Provider>
  );
}
