import styled from "styled-components";

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Table = styled(Skeleton)`
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
`;
