import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.div`
  border: 1px solid var(--color-neutral-80);
  border-radius: 12px;
  width: 100%;
  max-height: fit-content;
  height: fit-content;
`;

export const Header = styled.div`
  border-bottom: 1px solid var(--color-neutral-80);
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Content = styled.div`
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled(Typography)`
  color: var(--color-neutral-30);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const StyledTag = styled(Tag)`
  margin: auto 0;
  min-width: max-content;
  max-height: 26px;
`;

export const ContentHeaderWrapper = styled.div``;

export const StyledSubtitle = styled(Typography)`
  color: var(--color-neutral-40);
`;
