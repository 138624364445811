import { ISelectedCompany } from "@features/economicGroup/types";
import { switchLegacyStorageData } from "@flash-tecnologia/hros-web-utility";

export const switchSelectedCompany = (company: ISelectedCompany) => {
  switchLegacyStorageData(company.registrationNumber);
  dispatchEvent(
    new CustomEvent("header_company_selected", {
      detail: {
        id: company.id,
        name: company.name,
        registrationNumber: company.registrationNumber,
      },
    }),
  );
};
