import { Box } from "@/components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.neutral[90]};
`;

export const TransferListWrapper = styled(Box)`
  height: calc(100% - 122px);
`;

export const TransferListCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-width: 870px;
  width: 100%;
  min-height: 800px;

  border: 1px solid ${(props) => props.theme.colors.neutral[90]};
  border-radius: 12px;

  padding: 40px;
`;

export const EmployeeName = styled(Typography)`
  text-transform: capitalize;
`;

export const EmailField = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
`;
