import { GetRoleById_role } from "@/api/queries/roles/__generated__/GetRoleById";
import { GetRoleWithMembers_role_members } from "@/api/queries/roles/__generated__/GetRoleWithMembers";
import { BaseDataType } from "@flash-tecnologia/hros-web-ui-v2/dist/components/TransferList/types";

export type Role = GetRoleById_role;

export enum RoleFormStep {
  FIRST = 0,
  SECOND = 1,
}

export enum CreateRoleStepName {
  FIRST = "Informações básicas",
  SECOND = "Selecione pela lista",
}

export type Employee = Omit<GetRoleWithMembers_role_members, "__typename"> & {
  checked?: boolean;
  hidden?: boolean;
};

export type DataTypeEmployee = BaseDataType & {
  name: string;
  groups?: {
    name: string;
    id: string;
  }[];
  email?: string | null;
  documentNumber?: string | null;
  phoneNumber?: string | null;
};

export * as roleErrors from "./errors";
