import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";
import {
  EconomicGroupCompanyCard,
  TOption,
} from "@features/economicGroup/types";
import { switchSelectedCompany } from "@utils/companyChangeHandler";
import { routes } from "src/routes";

import { Option } from "./styled";

interface ICompanyOptionsProps {
  company: EconomicGroupCompanyCard;
}

export const CompanyOptions = ({ company }: ICompanyOptionsProps) => {
  const navigate = useNavigate();
  const onViewClick = async () => {
    switchSelectedCompany(company);
    navigate(routes.pageCompany);
  };

  const options: TOption[] = useMemo(() => {
    const opt: TOption[] = [
      {
        key: "view",
        children: (
          <Option onClick={() => onViewClick()}>
            <Icons name="IconBuilding" fill="transparent" />
            Ver dados da empresa
          </Option>
        ),
      },
    ];
    return opt;
  }, []);

  return (
    <Menu
      type={"select"}
      options={options}
      disableAutoFocusItem={true}
      anchorOrigin={{ vertical: 0, horizontal: -210 }}
    >
      <IconButton variant="line" size="small" icon="IconDotsVertical" />
    </Menu>
  );
};
