import { GetDepartmentWithMembers_getDepartment_members } from "src/api/queries/__generated__/GetDepartmentWithMembers";

export type Department = {
  id: string;
  name: string;
  description?: string;
  externalId?: string;
};

export type DepartmentTabCard = {
  companyId: string;
  departmentId: string;
  name: string;
  description?: string;
};

export type DepartmentCard = {
  _id: string;
  key: string;
  title: string;
};

export type DepartmentType = {
  _id: string;
  name: string;
  description?: string;
};

export enum DepartmentFormStep {
  FIRST = 0,
  SECOND = 1,
}

export enum CreateDepartmentStepName {
  FIRST = "Informações básicas",
  SECOND = "Selecione pela lista",
}

export type Employee = Omit<
  GetDepartmentWithMembers_getDepartment_members,
  "__typename"
> & {
  checked?: boolean;
  hidden?: boolean;
};

export * as departmentErrors from "./errors";
