import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";
import { CompanyFieldInput } from "@grapql-schema";
import { useMutation } from "@apollo/client";
import dispatchToast from "@Utils/dispatchToast";
import { ErrorBoundary } from "@utils/ErrorBoundary";
import { CreateSection } from "src/api/mutations/__generated__/CreateSection";
import { CREATE_SECTION } from "src/api/mutations/createSection";
import { routes } from "src/routes";

import { FirstStepContent } from "./components/FirstStepContent";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { SecondStepContent } from "./components/SecondStepContent";
import { Body, Container, ContentWrapper } from "./styled";

export const PageCreateSection = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const [preConfiguredFields, setPreConfiguredFields] =
    useState<CompanyFieldInput[]>();

  const handleFooterDisableForward = useMemo(() => {
    if (activeStep === 0) {
      return !!!name.length;
    } else return false;
  }, [name, activeStep]);

  const [createSection, { loading }] = useMutation<CreateSection>(
    CREATE_SECTION,
    {
      onCompleted: () => {
        dispatchToast({
          type: "success",
          content: "Seção criada com sucesso!",
        });
        navigate(routes.pageSectionsFields);
      },
      onError: (error) => {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content:
            "Ocorreu um erro ao criar a seção. Por favor, tente novamente mais tarde.",
        });
      },
      refetchQueries: ["GetCompanySectionsFields"],
      awaitRefetchQueries: true,
    },
  );

  return (
    <Body>
      <Header activeStep={activeStep} setActiveStep={setActiveStep} />
      <ContentWrapper>
        <PageContainer>
          <Container>
            {activeStep === 0 && (
              <FirstStepContent setName={setName} name={name} />
            )}
            {activeStep === 1 && (
              <SecondStepContent
                preConfiguredFields={preConfiguredFields as any}
                setPreConfiguredFields={setPreConfiguredFields}
              />
            )}
          </Container>
        </PageContainer>
      </ContentWrapper>
      <Footer
        disableForward={handleFooterDisableForward}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        loading={loading}
        createSection={() => {
          createSection({
            variables: {
              name: name,

              fields: preConfiguredFields?.map((field, index) => {
                return {
                  name: field?.name,
                  type: field?.type,
                  component: field?.component,
                  options: field?.options,
                  sensitive: field?.sensitive,
                  order: index,
                };
              }),
            },
          });
        }}
      />
    </Body>
  );
};
