import { Card, StyledLock, Title, Wrapper } from "./styled";

export interface SectionCardInterface {
  id: string;
  isSelected: boolean;
  isDefault?: boolean;
  setSelectedSection: (_id: string) => void;
  title: string;
}

export const SectionCard = ({
  id,
  isSelected,
  title,
  isDefault,
  setSelectedSection,
}: SectionCardInterface) => {
  return (
    <Card key={id} selected={isSelected} onClick={() => setSelectedSection(id)}>
      <Title variant="body3" tag="span" selected={isSelected}>
        {title}
      </Title>
      {isDefault && (
        <Wrapper>
          <StyledLock name="IconLock" fill="transparent" size={24} />
        </Wrapper>
      )}
    </Card>
  );
};
