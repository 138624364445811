import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Container = styled.aside`
  gap: 12px;
  display: grid;
  grid-auto-flow: column;
  overflow-x: scroll;
  overflow-y: hidden;
  min-height: 55px;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: 6px;
    padding-bottom: 10px;
    max-height: 520px;
  }
`;

export const MenuWrapper = styled.span`
  > .menu-base-container-custom-theme:first-child {
    height: 24px;
  }
`;

export const DotsIcon = styled(Icons)<{ selected: boolean }>`
  color: ${({ theme, selected }) =>
    selected ? theme.colors.secondary[50] : theme.colors.neutral[50]};
`;

export const Title = styled(Typography)<{ selected: boolean }>`
  color: ${({ selected, theme }) =>
    selected ? theme.colors.secondary[50] : theme.colors.neutral[50]};
  font-weight: 600 !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const TabItem = styled.button<{ selected: boolean }>`
  padding: 8px 16px;
  width: 312px;
  border-radius: 8px;
  gap: 16px;

  text-align: start;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  transition: all 0.1s;

  border: 1px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.primary : theme.colors.neutral[90]};

  background: ${({ selected, theme }) =>
    selected ? theme.colors.secondary[95] : theme.colors.neutral[100]};

  &:hover {
    background: ${(props) => props.theme.colors.secondary[95]};
    border: 1px solid ${(props) => props.theme.colors.secondary[95]};

    ${Title} {
      color: ${(props) => props.theme.colors.secondary[50]};
    }

    ${DotsIcon} {
      color: ${(props) => props.theme.colors.secondary[50]};
    }
  }

  &:focus-visible {
    outline: 4px solid ${(props) => props.theme.colors.secondary[95]};
  }

  &:active {
    background: ${(props) => props.theme.colors.secondary[70]};

    ${Title} {
      color: ${(props) => props.theme.colors.neutral[100]};
    }
    ${DotsIcon} {
      color: ${(props) => props.theme.colors.neutral[100]};
    }
  }
`;

export const Option = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const OptionSpan = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error.dark1};
`;
