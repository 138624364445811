import {
  GenericProfilePicture,
  IconButton,
  Icons,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { StatusTag } from "@Components/StatusTag";

import {
  Container,
  SubtitleWrapper,
  TextsWrapper,
  TitleWrapper,
  Wrapper,
} from "./styled";

export interface CompanyCardInterface {
  name?: string | null;
  status?: string | null;
  legalName?: string | null;
  logo?: {
    source: string | null;
  } | null;
}

export const CompanyCard = (props: CompanyCardInterface) => {
  const { name = "", status = "", legalName, logo } = props || {};

  return (
    <Container>
      <Wrapper>
        <GenericProfilePicture
          name={name || ""}
          source={logo?.source || ""}
          size={66}
        />
        <TextsWrapper>
          <TitleWrapper>
            <Typography variant="headline7">{name}</Typography>
            <StatusTag status={status || ""} />
          </TitleWrapper>
          <SubtitleWrapper>
            <Icons name="IconBuildingSkyscraper" size={16} fill="transparent" />
            <Typography variant="body4">{legalName}</Typography>
          </SubtitleWrapper>
        </TextsWrapper>
      </Wrapper>
    </Container>
  );
};
