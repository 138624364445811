import { ReactNode, useCallback } from "react";
import { Menu, MenuOptionType } from "@flash-tecnologia/hros-web-ui-v2";

import { Container, DotsIcon, MenuWrapper, TabItem, Title } from "./styles";

type BaseTabItem = { key: string; tabName: string; selected?: boolean };

type TabItem<T> = T & BaseTabItem;

type SidebarTabsProps<T extends BaseTabItem> = {
  items: TabItem<T>[];
  tabMenuOptions: MenuOptionType[];
  onTabClick?: (item: TabItem<T>) => void;
  modal?: ReactNode;
};

export function arrayToTabItems<T>(
  arr: T[] = [],
  keys: {
    key: keyof T;
    tabName: keyof T;
  },
  selectedItem?: T,
): TabItem<T>[] {
  return arr.map((item) => {
    const key = typeof item[keys.key] === "string" ? item[keys.key] : "";
    const tabName =
      typeof item[keys.tabName] === "string" ? item[keys.tabName] : "";

    const tabItem: TabItem<T> = {
      ...item,
      key,
      tabName,
      selected: false,
    } as any;

    if (!selectedItem) {
      return tabItem;
    }

    const selectedItemKey =
      typeof selectedItem[keys.key] === "string" ? selectedItem[keys.key] : "";
    if (key === selectedItemKey) {
      tabItem.selected = true;
    }

    return tabItem;
  });
}

export function SidebarTabs<T extends BaseTabItem>({
  items = [],
  tabMenuOptions,
  onTabClick,
  modal,
}: SidebarTabsProps<T>) {
  const handleClick = useCallback(
    (item: TabItem<T>) => () => {
      onTabClick?.(item);
    },
    [onTabClick],
  );

  return (
    <Container>
      {items.map((item) => {
        return (
          <TabItem
            key={item.key}
            selected={item.selected as any}
            onClick={handleClick(item)}
          >
            <Title variant="body4" tag="span" selected={item.selected as any}>
              {item.tabName}
            </Title>
            <MenuWrapper>
              <Menu
                type="select"
                options={tabMenuOptions}
                disableAutoFocusItem
                anchorOrigin={{ vertical: 0, horizontal: 30 }}
                onClick={handleClick(item)}
              >
                <DotsIcon
                  onClick={handleClick(item)}
                  selected={item.selected as any}
                  name="IconDotsVertical"
                  size={24}
                />
              </Menu>
            </MenuWrapper>
          </TabItem>
        );
      })}

      {modal}
    </Container>
  );
}
