import { useState } from "react";
import { useLazyQuery } from "@apollo/client";

import { GET_ROLE_WITH_MEMBERS } from "@/api/queries/roles";
import { GetRoleWithMembers } from "@/api/queries/roles/__generated__/GetRoleWithMembers";
import { useCompany } from "@/lib";
import { dispatchToast, ErrorBoundary } from "@/utils";
import { DataTypeEmployee } from "../types";

export const useGetRoleWithMembers = () => {
  const { companyId } = useCompany();

  const [members, setMembers] = useState<DataTypeEmployee[]>([]);

  const [getRoleWithMembers, { loading }] = useLazyQuery<GetRoleWithMembers>(
    GET_ROLE_WITH_MEMBERS,
    {
      onCompleted: ({ role }) => {
        const members = role?.members?.map<DataTypeEmployee>(
          (member) =>
            ({
              _id: member?._id,
              name: member?.name,
              email: member?.email ?? "",
              hidden: false,
              checked: false,
            } as any),
        );
        setMembers(members as any);
      },
      onError: (error) => {
        ErrorBoundary.captureException(error);
        dispatchToast({
          type: "error",
          content:
            "Sentimos muito, ocorreu um erro ao buscar os membros do cargo.",
        });
      },
      fetchPolicy: "network-only",
    },
  );

  const getMembers = (roleId: string) => {
    getRoleWithMembers({
      variables: {
        companyId,
        roleId,
      },
    });
  };

  return {
    members,
    getMembers,
    loading,
  };
};
