import {
  Checkbox,
  DatePicker,
  Dropzone,
  MultiSelect,
  ObjectLiteral,
  SelectField,
  TimePicker,
} from "@flash-tecnologia/hros-web-ui-v2";
import { InputBaseProps } from "@mui/material";
import { FieldComponentTypes } from "src/common/field";

import { CheckboxLabel, CheckboxWrapper, StyledTextField } from "./styled";

export interface FieldComponentInterface {
  name?: string | null;
  sensitive?: boolean | null;
  order?: number | null;
  mask?: string | null;
  type?: string;
  component?: FieldComponentTypes;
  options?: ObjectLiteral[] | null;
  expires?: boolean | null;
  required?: boolean;
  onChange?: (value: any) => void;
  disabled?: boolean;
  value?: any;
  inputProps?: InputBaseProps["inputProps"];
  inputMode?: InputBaseProps["inputMode"];
  imaskProps?: IMask.AnyMaskedOptions;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  style?: ObjectLiteral;
}

export const FieldComponent = ({
  component,
  name,
  type,
  options,
  onChange,
  disabled,
  value,
  inputProps,
  inputMode,
  imaskProps,
  placeholder,
  error,
  helperText,
  style,
}: FieldComponentInterface) => {
  const onChangeHandler = (value: any) =>
    onChange ? onChange(value) : () => null;
  switch (component) {
    case "shortText":
    case "longText":
    case "number":
    case "link":
      return (
        <StyledTextField
          label={name}
          type={type}
          disabled={disabled}
          onChange={(e) => onChangeHandler(e?.target?.value)}
          value={value}
          inputProps={{ ...inputProps }}
          imaskProps={imaskProps}
          inputMode={inputMode}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
        />
      );
    case "datePicker":
      return (
        <DatePicker
          label={name || undefined}
          onDateChange={() => {
            return;
          }}
          disabled={disabled}
          value={value}
        />
      );
    case "timePicker":
      return (
        <TimePicker
          label={name}
          onChange={(value) => onChangeHandler(value)}
          value={value}
          disabled={disabled}
        />
      );
    case "checkbox":
      return (
        <CheckboxWrapper>
          <Checkbox disabled={disabled} value={value} onChange={onChange} />
          <CheckboxLabel variant="body3">{name}</CheckboxLabel>
        </CheckboxWrapper>
      );
    case "select":
      return (
        <SelectField
          options={options || []}
          label={name}
          onSelectChange={(_, value) => onChangeHandler(value?.value)}
          disabled={disabled}
          value={value}
          error={error}
          helperText={helperText}
        />
      );
    case "multiSelect":
      return (
        <MultiSelect
          isOptionEqualToValue={(option, value) => option.label === value.label}
          value={value}
          label={name}
          options={options || []}
          renderInput={(() => {
            return;
          }) as any}
          onSelectChange={(_, value) => {
            onChangeHandler(value);
          }}
          disabled={disabled}
          error={error}
          helperText={helperText}
          style={style}
        />
      );
    case "attachment":
      return (
        <Dropzone
          title={name || undefined}
          accept={["png", "jpg", "jpeg"]}
          onChange={() => {
            return;
          }}
        />
      );
    default:
      return null;
  }
};
