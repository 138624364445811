import { useMemo, useState } from "react";
import { IconButton, Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";

import { ModalConfirmAction } from "@/components/ModalConfirmAction";
import { useRemoveEmployeesFromRole } from "@/features/roles/hooks";

import { Option } from "./styles";
import { TOption } from "./types";

type Props = {
  employeeId: string;
  active: boolean;
  roleId: string;
};

export const EmployeeOptions = ({ employeeId, active, roleId }: Props) => {
  const [confirmRemoveModal, setConfirmRemoveModal] = useState(false);

  const { removeEmployeesFromRole } = useRemoveEmployeesFromRole();

  const options: TOption[] = useMemo(
    () => [
      {
        key: "profile",
        children: (
          <Option onClick={() => setConfirmRemoveModal(true)}>
            <Icons name="IconUserOff" fill="transparent" />
            Desvincular do cargo
          </Option>
        ),
      },
    ],
    [employeeId, active],
  );

  const handleSubmit = () => {
    setConfirmRemoveModal(false);
    removeEmployeesFromRole({
      roleId,
      employeeIds: [employeeId],
    });
  };

  return (
    <>
      <Menu
        type={"select"}
        options={options}
        disableAutoFocusItem={true}
        anchorOrigin={{ vertical: 0, horizontal: -220 }}
      >
        <IconButton variant="line" size="small" icon="IconDotsVertical" />
      </Menu>

      <ModalConfirmAction
        isOpen={confirmRemoveModal}
        loading={false}
        title="Tem certeza que deseja desvincular essa pessoa?"
        description="A pessoa selecionada ficará sem atribuição de cargo e todas as configurações realizadas serão perdidas."
        confirmWord="desvincular"
        submitText={
          <>
            <span>Confirmar</span>
            <Icons name="IconTrash" size={30} fill="transparent" />
          </>
        }
        onClose={() => setConfirmRemoveModal(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};
